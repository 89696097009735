/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */

import Decimal from 'decimal.js';
import utilities from '.';
import api from '../api';
import constants from '../constants';
import store from '../store';
import actions from '../store/actions';
import Feature from '../types/Feature';
import FlangeTestResult from '../types/FlangeTestResult';
import SizingGlobals from '../types/sizingGlobals';
import convert from './convert';
import meterSpecify from './meterSpecify';

// This file contains the code for validating the specified meter.
const findFlangeInArray = (someArray: FlangeTestResult[], material: string, maxTemp: Decimal) => {
  const items = [] as FlangeTestResult[];

  // truncate (round down) to whole number
  const maxTempWhole = new Decimal(maxTemp.trunc());

  for (let i = 0; i < someArray.length; i++) {
    if (
      someArray[i].Material === material &&
      someArray[i].MinimumTemp.lt(maxTempWhole) &&
      someArray[i].MaximumTemp.gte(maxTempWhole)
    ) {
      items.push(someArray[i]);
    }
  }

  return items;
};

// must make a full copy, because we don't want to change the data stored in redux
const findAsmeFlange = (material: string, maxTemp: Decimal, flangeShort: string): FlangeTestResult[] => {
  const state = store.getState();
  const { meterData, meterIndex, meterType, sizingGlobals, specifyMeterParentModelNumber, username } = state;
  const {
    ANSI_900,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
    TurbineModelShort,
    VorconeModel,
    VorconeReduceModel,
    VORTEK_NAME,
  } = sizingGlobals;

  const selectedMeter = meterData[meterIndex];

  let i;
  const requestData = {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'flangeTestsAsme',
    },
  };

  const res = api.readOneSync(true, requestData);
  if (res.status === 'fail') {
    return [] as FlangeTestResult[];
  }

  const diameter = new Decimal(selectedMeter.diameter);
  const realres = {
    client: requestData.requestData.client,
    username: requestData.requestData.username,
    data: [] as FlangeTestResult[],
  };
  for (i = 0; i < res.data.data.length; i++) {
    if (VORTEK_NAME === utilities.PROV_NAME || VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) {
      const decFlange = new Decimal(res.data.data[i].Flange);
      if (
        !flangeShort.startsWith('P') &&
        (specifyMeterParentModelNumber === InsertionModel || specifyMeterParentModelNumber === TurbineModelShort)
      ) {
        realres.data.push({
          Index: new Decimal(res.data.data[i].Index),
          Material: res.data.data[i].Material,
          Flange: decFlange,
          MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
          MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
          PSIRating: new Decimal(res.data.data[i].PSIRating),
        });
      } else if (specifyMeterParentModelNumber === InlineNonReduceModel || specifyMeterParentModelNumber === InlineReduceModel) {
        if (
          !flangeShort.startsWith('P') &&
          (decFlange.lessThan(constants.D900) || (decFlange.equals(constants.D900) && diameter.lte(constants.EIGHT)))
        ) {
          realres.data.push({
            Index: new Decimal(res.data.data[i].Index),
            Material: res.data.data[i].Material,
            Flange: decFlange,
            MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
            MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
            PSIRating: new Decimal(res.data.data[i].PSIRating),
          });
        }
      } else if (specifyMeterParentModelNumber === VorconeModel || specifyMeterParentModelNumber === VorconeReduceModel) {
        if (
          decFlange.lessThan(constants.D900) ||
          (decFlange.equals(constants.D900) && diameter.gte(constants.TWO) && diameter.lte(constants.EIGHT))
        ) {
          realres.data.push({
            Index: new Decimal(res.data.data[i].Index),
            Material: res.data.data[i].Material,
            Flange: decFlange,
            MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
            MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
            PSIRating: new Decimal(res.data.data[i].PSIRating),
          });
        }
      } else if (decFlange.lte(constants.D600)) {
        realres.data.push({
          Index: new Decimal(res.data.data[i].Index),
          Material: res.data.data[i].Material,
          Flange: decFlange,
          MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
          MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
          PSIRating: new Decimal(res.data.data[i].PSIRating),
        });
      }
    } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
      if (specifyMeterParentModelNumber === InsertionModel) {
        const decFlange = new Decimal(res.data.data[i].Flange);
        if ((!flangeShort.startsWith('C') && decFlange.lessThan(constants.D900)) || flangeShort.startsWith('C')) {
          realres.data.push({
            Index: new Decimal(res.data.data[i].Index),
            Material: res.data.data[i].Material,
            Flange: res.data.data[i].Flange,
            MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
            MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
            PSIRating: new Decimal(res.data.data[i].PSIRating),
          });
        }
      } else if (specifyMeterParentModelNumber === InlineNonReduceModel) {
        if (
          res.data.data[i].Flange !== ANSI_900 ||
          (res.data.data[i].Flange === ANSI_900 && diameter.gte(constants.POINT5) && diameter.lte(constants.EIGHT))
        ) {
          realres.data.push({
            Index: new Decimal(res.data.data[i].Index),
            Material: res.data.data[i].Material,
            Flange: res.data.data[i].Flange,
            MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
            MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
            PSIRating: new Decimal(res.data.data[i].PSIRating),
          });
        }
      } else if (specifyMeterParentModelNumber === InlineReduceModel) {
        if (
          res.data.data[i].Flange !== ANSI_900 ||
          (res.data.data[i].Flange === ANSI_900 && diameter.gte(constants.POINT75) && diameter.lte(constants.EIGHT))
        ) {
          realres.data.push({
            Index: new Decimal(res.data.data[i].Index),
            Material: res.data.data[i].Material,
            Flange: res.data.data[i].Flange,
            MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
            MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
            PSIRating: new Decimal(res.data.data[i].PSIRating),
          });
        }
      } else if (res.data.data[i].Flange !== ANSI_900) {
        realres.data.push({
          Index: new Decimal(res.data.data[i].Index),
          Material: res.data.data[i].Material,
          Flange: res.data.data[i].Flange,
          MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
          MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
          PSIRating: new Decimal(res.data.data[i].PSIRating),
        });
      }
    } else if (VORTEK_NAME === utilities.ONICON_NAME) {
      let decFlange = new Decimal(res.data.data[i].Flange);
      if (specifyMeterParentModelNumber !== InsertionModel && !(meterType === 'turbine')) {
        if (decFlange.equals(constants.FOUR)) {
          decFlange = constants.ONE;
        } else if (decFlange.equals(constants.FIVE)) {
          decFlange = constants.THREE;
        }
      }

      realres.data.push({
        Index: new Decimal(res.data.data[i].Index),
        Material: res.data.data[i].Material,
        Flange: decFlange,
        MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
        MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
        PSIRating: new Decimal(res.data.data[i].PSIRating),
      });
    } else if (VORTEK_NAME === utilities.GESTRA_NAME) {
      realres.data.push({
        Index: new Decimal(res.data.data[i].Index),
        Material: res.data.data[i].Material,
        Flange: new Decimal(res.data.data[i].Flange),
        MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
        MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
        PSIRating: new Decimal(res.data.data[i].PSIRating),
      });
    } else {
      realres.data.push({
        Index: new Decimal(res.data.data[i].Index),
        Material: res.data.data[i].Material,
        Flange: res.data.data[i].Flange,
        MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
        MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
        PSIRating: new Decimal(res.data.data[i].PSIRating),
      });
    }
  }

  return findFlangeInArray(realres.data, material, maxTemp);
};

// must make a full copy, because we don't want to change the data stored in redux
const findDinFlange = (material: string, maxTemp: Decimal) => {
  const state = store.getState();
  const { sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let item;
  let i;
  let realres;
  const requestData = {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'flangeTestsDin',
    },
  };

  const res = api.readOneSync(true, requestData);
  if (res.status !== 'fail') {
    realres = {
      client: requestData.requestData.client,
      username: requestData.requestData.username,
      data: [] as FlangeTestResult[],
    };
    for (i = 0; i < res.data.data.length; i++) {
      if (
        VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
      ) {
        realres.data.push({
          Index: new Decimal(res.data.data[i].Index),
          Material: res.data.data[i].Material,
          Flange: new Decimal(res.data.data[i].Flange),
          MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
          MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
          PSIRating: new Decimal(res.data.data[i].PSIRating),
        });
      } else {
        realres.data.push({
          Index: new Decimal(res.data.data[i].Index),
          Material: res.data.data[i].Material,
          Flange: res.data.data[i].Flange,
          MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
          MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
          PSIRating: new Decimal(res.data.data[i].PSIRating),
        });
      }
    }

    item = findFlangeInArray(realres.data, material, maxTemp);
  }

  return item;
};

// must make a full copy, because we don't want to change the data stored in redux
const findJisFlange = (material: string, maxTemp: Decimal) => {
  const state = store.getState();
  const { sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;

  let item;
  let i;
  let realres;
  const requestData = {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'flangeTestsJis',
    },
  };

  const res = api.readOneSync(true, requestData);
  if (res.status !== 'fail') {
    realres = {
      client: requestData.requestData.client,
      username: requestData.requestData.username,
      data: [] as FlangeTestResult[],
    };
    for (i = 0; i < res.data.data.length; i++) {
      if (
        VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
      ) {
        realres.data.push({
          Index: new Decimal(res.data.data[i].Index),
          Material: res.data.data[i].Material,
          Flange: new Decimal(res.data.data[i].Flange),
          MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
          MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
          PSIRating: new Decimal(res.data.data[i].PSIRating),
        });
      } else {
        realres.data.push({
          Index: new Decimal(res.data.data[i].Index),
          Material: res.data.data[i].Material,
          Flange: res.data.data[i].Flange,
          MinimumTemp: new Decimal(res.data.data[i].MinimumTemp),
          MaximumTemp: new Decimal(res.data.data[i].MaximumTemp),
          PSIRating: new Decimal(res.data.data[i].PSIRating),
        });
      }
    }

    item = findFlangeInArray(realres.data, material, maxTemp);
  }

  return item;
};

const findFlange = (sTable: string, dblMaxTemp: Decimal, dblMaxPress: Decimal, sMaterial: string, flangeShort: string) => {
  // Look into the meters database at the flange test table.  This table has
  // the information on flange ratings.  Get the flange rated for the
  // process conditions and return it to the calling function.
  let dblPSIRating = new Decimal('2260.0');
  let dvDataView: any[] | undefined;
  let iCounter = 0;
  let sFlange = '';

  if (sTable === 'FlangeTests_ASME') {
    dvDataView = findAsmeFlange(sMaterial, dblMaxTemp, flangeShort);
  } else if (sTable === 'FlangeTests_DIN') {
    dvDataView = findDinFlange(sMaterial, dblMaxTemp);
  } else {
    dvDataView = findJisFlange(sMaterial, dblMaxTemp);
  }

  const iNumberOfRows = dvDataView?.length || 0;

  if (iNumberOfRows === 0) {
    return ['', constants.ZERO];
  }

  while (iCounter < iNumberOfRows) {
    if (
      dblMaxPress.lte(dvDataView ? dvDataView[iCounter].PSIRating : constants.ZERO) &&
      dblPSIRating.gte(dvDataView ? dvDataView[iCounter].PSIRating : constants.ZERO)
    ) {
      sFlange = dvDataView ? dvDataView[iCounter].Flange : '';
      dblPSIRating = dvDataView ? dvDataView[iCounter].PSIRating : '';
    }

    iCounter += 1;
  }

  return [sFlange, dblPSIRating];
};

const addWarning = (warnings: string, warning: string): string => {
  if (warnings.indexOf(warning) === -1) {
    return `${warnings}${warning}\n`;
  }

  return warnings;
};

const reconstructCode = (workingVersion: SizingGlobals, sFlangeShort: string, sSpecifiedFlange: string): string => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const {
    ANSI_600,
    DN_PN64,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    VORTEK_NAME,
  } = workingVersion;

  let specifiedFlange = sSpecifiedFlange;
  let flangeShort = sFlangeShort;

  if (
    specifyMeterParentModelNumber === InsertionModel ||
    specifyMeterParentModelNumber === TurbineModelShort ||
    specifyMeterParentModelNumber === UltrasonicS34Model ||
    specifyMeterParentModelNumber === UltrasonicS36Model ||
    specifyMeterParentModelNumber === UltrasonicU42Model ||
    specifyMeterParentModelNumber === UltrasonicU43Model ||
    specifyMeterParentModelNumber === UltrasonicU44Model
  ) {
    let sReconstructCode;

    if (VORTEK_NAME === utilities.ONICON_NAME) {
      sReconstructCode = '';
    } else if (VORTEK_NAME !== utilities.ARMSTRONG_NAME) {
      sReconstructCode = flangeShort.substring(0, 1);
    } else {
      sReconstructCode = flangeShort.substring(0, 3);
      if (specifiedFlange === '40') {
        specifiedFlange = '040';
      } else if (specifiedFlange === '64') {
        specifiedFlange = '064';
      } else if (specifiedFlange === '63') {
        specifiedFlange = '063';
      }
    }

    if (specifiedFlange === ANSI_600 || specifiedFlange === DN_PN64 || specifiedFlange === '064' || specifiedFlange === '063') {
      // armstrong Packing Gland doesn't have a 600 without a retractor
      if (flangeShort.substring(0, 1) === 'P' && flangeShort.substring(flangeShort.length - 1) !== 'R') {
        specifiedFlange += 'R';
      }
    }

    sReconstructCode += specifiedFlange;

    if (flangeShort.substring(flangeShort.length - 1) === 'R' && sReconstructCode.substring(sReconstructCode.length - 1) !== 'R') {
      sReconstructCode += 'R';
    } else if (
      flangeShort.substring(flangeShort.length - 3) === 'R-E' &&
      sReconstructCode.substring(sReconstructCode.length - 3) !== 'R-E'
    ) {
      if (sReconstructCode.substring(sReconstructCode.length - 1) === 'R') {
        sReconstructCode += '-E';
      } else {
        sReconstructCode += 'R-E';
      }
    }
    // Store the reconstructed code.
    flangeShort = sReconstructCode;
  } else {
    flangeShort = specifiedFlange;
  }

  return flangeShort;
};

const testParent = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, meterType, specifyMeterProcessFluid } = state;
  const { V_METER, VORTEK_NAME } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;

  if (
    (VORTEK_NAME === utilities.SPIRAX_NAME ||
      VORTEK_NAME === utilities.PROV_NAME ||
      VORTEK_NAME === utilities.GESTRA_NAME ||
      VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) &&
    (meterType === 'vorcone' || meterType === 'reduce_vorcone')
  ) {
    return warnings;
  }

  // Validate the parent model number.  If the density varies by more
  // than 10%, a mass meter should be used.
  let dblRoPercent;

  // If the abbreviation can be found, then the user is wanting a mass meter.
  const flowRateType = convert.getFlowRateType(selectedMeter.newPipeData.flowUnit);

  if (flowRateType !== constants.VOLUMETRIC && specifyMeterProcessFluid === V_METER) {
    // Verify that the density does not vary by more than 10%.
    dblRoPercent = selectedMeter.newPipeData.calcDensityMinVal;
    dblRoPercent = selectedMeter.newPipeData.calcDensityMaxVal.minus(dblRoPercent).div(dblRoPercent).abs();

    if (dblRoPercent.gt(new Decimal('0.1'))) {
      warnings = addWarning(warnings, 'The density varies by more than 10%');
      warnings = addWarning(warnings, 'Vortex recommends using a mass meter in this application.');
    }
  }

  return warnings;
};

const testFluid = (): boolean => {
  const state = store.getState();
  const { meterData, meterIndex } = state;

  const selectedMeter = meterData[meterIndex];

  // Test that the fluid chosen by the user will work correctly in the sized meter.
  let dblPVariation;
  let minPressurePSI;
  let bMakeVT = false;

  if (
    selectedMeter.newPipeData.fluidType === constants.NAME_LIQUID ||
    selectedMeter.newPipeData.fluidType === constants.NAME_WATER ||
    selectedMeter.newPipeData.fluidType === constants.NAME_OTHER_LIQUID
  ) {
    // Calculate the pressure variation for the meter.
    dblPVariation = convert.pressureTo(
      'psi-A',
      selectedMeter.newPipeData.calcPressureMaxVal,
      selectedMeter.newPipeData.pressurePrefix,
      selectedMeter.newPipeData.pressureCoefficient
    );
    minPressurePSI = convert.pressureTo(
      'psi-A',
      selectedMeter.newPipeData.calcPressureMinVal,
      selectedMeter.newPipeData.pressurePrefix,
      selectedMeter.newPipeData.pressureCoefficient
    );

    dblPVariation = dblPVariation.minus(minPressurePSI).div(dblPVariation);

    // if the pressure difference is less than 25%.
    if (dblPVariation.lt(new Decimal('0.25'))) {
      bMakeVT = true;
    }
  }

  return bMakeVT;
};

const testTemperature = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterProcessFluid, specifyMeterProcessTemperature } = state;
  const { HIGH_TEMP, STANDARD_TEMP } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;

  // Test the temperature of the user selection.
  // Initialize the minimum and maximum temperatures.
  // These temperatures are in degrees F.
  const dblMinus40 = new Decimal('-40');
  const dbl500 = new Decimal('500');
  const dbl750 = new Decimal('750');
  let shouldDisplayCryoWarn = true;

  if (specifyMeterProcessFluid === 'V') {
    shouldDisplayCryoWarn = false;
  }

  // Verify that the minimum and maximum application temperature are within the
  // range specified.
  const dblMinTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMin),
    selectedMeter.newPipeData.temperatureUnit
  );
  const dblNomTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureNom),
    selectedMeter.newPipeData.temperatureUnit
  );
  const dblMaxTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMax),
    selectedMeter.newPipeData.temperatureUnit
  );

  // Validate the temperatures.
  if (dblMinTemp.gt(dbl750) || dblNomTemp.gt(dbl750) || dblMaxTemp.gt(dbl750)) {
    warnings = addWarning(warnings, 'The maximum temperature is too hot for all sensors.');
  } else if (shouldDisplayCryoWarn && dblMinTemp.lt(dblMinus40)) {
    warnings = addWarning(warnings, 'A cryogenic temperature sensor will be used for this application.');
  }

  if (
    dblMinTemp.gte(dblMinus40) &&
    dblMinTemp.lte(dbl500) &&
    dblNomTemp.gte(dblMinus40) &&
    dblNomTemp.lte(dbl500) &&
    dblMaxTemp.gte(dblMinus40) &&
    dblMaxTemp.lte(dbl500)
  ) {
    if (specifyMeterProcessTemperature !== STANDARD_TEMP) {
      warnings = addWarning(warnings, `The sensor should be a standard temperature sensor (${STANDARD_TEMP}).`);
    }
  } else if (
    (dblMinTemp.gt(dbl500) && dblMinTemp.lte(dbl750)) ||
    (dblNomTemp.gt(dbl500) && dblNomTemp.lte(dbl750)) ||
    (dblMaxTemp.gt(dbl500) && dblMaxTemp.lte(dbl750))
  ) {
    store.dispatch(actions.setSpecifyMeterProcessTemperature(HIGH_TEMP));
  }

  return warnings;
};

const testProTTemperature = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterProcessFluid, specifyMeterProcessTemperature } = state;
  const { HIGH_TEMP, STANDARD_TEMP } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;

  // Test the temperature of the user selection.
  // Initialize the minimum and maximum temperatures.
  // These temperatures are in degrees F.
  const dblMinus448 = new Decimal('-448');
  const dblMinus67 = new Decimal('-67');
  const dblMinus40 = new Decimal('-40');
  const dbl450 = new Decimal('450');
  const dbl850 = new Decimal('850');
  let shouldDisplayCryoWarn = true;

  if (specifyMeterProcessFluid === 'V') {
    shouldDisplayCryoWarn = false;
  }

  // Verify that the minimum and maximum application temperature are within the
  // range specified.
  const dblMaxTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMax),
    selectedMeter.newPipeData.temperatureUnit
  );
  const dblMinTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMin),
    selectedMeter.newPipeData.temperatureUnit
  );

  // Validate the temperatures.
  if (dblMaxTemp.lt(dblMinus448)) {
    warnings = addWarning(warnings, 'The maximum temperature is too cold for all sensors.');
  }

  if (dblMaxTemp.gt(dbl850)) {
    warnings = addWarning(warnings, 'The maximum temperature is too hot for all sensors.');
  } else if (shouldDisplayCryoWarn && dblMinTemp.lt(dblMinus40)) {
    warnings = addWarning(warnings, 'A cryogenic temperature sensor will be used for this application.');
  }

  if (dblMaxTemp.gte(dblMinus67) && dblMaxTemp.lte(dbl450)) {
    if (specifyMeterProcessTemperature !== STANDARD_TEMP) {
      warnings = addWarning(warnings, `The sensor should be a standard temperature sensor (${STANDARD_TEMP}).`);
    }
  } else if (dblMaxTemp.lt(dblMinus67) || dblMaxTemp.gt(dbl450)) {
    store.dispatch(actions.setSpecifyMeterProcessTemperature(HIGH_TEMP));
  }

  return warnings;
};

const testPressure = (workingVersion: SizingGlobals, bMakeVT: boolean, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterEnergyMeter, specifyMeterProcessFluid } = state;
  const {
    NO_PRESSURE_TRANSDUCER,
    VORTEK_NAME,
    V_METER,
    VT_METER,
    VTEM_METER,
    VTEP_METER,
    VTEPEM_METER,
    VETEPEM_METER,
    VTP_METER,
    VTPEM_METER,
  } = workingVersion;
  let warnings = sWarnings;

  if (bMakeVT === true && (specifyMeterProcessFluid === VTP_METER || specifyMeterProcessFluid === VTPEM_METER)) {
    warnings = addWarning(
      warnings,
      `Mass flow can be calculated using only velocity and temperature (${VT_METER}) for this application.`
    );
  }

  if (VORTEK_NAME !== utilities.ONICON_NAME) {
    if (
      specifyMeterProcessFluid === V_METER ||
      specifyMeterProcessFluid === VT_METER ||
      specifyMeterProcessFluid === VTEM_METER ||
      specifyMeterProcessFluid === VTEP_METER
    ) {
      store.dispatch(actions.setSpecifyMeterTransducer(NO_PRESSURE_TRANSDUCER));
    }

    let azbilReducingMatch = false;
    if (VORTEK_NAME === utilities.AZBIL_NAME) {
      if (specifyMeterProcessFluid === '4' || specifyMeterProcessFluid === '5' || specifyMeterProcessFluid === '7') {
        azbilReducingMatch = true;
      }
    }

    if (
      specifyMeterProcessFluid === VTPEM_METER ||
      specifyMeterProcessFluid === VTEPEM_METER ||
      specifyMeterProcessFluid === VTEM_METER ||
      specifyMeterProcessFluid === VETEPEM_METER ||
      azbilReducingMatch
    ) {
      warnings = addWarning(
        warnings,
        'Energy models are only available if the fluid is steam, water, thermal oil, or ethylene glycol / water mix.\n' +
          '  For energy measurement with other fluids please consult the factory.'
      );
    }
  } else if (specifyMeterEnergyMeter !== '0') {
    warnings = addWarning(
      warnings,
      'Energy models are only available if the fluid is steam, water, thermal oil, or ethylene glycol / water mix.\n' +
        '  For energy measurement with other fluids please consult the factory.'
    );
  }

  return warnings;
};

const testProbeLength = (workingVersion: SizingGlobals, sWarnings: string): string => {
  // Verify that the probe length chosen will work for this application.
  const state = store.getState();
  const { specifyMeterProbeLength, specifyMeterFlange, specifyMeterProcessConnection } = state;
  const {
    CompactLength,
    VORTEK_NAME,
    PNPT,
    P16,
    P40,
    P150,
    P150R,
    P300,
    PNPTR,
    P16R,
    P40R,
    P64R,
    P300R,
    P600R,
    PJ10,
    PJ20,
    PJ30,
    PJ10R,
    PJ20R,
    PJ30R,
  } = workingVersion;
  let warnings = sWarnings;

  if (specifyMeterProbeLength === CompactLength) {
    let flangeShort = specifyMeterFlange;
    if (!flangeShort) {
      flangeShort = specifyMeterProcessConnection;
    }

    if (
      flangeShort === PNPT ||
      flangeShort === P150 ||
      flangeShort === P16 ||
      flangeShort === P300 ||
      flangeShort === P40 ||
      flangeShort === PNPTR ||
      flangeShort === P150R ||
      flangeShort === P16R ||
      flangeShort === P300R ||
      flangeShort === P40R ||
      flangeShort === P600R ||
      flangeShort === P64R ||
      flangeShort === PJ10 ||
      flangeShort === PJ20 ||
      flangeShort === PJ30 ||
      flangeShort === PJ10R ||
      flangeShort === PJ20R ||
      flangeShort === PJ30R
    ) {
      const warn = 'A compact probe is only available for compression style process connections.';
      warnings = addWarning(warnings, warn);
    }

    if (VORTEK_NAME === utilities.AZBIL_NAME) {
      if (flangeShort.substring(0, 1) === 'P') {
        store.dispatch(actions.setSpecifyMeterProcessConnection('CFNPT'));
      }
    }
  }

  return warnings;
};

const azbilConnectionPressure = (workingVersion: SizingGlobals, connection: string, flangeShort: string): number => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterParentModelNumber } = state;
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    ANSI_900,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];

  let connectionPressure = 0;
  const diameter = new Decimal(selectedMeter.diameter);
  if (
    !flangeShort.startsWith('P') &&
    (specifyMeterParentModelNumber === InsertionModel ||
      (specifyMeterParentModelNumber === InlineNonReduceModel && diameter.gte(constants.POINT5) && diameter.lte(constants.EIGHT)) ||
      (specifyMeterParentModelNumber === InlineReduceModel && diameter.gte(constants.POINT75) && diameter.lte(constants.EIGHT)))
  ) {
    switch (connection) {
      case 'FA':
      case ANSI_150:
      case 'CF150':
      case 'PG150':
      case 'PG15R':
        connectionPressure = 150;
        break;
      case 'FB':
      case ANSI_300:
      case 'CF300':
      case 'PG300':
      case 'PG30R':
        connectionPressure = 300;
        break;
      case 'FC':
      case ANSI_600:
      case 'CF600':
      case 'PG600':
      case 'PG60R':
        connectionPressure = 600;
        break;
      case ANSI_900:
      case 'FD':
      case 'CF900':
        connectionPressure = 900;
        break;
      case 'D1':
      case DN_PN16:
      case 'CFP16':
      case 'PGP16':
      case 'PGP1R':
        connectionPressure = 16;
        break;
      case 'D2':
      case DN_PN40:
      case 'CFP40':
      case 'PGP40':
      case 'PGP4R':
        connectionPressure = 40;
        break;
      case 'D3':
      case DN_PN64:
      case 'CFP63':
      case 'PGP63':
      case 'PGP6R':
        connectionPressure = 63;
        break;
      case 'J1':
      case 'J10':
      case 'CJ10':
      case 'PJ10':
      case 'PJ10R':
      case '10':
      case 'CFJ1K':
      case 'PGJ1K':
      case 'PGJ1R':
      case 'Y1':
      case 'Y10':
        connectionPressure = 10;
        break;
      case 'J2':
      case 'J20':
      case 'CJ20':
      case 'PJ20':
      case 'PJ20R':
      case '20':
      case 'CFJ2K':
      case 'PGJ2K':
      case 'PGJ2R':
      case 'Y2':
      case 'Y20':
        connectionPressure = 20;
        break;
      case 'J3':
      case 'J30':
      case 'CJ30':
      case 'PJ30':
      case 'PJ30R':
      case '30':
      case 'CFJ3K':
      case 'PGJ3K':
      case 'PGJ3R':
        connectionPressure = 30;
        break;
      case 'J4':
      case 'J40':
      case '40':
      case 'CFJ4K':
      case 'PGJ4K':
      case 'PGJ4R':
        connectionPressure = 40;
        break;
      default:
        if (Number(connection) > 0) {
          connectionPressure = Number(connection);
        } else {
          connectionPressure = 0;
        }
    }
  } else {
    switch (connection) {
      case 'FA':
      case ANSI_150:
      case 'CF150':
      case 'PG150':
      case 'PG15R':
        connectionPressure = 150;
        break;
      case 'FB':
      case ANSI_300:
      case 'CF300':
      case 'PG300':
      case 'PG30R':
        connectionPressure = 300;
        break;
      case 'FC':
      case ANSI_600:
      case 'CF600':
      case 'PG600':
      case 'PG60R':
        connectionPressure = 600;
        break;
      case 'D1':
      case DN_PN16:
      case 'CFP16':
      case 'PGP16':
      case 'PGP1R':
        connectionPressure = 16;
        break;
      case 'D2':
      case DN_PN40:
      case 'CFP40':
      case 'PGP40':
      case 'PGP4R':
        connectionPressure = 40;
        break;
      case 'D3':
      case DN_PN64:
      case 'CFP63':
      case 'PGP63':
      case 'PGP6R':
        connectionPressure = 63;
        break;
      case 'J1':
      case 'J10':
      case 'CJ10':
      case 'PJ10':
      case 'PJ10R':
      case '10':
      case 'CFJ1K':
      case 'PGJ1K':
      case 'PGJ1R':
      case 'Y1':
      case 'Y10':
        connectionPressure = 10;
        break;
      case 'J2':
      case 'J20':
      case 'CJ20':
      case 'PJ20':
      case 'PJ20R':
      case '20':
      case 'CFJ2K':
      case 'PGJ2K':
      case 'PGJ2R':
      case 'Y2':
      case 'Y20':
        connectionPressure = 20;
        break;
      case 'J3':
      case 'J30':
      case 'CJ30':
      case 'PJ30':
      case 'PJ30R':
      case '30':
      case 'CFJ3K':
      case 'PGJ3K':
      case 'PGJ3R':
        connectionPressure = 30;
        break;
      case 'J4':
      case 'J40':
      case '40':
      case 'CFJ4K':
      case 'PGJ4K':
      case 'PGJ4R':
        connectionPressure = 40;
        break;
      default:
        if (Number(connection) > 0) {
          connectionPressure = Number(connection);
        } else {
          connectionPressure = 0;
        }
    }
  }

  return connectionPressure;
};

const heinrichConnectionPressure = (workingVersion: SizingGlobals, connection: string): number => {
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    C16,
    C150,
    C300,
    C40,
    C64,
    C600,
    CNPT,
    DN_PN40,
    DN_PN64,
    DN_PN100,
    E150R,
    E300R,
    E5016R,
    E5040R,
    E5064R,
    E600R,
    P16,
    P16R,
    P40,
    P40R,
    P150,
    P150R,
    P300,
    P300R,
    P600R,
    P64R,
    PNPT,
    PNPTR,
  } = workingVersion;

  let connectionPressure = 0;
  switch (connection) {
    case '1':
    case ANSI_150:
    case C150:
    case P150:
    case P150R:
    case E150R:
      connectionPressure = 150;
      break;
    case '2':
    case ANSI_300:
    case C300:
    case P300:
    case P300R:
    case E300R:
      connectionPressure = 300;
      break;
    case '3':
    case ANSI_600:
    case C600:
    case P600R:
    case E600R:
    case CNPT:
      connectionPressure = 600;
      break;
    case 'PC':
    case 'PJ':
    case 'PO':
    case 'PU':
    case '16':
    case C16:
    case P16:
    case P16R:
    case E5016R:
    case 'K':
    case 'J16':
      connectionPressure = 16;
      break;
    case '5':
    case 'PE':
    case 'PL':
    case 'PQ':
    case 'PV':
    case '40':
    case DN_PN40:
    case C40:
    case P40:
    case P40R:
    case E5040R:
      connectionPressure = 40;
      break;
    case '6':
    case 'PG':
    case 'PS':
    case 'PW':
    case '64':
    case DN_PN64:
    case C64:
    case P64R:
    case E5064R:
      connectionPressure = 64;
      break;
    case '7':
    case DN_PN100:
      connectionPressure = 100;
      break;
    case 'J':
    case 'J10':
    case '10':
      connectionPressure = 10;
      break;
    case 'L':
    case 'J20':
    case '20':
      connectionPressure = 20;
      break;
    case '30':
      connectionPressure = 30;
      break;
    case PNPT:
    case PNPTR:
      connectionPressure = 50;
      break;
    default:
      if (Number(connection) > 0) {
        connectionPressure = Number(connection);
      } else {
        connectionPressure = 0;
      }
  }

  return connectionPressure;
};

const oniconConnectionPressure = (workingVersion: SizingGlobals, connection: string): number => {
  let connectionPressure = 0;
  switch (connection) {
    case '1':
      connectionPressure = 1;
      break;
    case '2':
      connectionPressure = 2;
      break;
    case '3':
      connectionPressure = 3;
      break;
    case '4':
      connectionPressure = 4;
      break;
    case '5':
      connectionPressure = 5;
      break;
    case '9':
      connectionPressure = 9;
      break;
    default:
      connectionPressure = 0;
  }

  return connectionPressure;
};

const provConnectionPressure = (workingVersion: SizingGlobals, connection: string, flangeShort: string): number => {
  const state = store.getState();
  const { meterData, meterIndex, meterType, specifyMeterParentModelNumber } = state;
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    ANSI_900,
    C16,
    C150,
    C300,
    C40,
    C64,
    C600,
    C900,
    CJ10,
    CJ20,
    CJ30,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
    JIS_10K_J1,
    JIS_20K_J2,
    JIS_30K_J3,
    P16,
    P16R,
    P40,
    P40R,
    P150,
    P150R,
    P300,
    P300R,
    P600R,
    P64R,
    PJ10,
    PJ10R,
    PJ20,
    PJ20R,
    PJ30,
    PJ30R,
    PNPT,
    PNPTR,
    VorconeModel,
    VorconeReduceModel,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];

  let connectionPressure = 0;
  const diameter = new Decimal(selectedMeter.diameter);
  if (
    !flangeShort.startsWith('P') &&
    (specifyMeterParentModelNumber === InsertionModel ||
      ((specifyMeterParentModelNumber === InlineNonReduceModel || specifyMeterParentModelNumber === InlineReduceModel) &&
        diameter.lessThanOrEqualTo(constants.EIGHT)) ||
      meterType === 'turbine' ||
      ((specifyMeterParentModelNumber === VorconeModel || specifyMeterParentModelNumber === VorconeReduceModel) &&
        diameter.gte(constants.TWO) &&
        diameter.lte(constants.EIGHT)))
  ) {
    switch (connection) {
      case P16:
      case P40:
        connectionPressure = 3.5;
        break;
      case JIS_10K_J1:
      case CJ10:
      case PJ10:
      case PJ10R:
        connectionPressure = 10;
        break;
      case DN_PN16:
      case C16:
      case P16R:
        connectionPressure = 16;
        break;
      case JIS_20K_J2:
      case CJ20:
      case PJ20:
      case PJ20R:
        connectionPressure = 20;
        break;
      case JIS_30K_J3:
      case CJ30:
      case PJ30:
      case PJ30R:
        connectionPressure = 30;
        break;
      case DN_PN40:
      case C40:
      case P40R:
        connectionPressure = 40;
        break;
      case PNPT:
      case P150:
      case P300:
      case PNPTR:
        connectionPressure = 50;
        break;
      case DN_PN64:
      case C64:
      case P64R:
        connectionPressure = 63;
        break;
      case ANSI_150:
      case C150:
      case P150R:
        connectionPressure = 150;
        break;
      case ANSI_300:
      case C300:
      case P300R:
        connectionPressure = 300;
        break;
      case ANSI_600:
      case CNPT:
      case C600:
      case P600R:
        connectionPressure = 600;
        break;
      case ANSI_900:
      case C900:
        connectionPressure = 900;
        break;
      default:
        if (Number(connection) > 0) {
          connectionPressure = Number(connection);
        } else {
          connectionPressure = 0;
        }
    }
  } else {
    switch (connection) {
      case P16:
      case P40:
        connectionPressure = 3.5;
        break;
      case JIS_10K_J1:
      case CJ10:
      case PJ10:
      case PJ10R:
        connectionPressure = 10;
        break;
      case DN_PN16:
      case C16:
      case P16R:
        connectionPressure = 16;
        break;
      case JIS_20K_J2:
      case CJ20:
      case PJ20:
      case PJ20R:
        connectionPressure = 20;
        break;
      case JIS_30K_J3:
      case CJ30:
      case PJ30:
      case PJ30R:
        connectionPressure = 30;
        break;
      case DN_PN40:
      case C40:
      case P40R:
        connectionPressure = 40;
        break;
      case PNPT:
      case P150:
      case P300:
      case PNPTR:
        connectionPressure = 50;
        break;
      case DN_PN64:
      case C64:
      case P64R:
        connectionPressure = 63;
        break;
      case ANSI_150:
      case C150:
      case P150R:
        connectionPressure = 150;
        break;
      case ANSI_300:
      case C300:
      case P300R:
        connectionPressure = 300;
        break;
      case ANSI_600:
      case CNPT:
      case C600:
      case P600R:
        connectionPressure = 600;
        break;
      default:
        if (Number(connection) > 0) {
          connectionPressure = Number(connection);
        } else {
          connectionPressure = 0;
        }
    }
  }

  return connectionPressure;
};

const gestraConnectionPressure = (workingVersion: SizingGlobals, connection: string): number => {
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    C16,
    C150,
    C300,
    C40,
    C64,
    C600,
    CJ10,
    CJ20,
    CJ30,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    JIS_10K_J1,
    JIS_20K_J2,
    JIS_30K_J3,
    P16,
    P16R,
    P40,
    P40R,
    P150,
    P150R,
    P300,
    P300R,
    P600R,
    P64R,
    PJ10,
    PJ10R,
    PJ20,
    PJ20R,
    PJ30,
    PJ30R,
    PNPT,
    PNPTR,
  } = workingVersion;

  let connectionPressure = 0;
  switch (connection) {
    case P16:
    case P40:
      connectionPressure = 3.5;
      break;
    case JIS_10K_J1:
    case CJ10:
    case PJ10:
    case PJ10R:
      connectionPressure = 10;
      break;
    case DN_PN16:
    case C16:
    case P16R:
      connectionPressure = 16;
      break;
    case JIS_20K_J2:
    case CJ20:
    case PJ20:
    case PJ20R:
      connectionPressure = 20;
      break;
    case JIS_30K_J3:
    case CJ30:
    case PJ30:
    case PJ30R:
      connectionPressure = 30;
      break;
    case DN_PN40:
    case C40:
    case P40R:
      connectionPressure = 40;
      break;
    case PNPT:
    case P150:
    case P300:
    case PNPTR:
      connectionPressure = 50;
      break;
    case DN_PN64:
    case C64:
    case P64R:
      connectionPressure = 63;
      break;
    case ANSI_150:
    case C150:
    case P150R:
      connectionPressure = 150;
      break;
    case ANSI_300:
    case C300:
    case P300R:
      connectionPressure = 300;
      break;
    case ANSI_600:
    case CNPT:
    case C600:
    case P600R:
      connectionPressure = 600;
      break;
    default:
      if (Number(connection) > 0) {
        connectionPressure = Number(connection);
      } else {
        connectionPressure = 0;
      }
  }

  return connectionPressure;
};

const spiraxConnectionPressure = (workingVersion: SizingGlobals, connection: string): number => {
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    C16,
    C150,
    C300,
    C40,
    C64,
    C600,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    DN_PN100,
    P16,
    P16R,
    P16RE,
    P40,
    P40R,
    P40RE,
    P150,
    P150R,
    P150RE,
    P300,
    P300R,
    P300RE,
    P600R,
    P600RE,
    P64R,
    P64RE,
    PNPT,
    PNPTR,
    PNPTRE,
  } = workingVersion;

  let connectionPressure = 0;
  switch (connection) {
    case P16:
    case P40:
      connectionPressure = 3.5;
      break;
    case DN_PN16:
    case C16:
    case P16R:
    case P16RE:
      connectionPressure = 16;
      break;
    case DN_PN40:
    case C40:
    case P40R:
    case P40RE:
      connectionPressure = 40;
      break;
    case PNPT:
    case PNPTR:
    case PNPTRE:
    case P150:
    case P300:
      connectionPressure = 50;
      break;
    case DN_PN64:
    case C64:
    case P64R:
    case P64RE:
      connectionPressure = 63;
      break;
    case DN_PN100:
      connectionPressure = 100;
      break;
    case ANSI_150:
    case C150:
    case P150R:
    case P150RE:
      connectionPressure = 150;
      break;
    case ANSI_300:
    case C300:
    case P300R:
    case P300RE:
      connectionPressure = 300;
      break;
    case ANSI_600:
    case C600:
    case CNPT:
    case P600R:
    case P600RE:
      connectionPressure = 600;
      break;
    default:
      if (Number(connection) > 0) {
        connectionPressure = Number(connection);
      } else {
        connectionPressure = 0;
      }
  }

  return connectionPressure;
};

const otherConnectionPressure = (workingVersion: SizingGlobals, connection: string): number => {
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    C16,
    C150,
    C300,
    C40,
    C64,
    C600,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    P16,
    P16R,
    P40,
    P40R,
    P150,
    P150R,
    P300,
    P300R,
    P600R,
    P64R,
    PNPT,
    PNPTR,
  } = workingVersion;

  let connectionPressure = 0;
  switch (connection) {
    case P16:
    case P40:
      connectionPressure = 3.5;
      break;
    case DN_PN16:
    case C16:
    case P16R:
      connectionPressure = 16;
      break;
    case DN_PN40:
    case C40:
    case P40R:
      connectionPressure = 40;
      break;
    case PNPT:
    case P150:
    case P300:
    case PNPTR:
      connectionPressure = 50;
      break;
    case DN_PN64:
    case C64:
    case P64R:
      connectionPressure = 64;
      break;
    case ANSI_150:
    case C150:
    case P150R:
      connectionPressure = 150;
      break;
    case ANSI_300:
    case C300:
    case P300R:
      connectionPressure = 300;
      break;
    case ANSI_600:
    case CNPT:
    case C600:
    case P600R:
      connectionPressure = 600;
      break;
    default:
      if (Number(connection) > 0) {
        connectionPressure = Number(connection);
      } else {
        connectionPressure = 0;
      }
  }

  return connectionPressure;
};

const connectionPressure = (workingVersion: SizingGlobals, connection: string, flangeShort: string): number => {
  const { VORTEK_NAME } = workingVersion;

  if (VORTEK_NAME === utilities.AZBIL_NAME) {
    return azbilConnectionPressure(workingVersion, connection, flangeShort);
  }
  if (VORTEK_NAME === utilities.HEINRICH_NAME) {
    return heinrichConnectionPressure(workingVersion, connection);
  }
  if (VORTEK_NAME === utilities.ONICON_NAME) {
    return oniconConnectionPressure(workingVersion, connection);
  }
  if (VORTEK_NAME === utilities.PROV_NAME || VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) {
    return provConnectionPressure(workingVersion, connection, flangeShort);
  }
  if (VORTEK_NAME === utilities.GESTRA_NAME) {
    return gestraConnectionPressure(workingVersion, connection);
  }
  if (VORTEK_NAME === utilities.SPIRAX_NAME) {
    return spiraxConnectionPressure(workingVersion, connection);
  }

  return otherConnectionPressure(workingVersion, connection);
};

const azbilAnsiFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterParentModelNumber } = state;
  const { ANSI_600, C600, C900, InlineModel, InlineNonReduceModel, InlineReduceModel, InsertionModel, P600R } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;

  let sReconstructCode = '';
  const flangeArray = findFlange('FlangeTests_ASME', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeASME = flangeArray[0] as Decimal;
  let sProcessCodeASME = dblProcessCodeASME.toString();
  const dblMaxFlangePressureASME = flangeArray[1] as Decimal;
  const diameter = new Decimal(selectedMeter.diameter);

  // This is an ANSI flange specification.
  // note this will have to be the max rated flange if others are specified in the future

  if (sProcessCodeASME === '') {
    // warnings += "The application requires the flange to be better than a " + "600<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    if (
      !flangeShort.startsWith('P') &&
      (specifyMeterParentModelNumber === InsertionModel ||
        (specifyMeterParentModelNumber === InlineNonReduceModel &&
          diameter.gte(constants.POINT5) &&
          diameter.lte(constants.EIGHT)) ||
        (specifyMeterParentModelNumber === InlineReduceModel && diameter.gte(constants.POINT75) && diameter.lte(constants.EIGHT)))
    ) {
      if (specifyMeterParentModelNumber === InlineNonReduceModel || specifyMeterParentModelNumber === InlineReduceModel) {
        sProcessCodeASME = 'FD';
        flangeShort = 'FD';
      } else {
        sProcessCodeASME = C900;
      }
    } else if (specifyMeterParentModelNumber === InlineNonReduceModel || specifyMeterParentModelNumber === InlineReduceModel) {
      sProcessCodeASME = 'FC';
      flangeShort = 'FC';
    } else {
      sProcessCodeASME = ANSI_600;
    }

    specifiedFlange = sProcessCodeASME;
    if (flangeShort.endsWith('E')) {
      flangeShort = 'PG60E';
    } else if (flangeShort.endsWith('R')) {
      flangeShort = P600R;
    } else if (flangeShort.startsWith('P')) {
      flangeShort = P600R;
    } else if (specifiedFlange === C900) {
      flangeShort = C900;
    } else if (specifyMeterParentModelNumber === InlineModel) {
      flangeShort = 'FC';
    } else if (!flangeShort.startsWith('F')) {
      flangeShort = C600;
    }
  }

  if (dblMaxFlangePressureASME.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        if (flangeShort.startsWith('P') && sProcessCodeASME === '900') {
          sProcessCodeASME = '600';
        }

        specifiedFlange = sProcessCodeASME;

        sReconstructCode = flangeShort.substring(0, 2);
        sReconstructCode += specifiedFlange;
        if (flangeShort.endsWith('E')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}E`;
        } else if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow PGP60
        if (sReconstructCode === '600' || sReconstructCode === 'PG600') {
          sReconstructCode = 'PG60R';
          if (dblMaxAppPress.gte(new Decimal('1440'))) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        warnings = addWarning(
          warnings,
          'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
        );
        flangeShort = sProcessCodeASME;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeASME, flangeShort)
    ) {
      // warnings += "The application requires the flange to be a " + sProcessCode_ASME + " or better.<br />";
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        sReconstructCode = flangeShort.substring(0, 2);
        sReconstructCode += sProcessCodeASME;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow PG600
        if (sReconstructCode === 'PG600') {
          sReconstructCode = 'PG300';
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeASME;
      }
    }
  }

  return { flangeShort, warnings };
};

const azbilDinFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const { C64, DN_PN64, InlineModel, InlineNonReduceModel, InlineReduceModel, InsertionModel, P64R } = workingVersion;

  let sReconstructCode = '';
  let flangeShort = sFlangeShort;

  const flangeArray = findFlange('FlangeTests_DIN', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeDIN = flangeArray[0];
  const sProcessCodeDIN = dblProcessCodeDIN.toString();
  const dblMaxFlangePressureDIN = flangeArray[1] as Decimal;
  let specifiedFlange = 0;
  let warnings = sWarnings;

  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeDIN === '') {
    // sWarnings += "The application requires the flange to be better than a " + "PN63<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    if (flangeShort.endsWith('E')) {
      flangeShort = 'PGP6E';
    } else if (flangeShort.endsWith('R')) {
      flangeShort = P64R;
    } else if (flangeShort.startsWith('P')) {
      flangeShort = P64R;
    } else if (
      specifyMeterParentModelNumber === InlineModel ||
      specifyMeterParentModelNumber === InlineNonReduceModel ||
      specifyMeterParentModelNumber === InlineReduceModel
    ) {
      flangeShort = DN_PN64;
    } else {
      flangeShort = C64;
    }
  } else if (dblMaxFlangePressureDIN.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, sSpecifiedFlange, flangeShort)
      ) {
        specifiedFlange = connectionPressure(workingVersion, sProcessCodeDIN, flangeShort);

        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 3);
        sReconstructCode += specifiedFlange;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        } else if (flangeShort.endsWith('E')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}E`;
        }

        // Do not allow PGP60
        if (specifiedFlange === 63 && sReconstructCode === 'PGP63') {
          sReconstructCode = 'PGP40';
          if (dblMaxAppPress.lt(new Decimal('813.66'))) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        } else if (specifiedFlange === 64 && sReconstructCode === 'CFP64') {
          sReconstructCode = 'CFP63';
        } else if (specifiedFlange === 64 && sReconstructCode === 'PGP64') {
          sReconstructCode = 'PGP6R';
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeDIN;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, sSpecifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeDIN, flangeShort)
    ) {
      // sWarnings += "The application requires the flange to be a PN " + sProcessCode_DIN + " or better.<br />";
    }

    // Do not allow the flange to be specified below the DIN tables by the user.
    if (specifyMeterParentModelNumber === InsertionModel) {
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 3);
        sReconstructCode += sProcessCodeDIN;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        } else if (flangeShort.endsWith('E')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}E`;
        }

        // Do not allow PGP60
        if (sReconstructCode === 'PGP63') {
          sReconstructCode = 'PGP40';
        }

        flangeShort = sReconstructCode;
      }
    } else if (
      connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeDIN;
    }
  }

  return { flangeShort, warnings };
};

const azbilJisJFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const { InsertionModel, JIS_10K_J1, JIS_20K_J2, JIS_30K_J3, JIS_40K_J4, PGJ2K, PGJ3R } = workingVersion;

  const dblJISMagicPressure = new Decimal('986.2566');
  let sReconstructCode = '';
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;

  const flangeArray = findFlange('FlangeTests_JIS', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  let sProcessCodeJISDivI = flangeArray[0] as string;
  const dblMaxFlangePressureJISDivI = flangeArray[1] as Decimal;

  // This is a JIS Division I flange specification
  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeJISDivI === '') {
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    if (flangeShort.endsWith('E')) {
      sProcessCodeJISDivI = '30';
    } else {
      sProcessCodeJISDivI = '40';
    }
  }

  if (dblMaxFlangePressureJISDivI.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified above the JIS tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeJISDivI;

        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 3);
        sReconstructCode += specifiedFlange.substring(0, 1) + (flangeShort.endsWith('E') ? 'E' : 'K');
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;

        // Do not allow JIS 40K packing gland style without a retractor.
        if (specifiedFlange === '40' && sReconstructCode === 'PGJ4K') {
          flangeShort = PGJ2K;
          if (dblMaxAppPress.lte(dblJISMagicPressure)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        } else if (specifiedFlange === '30' && sReconstructCode === 'PGJ3K') {
          flangeShort = PGJ3R;
        }
      }
    } else {
      // Do not allow the flange to be specified above the JIS tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        // cCurrentMeter.ProcessConnection_Flange = sProcessCode_JIS_Div_I
        if (sProcessCodeJISDivI === 'J10' || sProcessCodeJISDivI === '10') {
          flangeShort = JIS_10K_J1;
        } else if (sProcessCodeJISDivI === 'J20' || sProcessCodeJISDivI === '20') {
          flangeShort = JIS_20K_J2;
        } else if (sProcessCodeJISDivI === 'J30' || sProcessCodeJISDivI === '30') {
          flangeShort = JIS_30K_J3;
        } else if (sProcessCodeJISDivI === 'J40' || sProcessCodeJISDivI === '40') {
          // Limit to JIS 40K.
          flangeShort = JIS_40K_J4;
          warnings = addWarning(
            warnings,
            'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
          );
        }
      }
    }
  } else {
    // Do not allow the flange to be specified below the ASME tables by the user.
    // eslint-disable-next-line
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified above the JIS tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeJISDivI;

        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 3);
        sReconstructCode += specifiedFlange.substring(0, 1) + (flangeShort.endsWith('E') ? 'E' : 'K');
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow JIS 40K or 30K packing gland style without a retractor.
        if (specifiedFlange === '40' && sReconstructCode === 'PGJ4K') {
          flangeShort = PGJ2K;
          if (dblMaxAppPress.lte(dblJISMagicPressure)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        } else {
          flangeShort = sReconstructCode;
        }
      }
    } else if (
      connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeJISDivI;
    }
  }

  return { flangeShort, warnings };
};

const azbilJisYFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const {
    InsertionModel,
    JIS_10K_Y1,
    JIS_20K_Y2,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
  } = workingVersion;

  let sReconstructCode = '';
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;

  const flangeArray = findFlange('FlangeTests_JIS', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  let sProcessCodeJISDivI = flangeArray[0] as string;
  const dblMaxFlangePressureJISDivI = flangeArray[1] as Decimal;

  // This is a JIS Division I flange specification.
  // note this will have to be the max rated flange if others are specified in the future

  if (sProcessCodeJISDivI === '') {
    // warnings = warnings & "The application requires the flange to be better than a " & "600" & NEW_LINE
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    sProcessCodeJISDivI = JIS_20K_Y2;
    specifiedFlange = sProcessCodeJISDivI;
    flangeShort = specifiedFlange;
  } else if (dblMaxFlangePressureJISDivI.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.

    if (
      specifyMeterParentModelNumber === InsertionModel ||
      specifyMeterParentModelNumber === UltrasonicS34Model ||
      specifyMeterParentModelNumber === UltrasonicS36Model ||
      specifyMeterParentModelNumber === UltrasonicU42Model ||
      specifyMeterParentModelNumber === UltrasonicU43Model ||
      specifyMeterParentModelNumber === UltrasonicU44Model
    ) {
      // Currently no JIS flanges on insertion style keep for possible future option.
      // Do not allow the flange to be specified below the JIS tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeJISDivI;

        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 2);
        sReconstructCode += specifiedFlange;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow PG600.
        if (specifiedFlange === '600' && sReconstructCode === 'PG600') {
          sReconstructCode = 'PG300';
          if (dblMaxAppPress.lte(constants.D1440)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;
      }
    } else {
      // Do not allow the flange to be specified below the JIS tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        // cCurrentMeter.ProcessConnection_Flange = sProcessCode_JIS_Div_I
        if (sProcessCodeJISDivI === 'Y10' || sProcessCodeJISDivI === '10') {
          flangeShort = JIS_10K_Y1;
        } else if (sProcessCodeJISDivI === 'Y20' || sProcessCodeJISDivI === '20') {
          flangeShort = JIS_20K_Y2;
        } else if (sProcessCodeJISDivI === 'Y30' || sProcessCodeJISDivI === '30') {
          // Limit to JIS 20K.
          flangeShort = 'Y2';
          warnings = addWarning(
            warnings,
            'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
          );
        } else if (sProcessCodeJISDivI === 'Y40' || sProcessCodeJISDivI === '40') {
          // Limit to JIS 20K.
          flangeShort = 'Y2';
          warnings = addWarning(
            warnings,
            'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
          );
        }
      }
    }

    // CTO - Is this code necessary?
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort)
    ) {
      warnings = addWarning(warnings, `The application requires the flange to be a ${sProcessCodeJISDivI} or better.`);
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (
      specifyMeterParentModelNumber === InsertionModel ||
      specifyMeterParentModelNumber === UltrasonicS34Model ||
      specifyMeterParentModelNumber === UltrasonicS36Model ||
      specifyMeterParentModelNumber === UltrasonicU42Model ||
      specifyMeterParentModelNumber === UltrasonicU43Model ||
      specifyMeterParentModelNumber === UltrasonicU44Model
    ) {
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        sReconstructCode = flangeShort.substring(0, 2);
        sReconstructCode += sProcessCodeJISDivI;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow PG600.
        if (sReconstructCode === 'PG600') {
          sReconstructCode = 'PG300';
        }

        flangeShort = sReconstructCode;
      }
    } else if (
      connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeJISDivI;
    }
  }

  return { flangeShort, warnings };
};

const azbilProcessConnection = (workingVersion: SizingGlobals, sWarnings: string) => {
  const state = store.getState();
  const {
    meterData,
    meterIndex,
    specifyMeterFlange,
    specifyMeterParentModelNumber,
    specifyMeterPipeMaterial,
    specifyMeterProcessConnection,
  } = state;
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    ANSI_900,
    C600,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    InlineModel,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
    JIS_10K_J1,
    JIS_10K_Y1,
    JIS_20K_J2,
    JIS_20K_Y2,
    JIS_30K_J3,
    JIS_40K_J4,
    PNPT,
    PNPTR,
    VorconeModel,
    VorconeReduceModel,
    WAFER,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];

  let warnings = sWarnings;
  let sSpecifiedFlange = '';
  let sFlangeMaterial = specifyMeterPipeMaterial;

  if (!sFlangeMaterial) {
    sFlangeMaterial = '316L Stainless Steel';
  } else {
    const feature = meterSpecify.getFeature('OrderCode', sFlangeMaterial);
    sFlangeMaterial = feature?.Description || '';
  }

  // Determine if the process connection has a flange.
  let flangeShort = specifyMeterFlange;
  if (!flangeShort) {
    flangeShort = specifyMeterProcessConnection;
  }

  let originalFlangeShort;

  // simply return if validation is not necessary
  if (!flangeShort || flangeShort === '') {
    return { flangeShort, sFlangeMaterial, warnings };
  }

  if (flangeShort === WAFER || flangeShort === CNPT || flangeShort === PNPT || flangeShort === PNPTR) {
    originalFlangeShort = flangeShort;
    if (specifyMeterParentModelNumber === InsertionModel) {
      flangeShort = C600;
    } else {
      flangeShort = ANSI_600;
    }
  }

  // This code is flanged.  Get the maximum temperature and pressure
  // of the application in psi-G.
  const dblMaxAppPress = convert.pressureTo(
    'psi-G',
    selectedMeter.newPipeData.calcPressureMaxVal,
    selectedMeter.newPipeData.pressurePrefix,
    selectedMeter.newPipeData.pressureCoefficient
  );
  const dblMaxAppTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMax),
    selectedMeter.newPipeData.temperatureUnit
  );

  if (
    !sFlangeMaterial &&
    (specifyMeterParentModelNumber === InlineModel ||
      specifyMeterParentModelNumber === InlineReduceModel ||
      specifyMeterParentModelNumber === InlineNonReduceModel)
  ) {
    const feature = meterSpecify.getFeature('OrderCode', sFlangeMaterial);
    sFlangeMaterial = feature?.Description || '';
  }

  if (sFlangeMaterial?.length > 5) {
    sFlangeMaterial = sFlangeMaterial?.substring(5);
  }

  if (specifyMeterParentModelNumber === InsertionModel) {
    if (flangeShort === 'CF150' || flangeShort === 'PG150' || flangeShort === 'PG15R' || flangeShort === 'PG15E') {
      sSpecifiedFlange = '150';
    } else if (flangeShort === 'CF300' || flangeShort === 'PG300' || flangeShort === 'PG30R' || flangeShort === 'PG30E') {
      sSpecifiedFlange = '300';
    } else if (flangeShort === 'CF600' || flangeShort === 'PG600' || flangeShort === 'PG60R' || flangeShort === 'PG60E') {
      sSpecifiedFlange = '600';
    } else if (flangeShort === 'CF900') {
      sSpecifiedFlange = '900';
    } else if (flangeShort === 'CFP16' || flangeShort === 'PGP16' || flangeShort === 'PGP1R' || flangeShort === 'PGP1E') {
      sSpecifiedFlange = '16';
    } else if (flangeShort === 'CFP40' || flangeShort === 'PGP40' || flangeShort === 'PGP4R' || flangeShort === 'PGP4E') {
      sSpecifiedFlange = '40';
    } else if (flangeShort === 'CFP63' || flangeShort === 'PGP63' || flangeShort === 'PGP6R' || flangeShort === 'PGP6E') {
      sSpecifiedFlange = '63';
    } else if (
      flangeShort === 'J1' ||
      flangeShort === 'CFJ1K' ||
      flangeShort === 'PGJ1K' ||
      flangeShort === 'PGJ1R' ||
      flangeShort === 'PGJ1E'
    ) {
      sSpecifiedFlange = 'J10';
    } else if (
      flangeShort === 'J2' ||
      flangeShort === 'CFJ2K' ||
      flangeShort === 'PGJ2K' ||
      flangeShort === 'PGJ2R' ||
      flangeShort === 'PGJ2E'
    ) {
      sSpecifiedFlange = 'J20';
    } else if (
      flangeShort === 'J3' ||
      flangeShort === 'CFJ3K' ||
      flangeShort === 'PGJ3K' ||
      flangeShort === 'PGJ3R' ||
      flangeShort === 'PGJ3E'
    ) {
      sSpecifiedFlange = 'J30';
    } else if (flangeShort === 'J4' || flangeShort === 'CFJ4K' || flangeShort === 'PGJ4K' || flangeShort === 'PGJ4R') {
      sSpecifiedFlange = 'J40';
    }
  } else {
    // The model M22 does not have a pre- or post- letter.
    // eslint-disable-next-line
    if (flangeShort === 'FA' || flangeShort === '150') {
      sSpecifiedFlange = '150';
    } else if (flangeShort === 'FB' || flangeShort === '300') {
      sSpecifiedFlange = '300';
    } else if (flangeShort === 'FC' || flangeShort === '600') {
      sSpecifiedFlange = '600';
    } else if (flangeShort === 'FD' || flangeShort === '900') {
      sSpecifiedFlange = '900';
    } else if (flangeShort === 'D1' || flangeShort === '16') {
      sSpecifiedFlange = '16';
    } else if (flangeShort === 'D2' || flangeShort === '40') {
      sSpecifiedFlange = '40';
    } else if (flangeShort === 'D3' || flangeShort === '63') {
      sSpecifiedFlange = '63';
    } else if (flangeShort === 'J1' || flangeShort === 'J10' || flangeShort === '10') {
      sSpecifiedFlange = 'J10';
    } else if (flangeShort === 'J2' || flangeShort === 'J20' || flangeShort === '20') {
      sSpecifiedFlange = 'J20';
    } else if (flangeShort === 'J3' || flangeShort === 'J30' || flangeShort === '30') {
      sSpecifiedFlange = 'J30';
    } else if (flangeShort === 'J4' || flangeShort === 'J40' || flangeShort === '40') {
      sSpecifiedFlange = 'J40';
    } else if (flangeShort === 'Y1' || flangeShort === 'Y10' || flangeShort === '10') {
      sSpecifiedFlange = 'Y10';
    } else if (flangeShort === 'Y2' || flangeShort === 'Y20' || flangeShort === '20') {
      sSpecifiedFlange = 'Y20';
    }
  }

  if (
    sSpecifiedFlange === DN_PN16 ||
    sSpecifiedFlange === '16' ||
    sSpecifiedFlange === DN_PN40 ||
    sSpecifiedFlange === '40' ||
    sSpecifiedFlange === DN_PN64 ||
    sSpecifiedFlange === '63'
  ) {
    const dinResults = azbilDinFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = dinResults.flangeShort;
    warnings = dinResults.warnings;
  } else if (
    sSpecifiedFlange === ANSI_150 ||
    sSpecifiedFlange === ANSI_300 ||
    sSpecifiedFlange === ANSI_600 ||
    sSpecifiedFlange === ANSI_900
  ) {
    const ansiResults = azbilAnsiFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = ansiResults.flangeShort;
    warnings = ansiResults.warnings;
  } else if (
    sSpecifiedFlange === JIS_10K_J1 ||
    sSpecifiedFlange === JIS_20K_J2 ||
    sSpecifiedFlange === JIS_30K_J3 ||
    sSpecifiedFlange === JIS_40K_J4
  ) {
    const jisJResults = azbilJisJFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = jisJResults.flangeShort;
    warnings = jisJResults.warnings;
  } else if (sSpecifiedFlange === JIS_10K_Y1 || sSpecifiedFlange === JIS_20K_Y2) {
    const jisYResults = azbilJisYFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = jisYResults.flangeShort;
    warnings = jisYResults.warnings;
  }

  if (originalFlangeShort) {
    // if a different flange is returned from the tests, then the flange limit has been
    if (specifyMeterParentModelNumber === InsertionModel) {
      if (flangeShort !== C600) {
        warnings = addWarning(
          warnings,
          'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
        );
      }
    } else if (flangeShort !== ANSI_600) {
      warnings = addWarning(
        warnings,
        'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
      );
    }

    flangeShort = originalFlangeShort;
  }

  // now convert flangeShort back to Azbil normal values...
  if (
    specifyMeterParentModelNumber === InlineModel ||
    specifyMeterParentModelNumber === InlineReduceModel ||
    specifyMeterParentModelNumber === InlineNonReduceModel ||
    specifyMeterParentModelNumber === VorconeModel ||
    specifyMeterParentModelNumber === VorconeReduceModel
  ) {
    if (flangeShort === '150') {
      flangeShort = 'FA';
    } else if (flangeShort === '300') {
      flangeShort = 'FB';
    } else if (flangeShort === '600') {
      flangeShort = 'FC';
    } else if (flangeShort === '900') {
      flangeShort = 'FD';
    } else if (flangeShort === 'PGP16' || flangeShort === '16') {
      flangeShort = 'D1';
    } else if (flangeShort === 'PGP40' || flangeShort === '40') {
      flangeShort = 'D2';
    } else if (flangeShort === 'PGP63' || flangeShort === '63' || flangeShort === '64') {
      flangeShort = 'D3';
    } else if (flangeShort === 'J10' || flangeShort === '10') {
      flangeShort = 'J1';
    } else if (flangeShort === 'J20' || flangeShort === '20') {
      flangeShort = 'J2';
    } else if (flangeShort === 'J30' || flangeShort === '30') {
      flangeShort = 'J3';
    } else if (flangeShort === 'J40' || flangeShort === '40') {
      flangeShort = 'J4';
    } else if (flangeShort === 'Y10') {
      flangeShort = 'Y1';
    } else if (flangeShort === 'Y20') {
      flangeShort = 'Y2';
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const heinrichsAnsiFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const { ANSI_600, InsertionModel } = workingVersion;

  let sReconstructCode = '';
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;
  const flangeArray = findFlange('FlangeTests_ASME', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeASME = flangeArray[0] as Decimal;
  let sProcessCodeASME = dblProcessCodeASME.toString();
  const dblMaxFlangePressureASME = flangeArray[1] as Decimal;

  // This is an ANSI flange specification.
  // note this will have to be the max rated flange if others are specified in the future

  if (sProcessCodeASME === '') {
    // warnings += "The application requires the flange to be better than a " + "600<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    sProcessCodeASME = ANSI_600;
  }

  if (dblMaxFlangePressureASME.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, sSpecifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeASME;

        if (specifiedFlange === '300') {
          if (flangeShort === 'B') {
            sReconstructCode = 'D';
          } else if (flangeShort === 'I') {
            sReconstructCode = 'K';
          } else if (flangeShort === 'N') {
            sReconstructCode = 'P';
          } else if (flangeShort === 'Y') {
            sReconstructCode = 'Z';
          }
        } else if (specifiedFlange === '600') {
          if (flangeShort === 'B' || flangeShort === 'D' || flangeShort === 'F') {
            sReconstructCode = 'F';
          } else if (flangeShort === 'I' || flangeShort === 'K') {
            sReconstructCode = 'K'; // No ANSI 600# with removable retractor.
          } else if (flangeShort === 'N' || flangeShort === 'P' || flangeShort === 'R') {
            sReconstructCode = 'R';
          } else if (flangeShort === 'Y' || flangeShort === 'Z' || flangeShort === '1') {
            sReconstructCode = '1';
          }

          if (dblMaxAppPress.lte(constants.D1440)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeASME;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeASME, flangeShort)
    ) {
      // warnings += "The application requires the flange to be a " + sProcessCode_ASME + " or better.<br />";
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (specifyMeterParentModelNumber === InsertionModel) {
      if (flangeShort === 'B' || flangeShort === 'D' || flangeShort === 'F') {
        sReconstructCode = 'F';
      } else if (flangeShort === 'I' || flangeShort === 'K') {
        sReconstructCode = 'K'; // No ANSI 600# with removable retractor.
      } else if (flangeShort === 'N' || flangeShort === 'P' || flangeShort === 'R') {
        sReconstructCode = 'R';
      } else if (flangeShort === 'Y' || flangeShort === 'Z' || flangeShort === '1') {
        sReconstructCode = '1';
      }
    } else {
      sReconstructCode = '3';
    }

    // Store the reconstructed code.
    flangeShort = sReconstructCode;
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const heinrichsDinFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const { DN_PN100, InsertionModel } = workingVersion;

  const dblMagicPressure = new Decimal('913.74');
  let sReconstructCode = '';
  let warnings = sWarnings;
  let specifiedFlange = sSpecifiedFlange;
  let flangeShort = sFlangeShort;

  const flangeArray = findFlange('FlangeTests_DIN', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeDIN = flangeArray[0] as Decimal;
  let sProcessCodeDIN = dblProcessCodeDIN.toString();
  const dblMaxFlangePressureDIN = flangeArray[1] as Decimal;

  // This is a DIN specification
  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeDIN === '') {
    // warnings += "The application requires the flange to be better than a " + "PN64<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    sProcessCodeDIN = DN_PN100;
    flangeShort = DN_PN100;
  }

  // This is a metric flange specification.
  if (dblMaxFlangePressureDIN.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeDIN;

        // The model code must now be re-constructed.
        if (specifiedFlange === '40') {
          if (flangeShort === 'C') {
            sReconstructCode = 'E';
          } else if (flangeShort === 'J') {
            sReconstructCode = 'L';
          } else if (flangeShort === 'O') {
            sReconstructCode = 'Q';
          } else if (flangeShort === 'U') {
            sReconstructCode = 'V';
          }
        } else if (specifiedFlange === '64' || specifiedFlange === '100') {
          if (flangeShort === 'C' || flangeShort === 'E' || flangeShort === 'G') {
            sReconstructCode = 'G';
          } else if (flangeShort === 'J' || flangeShort === 'L') {
            sReconstructCode = 'L';
          } else if (flangeShort === 'O' || flangeShort === 'Q' || flangeShort === 'S') {
            sReconstructCode = 'S';
          } else if (flangeShort === 'U' || flangeShort === 'V' || flangeShort === 'W') {
            sReconstructCode = 'W';
          }

          if (specifiedFlange === '100' || dblMaxAppPress.lte(dblMagicPressure)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeDIN;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeDIN, flangeShort)
    ) {
      // removed at request from Vortek, only active above 2000 psi
      // sWarnings += "The application requires the flange to be a PN " + sProcessCode_DIN + " or better.<br />";
    }

    if (specifyMeterParentModelNumber === InsertionModel) {
      if (flangeShort === 'C' || flangeShort === 'E' || flangeShort === 'G') {
        sReconstructCode = 'G';
      } else if (flangeShort === 'J' || flangeShort === 'L') {
        sReconstructCode = 'L';
      } else if (flangeShort === 'O' || flangeShort === 'Q' || flangeShort === 'S') {
        sReconstructCode = 'S';
      } else if (flangeShort === 'U' || flangeShort === 'V' || flangeShort === 'W') {
        sReconstructCode = 'W';
      }
    } else {
      sReconstructCode = '7';
    }

    flangeShort = sReconstructCode;
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const heinrichsJisJFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const { InsertionModel, JIS_16K_K, JIS_10K_J1, JIS_20K_J2, PGJ3K } = workingVersion;

  const dblJISMagicPressure = new Decimal('986.2566');
  let sReconstructCode = '';
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;

  const flangeArray = findFlange('FlangeTests_JIS', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  let sProcessCodeJISDivI = flangeArray[0] as string;
  const dblMaxFlangePressureJISDivI = flangeArray[1] as Decimal;

  // This is a JIS Division I flange specification
  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeJISDivI === '') {
    sProcessCodeJISDivI = '30';
  }

  if (dblMaxFlangePressureJISDivI.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified above the JIS tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeJISDivI;

        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 3);
        sReconstructCode += `${specifiedFlange.substring(0, 1)}K`;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;

        // Do not allow JIS 40K packing gland style without a retractor.
        if (specifiedFlange === '40' && sReconstructCode === 'PGJ4K') {
          flangeShort = PGJ3K;
          if (dblMaxAppPress.lte(dblJISMagicPressure)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        }
      }
    } else {
      // Do not allow the flange to be specified above the JIS tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        // cCurrentMeter.ProcessConnection_Flange = sProcessCode_JIS_Div_I
        if (sProcessCodeJISDivI === 'J10' || sProcessCodeJISDivI === '10') {
          flangeShort = JIS_10K_J1;
        } else if (sProcessCodeJISDivI === 'J16' || sProcessCodeJISDivI === '16') {
          flangeShort = JIS_16K_K;
        } else if (sProcessCodeJISDivI === 'J20' || sProcessCodeJISDivI === '20') {
          flangeShort = JIS_20K_J2;
        } else if (
          sProcessCodeJISDivI === 'J30' ||
          sProcessCodeJISDivI === '30' ||
          sProcessCodeJISDivI === 'J40' ||
          sProcessCodeJISDivI === '40'
        ) {
          flangeShort = 'L';
          warnings = addWarning(
            warnings,
            'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
          );
        }
      }
    }
  } else {
    // Do not allow the flange to be specified below the ASME tables by the user.
    // eslint-disable-next-line
    if (specifyMeterParentModelNumber === InsertionModel) {
      // Do not allow the flange to be specified above the JIS tables by the user.
      sProcessCodeJISDivI = '40';
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeJISDivI;

        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 3);
        sReconstructCode += `${specifiedFlange.substring(0, 1)}K`;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow JIS 40K packing gland style without a retractor.
        if (specifiedFlange === '40' && sReconstructCode === 'PGJ4K') {
          flangeShort = PGJ3K;
          if (dblMaxAppPress.lte(dblJISMagicPressure)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        }
      }
    } else if (
      connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = 'L';
      warnings = addWarning(
        warnings,
        'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
      );
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const heinrichsJisYFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const { InsertionModel, JIS_10K_Y1, JIS_20K_Y2 } = workingVersion;

  let sReconstructCode = '';
  let warnings = sWarnings;
  let specifiedFlange = sSpecifiedFlange;
  let flangeShort = sFlangeShort;

  const flangeArray = findFlange('FlangeTests_JIS', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  let sProcessCodeJISDivI = flangeArray[0] as string;
  const dblMaxFlangePressureJISDivI = flangeArray[1] as Decimal;

  // This is a JIS Division I flange specification.
  // note this will have to be the max rated flange if others are specified in the future

  if (sProcessCodeJISDivI === '') {
    // warnings = warnings & "The application requires the flange to be better than a " & "600" & NEW_LINE
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    sProcessCodeJISDivI = JIS_20K_Y2;
  }

  if (dblMaxFlangePressureJISDivI.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.

    if (specifyMeterParentModelNumber === InsertionModel) {
      // Currently no JIS flanges on insertion style keep for possible future option.
      // Do not allow the flange to be specified below the JIS tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeJISDivI;

        // The model code must now be re-constructed.
        sReconstructCode = flangeShort.substring(0, 2);
        sReconstructCode += specifiedFlange;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow PG600.
        if (specifiedFlange === '600' && sReconstructCode === 'PG600') {
          sReconstructCode = 'PG300';
          if (dblMaxAppPress.lte(constants.D1440)) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        }

        // Store the reconstructed code.
        flangeShort = sReconstructCode;
      }
    } else {
      // Do not allow the flange to be specified below the JIS tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        // cCurrentMeter.ProcessConnection_Flange = sProcessCode_JIS_Div_I
        if (sProcessCodeJISDivI === 'Y10' || sProcessCodeJISDivI === '10') {
          flangeShort = JIS_10K_Y1;
        } else if (sProcessCodeJISDivI === 'Y20' || sProcessCodeJISDivI === '20') {
          flangeShort = JIS_20K_Y2;
        } else if (sProcessCodeJISDivI === 'Y30' || sProcessCodeJISDivI === '30') {
          // Limit to JIS 20K.
          flangeShort = 'Y2';
          // sWarnings += "Select a higher rated process connection, the pressure / temperature limit has been exceeded.<br />";
        } else if (sProcessCodeJISDivI === 'Y40' || sProcessCodeJISDivI === '40') {
          // Limit to JIS 20K.
          flangeShort = 'Y2';
          warnings = addWarning(
            warnings,
            'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
          );
        }
      }
    }

    // CTO - Is this code necessary?
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort)
    ) {
      warnings = addWarning(warnings, `The application requires the flange to be a ${sProcessCodeJISDivI} or better.`);
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (specifyMeterParentModelNumber === InsertionModel) {
      if (
        connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        sReconstructCode = flangeShort.substring(0, 2);
        sReconstructCode += sProcessCodeJISDivI;
        if (flangeShort.endsWith('R')) {
          sReconstructCode = `${sReconstructCode.substring(0, 4)}R`;
        }

        // Do not allow PG600.
        if (sReconstructCode === 'PG600') {
          sReconstructCode = 'PG300';
        }

        flangeShort = sReconstructCode;
      }
    } else if (
      connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeJISDivI;
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const heinrichsProcessConnection = (workingVersion: SizingGlobals, sWarnings: string) => {
  const state = store.getState();
  const {
    meterData,
    meterIndex,
    specifyMeterFlange,
    specifyMeterParentModelNumber,
    specifyMeterPipeMaterial,
    specifyMeterProcessConnection,
  } = state;
  const {
    ANSI_150,
    ANSI_300,
    ANSI_600,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    DN_PN100,
    ENPTR,
    InsertionModel,
    JIS_16K_K,
    JIS_10K_J1,
    JIS_10K_Y1,
    JIS_20K_J2,
    JIS_20K_Y2,
    JIS_30K_J3,
    JIS_40K_J4,
    PNPT,
    PNPTR,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;

  // Verify that the process connection is correct for this application.
  let sSpecifiedFlange;
  let sFlangeMaterial = specifyMeterPipeMaterial;

  if (!sFlangeMaterial) {
    sFlangeMaterial = 'st.st. 1.4404 / 316 L';
  } else {
    const feature = meterSpecify.getFeature('OrderCode', sFlangeMaterial);
    sFlangeMaterial = feature?.Description || 'st.st. 1.4404 / 316 L';
  }

  // Determine if the process connection has a flange.
  let flangeShort = specifyMeterFlange;
  if (!flangeShort) {
    flangeShort = specifyMeterProcessConnection;
  }

  if (flangeShort === ENPTR || flangeShort === CNPT || flangeShort === PNPT || flangeShort === PNPTR || flangeShort === '') {
    return { flangeShort, sFlangeMaterial, warnings };
  }

  // This code is flanged.  Get the maximum temperature and pressure
  // of the application in psi-G.
  const dblMaxAppPress = convert.pressureTo(
    'psi-G',
    selectedMeter.newPipeData.calcPressureMaxVal,
    selectedMeter.newPipeData.pressurePrefix,
    selectedMeter.newPipeData.pressureCoefficient
  );
  const dblMaxAppTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMax),
    selectedMeter.newPipeData.temperatureUnit
  );

  if (sFlangeMaterial.trim() === 'Special') {
    return { flangeShort, sFlangeMaterial, warnings };
  }

  if (specifyMeterParentModelNumber === InsertionModel) {
    if (flangeShort === 'B' || flangeShort === 'I' || flangeShort === 'N' || flangeShort === 'Y') {
      sSpecifiedFlange = '150';
    } else if (flangeShort === 'D' || flangeShort === 'K' || flangeShort === 'P' || flangeShort === 'Z') {
      sSpecifiedFlange = '300';
    } else if (flangeShort === 'F' || flangeShort === 'R' || flangeShort === '1') {
      sSpecifiedFlange = '600';
    } else if (flangeShort === 'C' || flangeShort === 'J' || flangeShort === 'O' || flangeShort === 'U') {
      sSpecifiedFlange = '16';
    } else if (flangeShort === 'E' || flangeShort === 'L' || flangeShort === 'Q' || flangeShort === 'V') {
      sSpecifiedFlange = '40';
    } else if (flangeShort === 'G' || flangeShort === 'S' || flangeShort === 'W') {
      sSpecifiedFlange = '64';
    } else {
      sSpecifiedFlange = flangeShort;
    }
  } else if (flangeShort === '1' || flangeShort === '150') {
    sSpecifiedFlange = '150';
  } else if (flangeShort === '2' || flangeShort === '300') {
    sSpecifiedFlange = '300';
  } else if (flangeShort === '3' || flangeShort === '600') {
    sSpecifiedFlange = '600';
  } else if (flangeShort === '5' || flangeShort === '40') {
    sSpecifiedFlange = '40';
  } else if (flangeShort === '6' || flangeShort === '64') {
    sSpecifiedFlange = '64';
  } else if (flangeShort === '7' || flangeShort === '100') {
    sSpecifiedFlange = '100';
  } else if (flangeShort === 'J' || flangeShort === 'J10' || flangeShort === '10') {
    sSpecifiedFlange = 'J10';
  } else if (flangeShort === 'K' || flangeShort === 'J16' || flangeShort === '16') {
    sSpecifiedFlange = 'J16';
  } else if (flangeShort === 'L' || flangeShort === 'J20' || flangeShort === '20') {
    sSpecifiedFlange = 'J20';
  } else {
    // The model M22 does not have a pre- or post- letter.
    sSpecifiedFlange = flangeShort;
  }

  // Determine if the flange is a DIN, ANSI, or JIS
  if (
    sSpecifiedFlange === DN_PN16 ||
    sSpecifiedFlange === DN_PN40 ||
    sSpecifiedFlange === DN_PN64 ||
    sSpecifiedFlange === DN_PN100
  ) {
    const dinResults = heinrichsDinFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = dinResults.flangeShort;
    warnings = dinResults.warnings;
  } else if (sSpecifiedFlange === ANSI_150 || sSpecifiedFlange === ANSI_300 || sSpecifiedFlange === ANSI_600) {
    const ansiResults = heinrichsAnsiFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = ansiResults.flangeShort;
    warnings = ansiResults.warnings;
  } else if (
    sSpecifiedFlange === JIS_10K_J1 ||
    sSpecifiedFlange === JIS_16K_K ||
    sSpecifiedFlange === JIS_20K_J2 ||
    sSpecifiedFlange === JIS_30K_J3 ||
    sSpecifiedFlange === JIS_40K_J4
  ) {
    const jisJResults = heinrichsJisJFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = jisJResults.flangeShort;
    warnings = jisJResults.warnings;
  } else if (sSpecifiedFlange === JIS_10K_Y1 || sSpecifiedFlange === JIS_20K_Y2) {
    const jisYResults = heinrichsJisYFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = jisYResults.flangeShort;
    warnings = jisYResults.warnings;
  }

  // now convert flangeShort back to Heinrichs normal values...
  if (flangeShort === '150') {
    flangeShort = '1';
  } else if (flangeShort === '300') {
    flangeShort = '2';
  } else if (flangeShort === '600') {
    flangeShort = '3';
  } else if (flangeShort === '40') {
    flangeShort = '5';
  } else if (flangeShort === '64') {
    flangeShort = '6';
  } else if (flangeShort === '100') {
    flangeShort = '7';
  } else if (flangeShort === 'J10' || flangeShort === '10') {
    flangeShort = 'J';
  } else if (flangeShort === 'J16' || flangeShort === '16') {
    flangeShort = 'K';
  } else if (flangeShort === 'J20' || flangeShort === '20') {
    flangeShort = 'L';
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const provAnsiFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { meterData, meterIndex, meterType, specifyMeterParentModelNumber } = state;
  const {
    ANSI_600,
    ANSI_900,
    InlineModel,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    VorconeModel,
    VorconeReduceModel,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  const diameter = new Decimal(selectedMeter.diameter);
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;

  // Determine if the code for this flange is acceptable.  The new pressure
  // variable will return with the maximum pressure.
  const flangeArray = findFlange('FlangeTests_ASME', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeASME = flangeArray[0] as Decimal;
  let sProcessCodeASME = dblProcessCodeASME.toString();
  const dblMaxFlangePressureASME = flangeArray[1] as Decimal;

  // This is an English flange specification.
  // note this will have to be the max rated flange if others are specified in the future

  if (sProcessCodeASME === '') {
    // warnings += "The application requires the flange to be better than a " + "900<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    if (
      !flangeShort.startsWith('P') &&
      (specifyMeterParentModelNumber === InsertionModel ||
        ((specifyMeterParentModelNumber === InlineModel ||
          specifyMeterParentModelNumber === InlineNonReduceModel ||
          specifyMeterParentModelNumber === InlineReduceModel) &&
          diameter.lessThanOrEqualTo(constants.EIGHT)) ||
        meterType === 'turbine' ||
        ((specifyMeterParentModelNumber === VorconeModel || specifyMeterParentModelNumber === VorconeReduceModel) &&
          diameter.gte(constants.TWO) &&
          diameter.lte(constants.EIGHT)))
    ) {
      sProcessCodeASME = ANSI_900;
    } else {
      sProcessCodeASME = ANSI_600;
    }
    specifiedFlange = sProcessCodeASME;
    flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
  }

  if (dblMaxFlangePressureASME.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (
      specifyMeterParentModelNumber === InsertionModel ||
      specifyMeterParentModelNumber === TurbineModelShort ||
      specifyMeterParentModelNumber === UltrasonicS34Model ||
      specifyMeterParentModelNumber === UltrasonicS36Model ||
      specifyMeterParentModelNumber === UltrasonicU42Model ||
      specifyMeterParentModelNumber === UltrasonicU43Model ||
      specifyMeterParentModelNumber === UltrasonicU44Model
    ) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeASME;
        flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeASME;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeASME, flangeShort)
    ) {
      // sWarnings += "The application requires the flange to be a " + sProcessCode_ASME + " or better.<br />";
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (
      connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeASME;
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const provDinFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;

  const {
    DN_PN64,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
  } = workingVersion;

  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;
  const flangeArray = findFlange('FlangeTests_DIN', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeDIN = flangeArray[0] as Decimal;
  let sProcessCodeDIN = dblProcessCodeDIN.toString();
  const dblMaxFlangePressureDIN = flangeArray[1] as Decimal;

  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeDIN === '') {
    // warnings += "The application requires the flange to be better than a " + "PN64<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    sProcessCodeDIN = DN_PN64;
    specifiedFlange = sProcessCodeDIN;
    flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
  }
  // This is a metric flange specification.
  else if (dblMaxFlangePressureDIN.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (
      specifyMeterParentModelNumber === InsertionModel ||
      specifyMeterParentModelNumber === TurbineModelShort ||
      specifyMeterParentModelNumber === UltrasonicS34Model ||
      specifyMeterParentModelNumber === UltrasonicS36Model ||
      specifyMeterParentModelNumber === UltrasonicU42Model ||
      specifyMeterParentModelNumber === UltrasonicU43Model ||
      specifyMeterParentModelNumber === UltrasonicU44Model
    ) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeDIN;
        flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeDIN;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeDIN, flangeShort)
    ) {
      // sWarnings += "The application requires the flange to be a PN " + sProcessCode_DIN + " or better.<br />";
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (
      connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeDIN;
      flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const provJisFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  let sReconstructCode = '';
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;
  const flangeArray = findFlange('FlangeTests_JIS', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  let sProcessCodeJISDivI = flangeArray[0] as string;

  // This is a JIS Division I flange specification
  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeJISDivI === '') {
    sProcessCodeJISDivI = '30';
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
  }

  // Do not allow the flange to be specified above the JIS tables by the user.
  if (
    connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
    connectionPressure(workingVersion, specifiedFlange, flangeShort)
  ) {
    specifiedFlange = sProcessCodeJISDivI.toString();
    if (flangeShort.startsWith('C')) {
      sReconstructCode = `CJ${specifiedFlange}`;
    } else if (flangeShort.startsWith('P')) {
      sReconstructCode = `PJ${specifiedFlange}`;
    } else if (flangeShort.startsWith('J')) {
      sReconstructCode = `J${specifiedFlange}`;
    }

    // The model code must now be re-constructed.
    if (flangeShort.endsWith('R') || (!flangeShort.endsWith('R') && flangeShort.startsWith('P'))) {
      sReconstructCode += 'R';
    }

    // Store the reconstructed code.
    flangeShort = sReconstructCode;
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const provProcessConnection = (workingVersion: SizingGlobals, sWarnings: string) => {
  const state = store.getState();
  const {
    meterData,
    meterIndex,
    specifyMeterFlange,
    specifyMeterParentModelNumber,
    specifyMeterPipeMaterial,
    specifyMeterProcessConnection,
  } = state;
  const {
    ANSI_600,
    C600,
    CJ10,
    CJ20,
    CJ30,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    InsertionModel,
    JIS_10K_J1,
    JIS_20K_J2,
    JIS_30K_J3,
    PJ10,
    PJ20,
    PJ10R,
    PJ20R,
    PJ30R,
    PNPT,
    PNPTR,
    PNPTRE,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    WAFER,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];

  // Verify that the process connection is correct for this application.
  let warnings = sWarnings;
  let sSpecifiedFlange;
  let sFlangeMaterial = specifyMeterPipeMaterial;

  if (!sFlangeMaterial) {
    sFlangeMaterial = '316L Stainless Steel';
  } else {
    const feature = meterSpecify.getFeature('OrderCode', sFlangeMaterial);
    sFlangeMaterial = feature?.Description || '316L Stainless Steel';
  }

  // Determine if the process connection has a flange.
  let flangeShort = specifyMeterFlange;
  if (!flangeShort) {
    flangeShort = specifyMeterProcessConnection;
  }

  // simply return if validation is not necessary
  let originalFlangeShort;

  // simply return if validation is not necessary
  if (!flangeShort || flangeShort === '') {
    return { flangeShort, sFlangeMaterial, warnings };
  }

  if (flangeShort === WAFER || flangeShort === CNPT || flangeShort === PNPT || flangeShort === PNPTR || flangeShort === PNPTRE) {
    originalFlangeShort = flangeShort;
    if (specifyMeterParentModelNumber === InsertionModel) {
      flangeShort = C600;
    } else {
      flangeShort = ANSI_600;
    }
  }

  // This code is flanged.  Get the maximum temperature and pressure
  // of the application in psi-G.
  const dblMaxAppPress = convert.pressureTo(
    'psi-G',
    selectedMeter.newPipeData.calcPressureMaxVal,
    selectedMeter.newPipeData.pressurePrefix,
    selectedMeter.newPipeData.pressureCoefficient
  );
  const dblMaxAppTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMax),
    selectedMeter.newPipeData.temperatureUnit
  );

  if (sFlangeMaterial.length < 5) {
    const selMat = specifyMeterPipeMaterial;

    sFlangeMaterial = selMat.substring(5);
  } else {
    sFlangeMaterial = sFlangeMaterial.substring(5);
  }

  // check model number and that the first character in the flange is not a number
  if (
    (specifyMeterParentModelNumber === InsertionModel ||
      specifyMeterParentModelNumber === TurbineModelShort ||
      specifyMeterParentModelNumber === UltrasonicS34Model ||
      specifyMeterParentModelNumber === UltrasonicS36Model ||
      specifyMeterParentModelNumber === UltrasonicU42Model ||
      specifyMeterParentModelNumber === UltrasonicU43Model ||
      specifyMeterParentModelNumber === UltrasonicU44Model) &&
    Number(flangeShort.substring(0, 1)).toString() !== flangeShort.substring(0, 1)
  ) {
    // The first character is not needed.  Get rid of it.
    sSpecifiedFlange = flangeShort.substring(1);
    const iLength = sSpecifiedFlange.length;
    // Determine if there is a character at the end of the order code.
    if (sSpecifiedFlange.substring(sSpecifiedFlange.length - 1) === 'R') {
      sSpecifiedFlange = sSpecifiedFlange.substring(0, iLength - 1);
    } else if (flangeShort.substring(flangeShort.length - 3) === 'R-E') {
      sSpecifiedFlange = sSpecifiedFlange.substring(0, iLength - 3);
    }
  } else {
    // The model M22 does not have a pre- or post- letter.
    sSpecifiedFlange = flangeShort;
  }

  // Determine if the flange is a metric or English unit.
  if (sSpecifiedFlange === DN_PN16 || sSpecifiedFlange === DN_PN40 || sSpecifiedFlange === DN_PN64) {
    const dinResults = provDinFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = dinResults.flangeShort;
    warnings = dinResults.warnings;
  } else if (
    sSpecifiedFlange === JIS_10K_J1 ||
    sSpecifiedFlange === CJ10 ||
    sSpecifiedFlange === CJ20 ||
    sSpecifiedFlange === CJ30 ||
    sSpecifiedFlange === PJ10 ||
    sSpecifiedFlange === PJ20 ||
    sSpecifiedFlange === PJ10R ||
    sSpecifiedFlange === PJ20R ||
    sSpecifiedFlange === PJ30R ||
    sSpecifiedFlange === JIS_20K_J2 ||
    sSpecifiedFlange === JIS_30K_J3
  ) {
    const jisJResults = provJisFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = jisJResults.flangeShort;
    warnings = jisJResults.warnings;
  } else {
    const ansiResults = provAnsiFlange(
      workingVersion,
      sWarnings,
      flangeShort,
      sSpecifiedFlange,
      dblMaxAppPress,
      dblMaxAppTemp,
      sFlangeMaterial
    );
    flangeShort = ansiResults.flangeShort;
    warnings = ansiResults.warnings;
    if (originalFlangeShort) {
      // if a different flange is returned from the tests, then the flange limit has been
      if (specifyMeterParentModelNumber === InsertionModel) {
        if (flangeShort !== C600) {
          warnings = addWarning(
            warnings,
            'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
          );
        }
      } else if (flangeShort !== ANSI_600) {
        warnings = addWarning(
          warnings,
          'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
        );
      }

      flangeShort = originalFlangeShort;
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const otherAnsiFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;
  const {
    ANSI_300,
    ANSI_600,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    VORTEK_NAME,
  } = workingVersion;

  // Determine if the code for this flange is acceptable.  The new pressure
  // variable will return with the maximum pressure.
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;
  const flangeArray = findFlange('FlangeTests_ASME', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeASME = flangeArray[0] as Decimal;
  let sProcessCodeASME = dblProcessCodeASME.toString();
  const dblMaxFlangePressureASME = flangeArray[1] as Decimal;

  // This is an English flange specification.
  // note this will have to be the max rated flange if others are specified in the future

  if (sProcessCodeASME === '') {
    // warnings += "The application requires the flange to be better than a " + "600<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    if (VORTEK_NAME === utilities.ONICON_NAME) {
      // for Onicon, the meter type decides the flange
      if (specifyMeterParentModelNumber === InsertionModel || specifyMeterParentModelNumber === TurbineModelShort) {
        if (flangeShort === '4') {
          // Insertion and Turbine 300# flange
          sProcessCodeASME = '5';
        } else {
          sProcessCodeASME = flangeShort;
        }
      } else {
        // Inline 300# flange
        sProcessCodeASME = ANSI_300;
      }
    } else {
      sProcessCodeASME = ANSI_600;
    }
    specifiedFlange = sProcessCodeASME;
    flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
  }

  if (dblMaxFlangePressureASME.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (
      specifyMeterParentModelNumber === InsertionModel ||
      specifyMeterParentModelNumber === TurbineModelShort ||
      specifyMeterParentModelNumber === UltrasonicS34Model ||
      specifyMeterParentModelNumber === UltrasonicS36Model ||
      specifyMeterParentModelNumber === UltrasonicU42Model ||
      specifyMeterParentModelNumber === UltrasonicU43Model ||
      specifyMeterParentModelNumber === UltrasonicU44Model
    ) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeASME;
        flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeASME;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeASME, flangeShort)
    ) {
      // sWarnings += "The application requires the flange to be a " + sProcessCode_ASME + " or better.<br />";
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (
      connectionPressure(workingVersion, sProcessCodeASME, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeASME;
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const otherDinFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  const state = store.getState();
  const { specifyMeterParentModelNumber } = state;
  const {
    DN_PN64,
    DN_PN100,
    InlineModel,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    VORTEK_NAME,
  } = workingVersion;

  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;
  const flangeArray = findFlange('FlangeTests_DIN', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  const dblProcessCodeDIN = flangeArray[0] as Decimal;
  let sProcessCodeDIN = dblProcessCodeDIN.toString();
  const dblMaxFlangePressureDIN = flangeArray[1] as Decimal;

  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeDIN === '') {
    // warnings += "The application requires the flange to be better than a " + "PN64<br />";
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
    if (VORTEK_NAME === utilities.SPIRAX_NAME && specifyMeterParentModelNumber === InlineModel) {
      sProcessCodeDIN = DN_PN100;
    } else {
      sProcessCodeDIN = DN_PN64;
    }
    specifiedFlange = sProcessCodeDIN;
    flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
  }
  // This is a metric flange specification.
  else if (dblMaxFlangePressureDIN.gte(dblMaxAppPress)) {
    // The meter connection is under-rated.
    if (
      specifyMeterParentModelNumber === InsertionModel ||
      specifyMeterParentModelNumber === TurbineModelShort ||
      specifyMeterParentModelNumber === UltrasonicS34Model ||
      specifyMeterParentModelNumber === UltrasonicS36Model ||
      specifyMeterParentModelNumber === UltrasonicU42Model ||
      specifyMeterParentModelNumber === UltrasonicU43Model ||
      specifyMeterParentModelNumber === UltrasonicU44Model
    ) {
      // Do not allow the flange to be specified below the ASME tables by the user.
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, specifiedFlange, flangeShort)
      ) {
        specifiedFlange = sProcessCodeDIN;
        flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
      }
    } else {
      // Do not allow the flange to be specified below the ASME tables by the user.
      // eslint-disable-next-line
      if (
        connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
        connectionPressure(workingVersion, flangeShort, flangeShort)
      ) {
        flangeShort = sProcessCodeDIN;
      }
    }
  } else {
    if (
      connectionPressure(workingVersion, specifiedFlange, flangeShort) <
      connectionPressure(workingVersion, sProcessCodeDIN, flangeShort)
    ) {
      // sWarnings += "The application requires the flange to be a PN " + sProcessCode_DIN + " or better.<br />";
    }

    // Do not allow the flange to be specified below the ASME tables by the user.
    if (
      connectionPressure(workingVersion, sProcessCodeDIN, flangeShort) >
      connectionPressure(workingVersion, flangeShort, flangeShort)
    ) {
      flangeShort = sProcessCodeDIN;
      flangeShort = reconstructCode(workingVersion, flangeShort, specifiedFlange);
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const otherJisFlange = (
  workingVersion: SizingGlobals,
  sWarnings: string,
  sFlangeShort: string,
  sSpecifiedFlange: string,
  dblMaxAppPress: Decimal,
  dblMaxAppTemp: Decimal,
  sFlangeMaterial: string
) => {
  let sReconstructCode = '';
  let warnings = sWarnings;
  let flangeShort = sFlangeShort;
  let specifiedFlange = sSpecifiedFlange;
  const flangeArray = findFlange('FlangeTests_JIS', dblMaxAppTemp, dblMaxAppPress, sFlangeMaterial, flangeShort);
  let sProcessCodeJISDivI = flangeArray[0] as string;

  // This is a JIS Division I flange specification
  // note this will have to be the max rated flange if others are specified in the future
  if (sProcessCodeJISDivI === '') {
    sProcessCodeJISDivI = '30';
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
  }

  // Do not allow the flange to be specified above the JIS tables by the user.
  if (
    connectionPressure(workingVersion, sProcessCodeJISDivI, flangeShort) >
    connectionPressure(workingVersion, specifiedFlange, flangeShort)
  ) {
    specifiedFlange = sProcessCodeJISDivI.toString();
    if (flangeShort.startsWith('C')) {
      sReconstructCode = `CJ${specifiedFlange}`;
    } else if (flangeShort.startsWith('P')) {
      sReconstructCode = `PJ${specifiedFlange}`;
    } else if (flangeShort.startsWith('J')) {
      sReconstructCode = `J${specifiedFlange}`;
    }

    // The model code must now be re-constructed.
    if (flangeShort.endsWith('R') || (!flangeShort.endsWith('R') && flangeShort.startsWith('P'))) {
      sReconstructCode += 'R';
    }

    // Store the reconstructed code.
    flangeShort = sReconstructCode;
    warnings = addWarning(
      warnings,
      'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
    );
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const otherProcessConnection = (workingVersion: SizingGlobals, sWarnings: string) => {
  const state = store.getState();
  const {
    meterData,
    meterIndex,
    specifyMeterFlange,
    specifyMeterParentModelNumber,
    specifyMeterPipeMaterial,
    specifyMeterProcessConnection,
  } = state;
  const {
    ANSI_600,
    C600,
    CJ10,
    CJ20,
    CJ30,
    CNPT,
    DN_PN16,
    DN_PN40,
    DN_PN64,
    DN_PN100,
    InsertionModel,
    JIS_10K_J1,
    JIS_20K_J2,
    JIS_30K_J3,
    PJ10,
    PJ20,
    PJ10R,
    PJ20R,
    PJ30R,
    PNPT,
    PNPTR,
    PNPTRE,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    VORTEK_NAME,
    WAFER,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];

  // Verify that the process connection is correct for this application.
  let warnings = sWarnings;
  let sSpecifiedFlange;
  let sFlangeMaterial = specifyMeterPipeMaterial;

  if (!sFlangeMaterial) {
    sFlangeMaterial = '316L Stainless Steel';
  } else {
    const feature = meterSpecify.getFeature('OrderCode', sFlangeMaterial);
    sFlangeMaterial = feature?.Description || '316L Stainless Steel';
  }

  // Determine if the process connection has a flange.
  let flangeShort = specifyMeterFlange;
  if (!flangeShort) {
    flangeShort = specifyMeterProcessConnection;
  }

  // simply return if validation is not necessary

  let originalFlangeShort;

  // simply return if validation is not necessary
  if (!flangeShort || flangeShort === '') {
    return { flangeShort, sFlangeMaterial, warnings };
  }

  if (flangeShort === WAFER || flangeShort === CNPT || flangeShort === PNPT || flangeShort === PNPTR || flangeShort === PNPTRE) {
    originalFlangeShort = flangeShort;
    if (specifyMeterParentModelNumber === InsertionModel) {
      flangeShort = C600;
    } else {
      flangeShort = ANSI_600;
    }
  }

  if (
    VORTEK_NAME !== utilities.ONICON_NAME ||
    (VORTEK_NAME === utilities.ONICON_NAME && flangeShort !== '2' && flangeShort !== '9' && flangeShort !== '')
  ) {
    // This code is flanged.  Get the maximum temperature and pressure
    // of the application in psi-G.
    const dblMaxAppPress = convert.pressureTo(
      'psi-G',
      selectedMeter.newPipeData.calcPressureMaxVal,
      selectedMeter.newPipeData.pressurePrefix,
      selectedMeter.newPipeData.pressureCoefficient
    );
    const dblMaxAppTemp = convert.temperatureTo(
      'deg F',
      new Decimal(selectedMeter.newPipeData.temperatureMax),
      selectedMeter.newPipeData.temperatureUnit
    );

    if (sFlangeMaterial.length < 5) {
      const feature = meterSpecify.getFeature('OrderCode', specifyMeterPipeMaterial);
      const selMat = feature?.Description || '316L Stainless Steel';
      sFlangeMaterial = selMat.substring(5);
    } else {
      sFlangeMaterial = sFlangeMaterial.substring(5);
    }

    // check model number and that the first character in the flange is not a number
    if (
      (specifyMeterParentModelNumber === InsertionModel ||
        specifyMeterParentModelNumber === TurbineModelShort ||
        specifyMeterParentModelNumber === UltrasonicS34Model ||
        specifyMeterParentModelNumber === UltrasonicS36Model ||
        specifyMeterParentModelNumber === UltrasonicU42Model ||
        specifyMeterParentModelNumber === UltrasonicU43Model ||
        specifyMeterParentModelNumber === UltrasonicU44Model) &&
      Number(flangeShort.substring(0, 1)).toString() !== flangeShort.substring(0, 1)
    ) {
      // The first character is not needed.  Get rid of it.
      if (VORTEK_NAME === utilities.ONICON_NAME) {
        sSpecifiedFlange = flangeShort;
      } else if (VORTEK_NAME !== utilities.ARMSTRONG_NAME) {
        sSpecifiedFlange = flangeShort.substring(1);
      } else {
        sSpecifiedFlange = flangeShort.substring(3);
        if (sSpecifiedFlange[0] === '0') {
          sSpecifiedFlange = sSpecifiedFlange.substring(1);
        }
      }

      // Determine if there is a character at the end of the order code.
      const iLength = sSpecifiedFlange.length;
      if (sSpecifiedFlange.substring(sSpecifiedFlange.length - 1) === 'R') {
        sSpecifiedFlange = sSpecifiedFlange.substring(0, iLength - 1);
      } else if (flangeShort.substring(flangeShort.length - 3) === 'R-E') {
        sSpecifiedFlange = sSpecifiedFlange.substring(0, iLength - 3);
      }
    } else {
      // The model M22 does not have a pre- or post- letter.
      sSpecifiedFlange = flangeShort;
    }

    // Determine if the flange is a metric or English unit.
    if (
      sSpecifiedFlange === DN_PN16 ||
      sSpecifiedFlange === DN_PN40 ||
      sSpecifiedFlange === DN_PN64 ||
      sSpecifiedFlange === DN_PN100
    ) {
      const dinResults = otherDinFlange(
        workingVersion,
        sWarnings,
        flangeShort,
        sSpecifiedFlange,
        dblMaxAppPress,
        dblMaxAppTemp,
        sFlangeMaterial
      );
      flangeShort = dinResults.flangeShort;
      warnings = dinResults.warnings;
    } else if (
      sSpecifiedFlange === JIS_10K_J1 ||
      sSpecifiedFlange === CJ10 ||
      sSpecifiedFlange === CJ20 ||
      sSpecifiedFlange === CJ30 ||
      sSpecifiedFlange === PJ10 ||
      sSpecifiedFlange === PJ20 ||
      sSpecifiedFlange === PJ10R ||
      sSpecifiedFlange === PJ20R ||
      sSpecifiedFlange === PJ30R ||
      sSpecifiedFlange === JIS_20K_J2 ||
      sSpecifiedFlange === JIS_30K_J3
    ) {
      const jisJResults = otherJisFlange(
        workingVersion,
        sWarnings,
        flangeShort,
        sSpecifiedFlange,
        dblMaxAppPress,
        dblMaxAppTemp,
        sFlangeMaterial
      );
      flangeShort = jisJResults.flangeShort;
      warnings = jisJResults.warnings;
    } else {
      const ansiResults = otherAnsiFlange(
        workingVersion,
        sWarnings,
        flangeShort,
        sSpecifiedFlange,
        dblMaxAppPress,
        dblMaxAppTemp,
        sFlangeMaterial
      );
      flangeShort = ansiResults.flangeShort;
      warnings = ansiResults.warnings;
      if (originalFlangeShort) {
        // if a different flange is returned from the tests, then the flange limit has been
        if (specifyMeterParentModelNumber === InsertionModel) {
          if (flangeShort !== C600) {
            warnings = addWarning(
              warnings,
              'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
            );
          }
        } else if (flangeShort !== ANSI_600) {
          warnings = addWarning(
            warnings,
            'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
          );
        }

        flangeShort = originalFlangeShort;
      }
    }
  }

  return { flangeShort, sFlangeMaterial, warnings };
};

const testRetractor = (workingVersion: SizingGlobals, sWarnings: string) => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterFlange, specifyMeterProcessConnection } = state;
  const { VORTEK_NAME } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;

  // Determine if a retractor is needed.
  let flangeShort = specifyMeterFlange;
  if (!flangeShort) {
    flangeShort = specifyMeterProcessConnection;
  }

  if (!flangeShort) {
    return sWarnings;
  }

  const pressure = convert.pressureTo(
    'psi-A',
    selectedMeter.newPipeData.calcPressureMaxVal,
    selectedMeter.newPipeData.pressurePrefix,
    selectedMeter.newPipeData.pressureCoefficient
  );

  if (pressure.gt(new Decimal('50.0'))) {
    // Determine if a retractor is part of the order code.
    if (VORTEK_NAME === utilities.ONICON_NAME) {
      // if (flangeShort === '7' || flangeShort === '8' || flangeShort === '9') {
      //   warnings = addWarning(warnings, "A removable retractor is recommended for this application.");
      // }
    } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
      if (flangeShort < 'M') {
        // Determine if a compression fitting is part of the order code.
        if (flangeShort >= 'H') {
          // Recommend a retractor.
          warnings = addWarning(warnings, 'A removable retractor is recommended for this application.');
        }
      }
    } else {
      const endOfFlange = flangeShort.substring(flangeShort.length - 1);
      if (endOfFlange !== 'R' && endOfFlange !== 'E') {
        // Determine if a compression fitting is part of the order code.
        if (flangeShort.substring(0, 1) !== 'C') {
          // Recommend a retractor.
          warnings = addWarning(warnings, 'A removable retractor is recommended for this application.');
        }
      }
    }
  }

  return warnings;
};

const testProcessConnection = (workingVersion: SizingGlobals, sWarnings: string) => {
  const state = store.getState();
  const { accessories, meterData, meterIndex, specifyMeterFlange, specifyMeterParentModelNumber, specifyMeterProcessConnection } =
    state;
  const {
    InlineModel,
    InlineReduceModel,
    InlineNonReduceModel,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    VorconeModel,
    VorconeReduceModel,
    VORTEK_NAME,
  } = workingVersion;

  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;

  // Verify that the process connection is correct for this application.
  const dblMinus20 = new Decimal('-20');
  const dblMinus320 = new Decimal('-320');
  const dblMinus420 = new Decimal('-420');
  const dblMinTemp = convert.temperatureTo(
    'deg F',
    new Decimal(selectedMeter.newPipeData.temperatureMin),
    selectedMeter.newPipeData.temperatureUnit
  );
  let nf;

  // Determine if the process connection has a flange.
  let flangeShort = specifyMeterFlange;
  if (!flangeShort) {
    flangeShort = specifyMeterProcessConnection;
  }

  let retObj = { flangeShort: '', sFlangeMaterial: '', warnings: '' };

  // If the connection is flanged, the second character of the code
  if (VORTEK_NAME === utilities.AZBIL_NAME) {
    retObj = azbilProcessConnection(workingVersion, warnings);
  } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
    retObj = heinrichsProcessConnection(workingVersion, warnings);
  } else if (VORTEK_NAME === utilities.PROV_NAME || VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) {
    retObj = provProcessConnection(workingVersion, warnings);
  } else {
    retObj = otherProcessConnection(workingVersion, warnings);
  }

  flangeShort = retObj.flangeShort;

  // eslint-disable-next-line
  const sFlangeMaterial = retObj.sFlangeMaterial;
  warnings = retObj.warnings;

  if (flangeShort) {
    let fmk;
    if (
      specifyMeterParentModelNumber === InlineModel ||
      specifyMeterParentModelNumber === InlineReduceModel ||
      specifyMeterParentModelNumber === InlineNonReduceModel ||
      specifyMeterParentModelNumber === VorconeModel ||
      specifyMeterParentModelNumber === VorconeReduceModel
    ) {
      const prevFlange = specifyMeterFlange;
      store.dispatch(actions.setSpecifyMeterFlange(flangeShort));
      fmk = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['2', flangeShort]);
      if (fmk) {
        nf = fmk.OrderCode;
        store.dispatch(actions.setSpecifyMeterFlange(nf));
      } else {
        store.dispatch(actions.setSpecifyMeterFlange(prevFlange));
      }
    } else {
      const prevConn = specifyMeterProcessConnection;
      store.dispatch(actions.setSpecifyMeterProcessConnection(flangeShort));
      fmk = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['11', flangeShort]);
      if (fmk) {
        nf = fmk.OrderCode;
        store.dispatch(actions.setSpecifyMeterProcessConnection(nf));
      } else {
        store.dispatch(actions.setSpecifyMeterProcessConnection(prevConn));
      }
    }
  }

  if (sFlangeMaterial) {
    if (sFlangeMaterial.indexOf('Carbon Steel') > -1) {
      if (selectedMeter.name === '0.5-inch' || selectedMeter.name === '0.75-inch' || selectedMeter.name === '1-inch') {
        warnings = addWarning(warnings, 'Carbon steel is not available for 0.5, 0.75 or 1 inch sizes.');
        store.dispatch(actions.setErrorMessage('Carbon steel is not available for 0.5, 0.75 or 1 inch sizes.'));
      }

      if (accessories.find((accessory) => accessory.code === 'O2')) {
        warnings = addWarning(warnings, 'O2 cleaning is not allowed for carbon steel material.');
        store.dispatch(actions.setErrorMessage('O2 cleaning is not allowed for carbon steel material.'));
      }

      if (dblMinTemp.lt(dblMinus20)) {
        warnings = addWarning(
          warnings,
          'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
        );
      }
    } else if (sFlangeMaterial.indexOf('Stainless Steel') > -1) {
      if (dblMinTemp.lt(dblMinus420)) {
        warnings = addWarning(
          warnings,
          'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
        );
      }
    } else if (sFlangeMaterial.indexOf('Hastelloy') > -1) {
      if (dblMinTemp.lt(dblMinus320)) {
        warnings = addWarning(
          warnings,
          'Select a higher rated process connection, the pressure / temperature limit has been exceeded.'
        );
      }
    }
  }

  if (
    specifyMeterParentModelNumber === InsertionModel ||
    specifyMeterParentModelNumber === TurbineModelShort ||
    specifyMeterParentModelNumber === UltrasonicS34Model ||
    specifyMeterParentModelNumber === UltrasonicS36Model ||
    specifyMeterParentModelNumber === UltrasonicU42Model ||
    specifyMeterParentModelNumber === UltrasonicU43Model ||
    specifyMeterParentModelNumber === UltrasonicU44Model
  ) {
    warnings = testRetractor(workingVersion, warnings);
  }

  return warnings;
};

const testSize = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, pipeInsideDiameter } = state;
  const selectedMeter = meterData[meterIndex];
  let warnings = sWarnings;

  // Test the meter size, and verify that it is not too large.
  const meterSize = new Decimal(selectedMeter.newPipeData.pipeInsideDiameter);
  let selectedPipeUnit = selectedMeter.unit;
  if (!selectedPipeUnit) {
    selectedPipeUnit = 'in';
  }

  const pipeSize = convert.pipeInsideDiameter_To('in', pipeInsideDiameter, selectedPipeUnit);
  if (pipeSize.lt(meterSize)) {
    warnings = addWarning(warnings, 'The meter is larger than the pipe.');
  }

  return warnings;
};

const testPressureTransducer = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterProcessFluid, specifyMeterTransducer } = state;
  const { SPECIAL_STUFF, VORTEK_NAME, VTP_METER, VTPEM_METER } = workingVersion;

  const selectedMeter = meterData[meterIndex];

  const dvPTDataView = meterSpecify.getFeatureArrayByFeatureKey('Name', 'PressureTransducer');
  const dvPFDataView = meterSpecify.getFeatureArrayByFeatureKey('Name', 'ProcessFluid');
  const dblMaxPress = convert.pressureTo(
    'psi-A',
    selectedMeter.newPipeData.calcPressureMaxVal,
    selectedMeter.newPipeData.pressurePrefix,
    selectedMeter.newPipeData.pressureCoefficient
  );
  const dblThirty = new Decimal('30');
  const dblOneHundred = new Decimal('100');
  const dblThreeHundred = new Decimal('300');
  const dblFiveHundred = new Decimal('500');
  const dblFifteenHundred = new Decimal('1500');
  const msg1500 = 'A 1500 PSIA pressure transducer cannot be selected, an external pressure transmitter must be used.';
  let transducerVal: string;
  let index;
  let processFluid: string;
  let warnings = sWarnings;

  if (VORTEK_NAME === utilities.ONICON_NAME) {
    processFluid = specifyMeterProcessFluid;
    index = dvPFDataView.findIndex((item) => item.OrderCode === processFluid) || -1;
    if (!processFluid) {
      processFluid = '';
    }

    if (index > 0 && index < 5) {
      if (dblMaxPress.lt(dblThirty) && index === 1) {
        store.dispatch(actions.setSpecifyMeterProcessFluid(dvPFDataView[1].OrderCode));
      } else if (dblMaxPress.lt(dblOneHundred) && index <= 2) {
        store.dispatch(actions.setSpecifyMeterProcessFluid(dvPFDataView[2].OrderCode));
      } else if (dblMaxPress.lt(dblThreeHundred) && index <= 3) {
        store.dispatch(actions.setSpecifyMeterProcessFluid(dvPFDataView[3].OrderCode));
      } else {
        store.dispatch(actions.setSpecifyMeterProcessFluid(dvPFDataView[4].OrderCode));
      }
    }
  } else {
    processFluid = specifyMeterProcessFluid;
    transducerVal = specifyMeterTransducer;
    if (!transducerVal) {
      transducerVal = '';
    }

    index = dvPTDataView.findIndex((item) => item.OrderCode === transducerVal) || -1;

    if (VORTEK_NAME === utilities.HEINRICH_NAME) {
      if (transducerVal.trim() === 'Special') {
        store.dispatch(actions.setSpecifyMeterTransducer('X'));
        return warnings;
      }

      if (processFluid === VTP_METER || processFluid === VTPEM_METER) {
        if (dblMaxPress.lt(dblThirty) && index <= 1) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[1].OrderCode));
        } else if (dblMaxPress.lt(dblOneHundred) && index <= 2) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[2].OrderCode));
        } else if (dblMaxPress.lt(dblThreeHundred) && index <= 3) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[3].OrderCode));
        } else if (dblMaxPress.lt(dblFiveHundred) && index <= 4) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[4].OrderCode));
        } else if (dblMaxPress.lt(dblFifteenHundred) && index <= 5) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[5].OrderCode));
        } else {
          warnings = addWarning(warnings, msg1500);
          if (transducerVal !== dvPTDataView[0].OrderCode) {
            store.dispatch(actions.setErrorMessage(msg1500));
            store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[0].OrderCode));
          }
        }
      } else if (processFluid === SPECIAL_STUFF) {
        if (transducerVal.trim() !== 'No Pressure Sensor') {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[6].OrderCode));
        }
      } else {
        store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[0].OrderCode));
      }
    } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
      if (processFluid === VTP_METER || processFluid === '2' || processFluid === '5' || processFluid === VTPEM_METER) {
        if (dblMaxPress.lt(dblThirty) && index <= 1) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[1].OrderCode));
        } else if (dblMaxPress.lt(dblOneHundred) && index <= 2) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[2].OrderCode));
        } else if (dblMaxPress.lt(dblThreeHundred) && index <= 3) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[3].OrderCode));
        } else if (dblMaxPress.lt(dblFiveHundred) && index <= 4) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[4].OrderCode));
        } else if (dblMaxPress.lt(dblFifteenHundred) && index <= 5) {
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[5].OrderCode));
        } else {
          warnings = addWarning(warnings, msg1500);
          if (transducerVal !== dvPTDataView[0].OrderCode) {
            store.dispatch(actions.setErrorMessage(msg1500));
            store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[0].OrderCode));
          }
        }
      } else if (dvPTDataView && dvPTDataView.length > 0) {
        store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[0].OrderCode));
      }
    } else if (processFluid === VTP_METER || processFluid === VTPEM_METER) {
      if (dblMaxPress.lt(dblThirty) && index <= 1) {
        store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[1].OrderCode));
      } else if (dblMaxPress.lt(dblOneHundred) && index <= 2) {
        store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[2].OrderCode));
      } else if (dblMaxPress.lt(dblThreeHundred) && index <= 3) {
        store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[3].OrderCode));
      } else if (dblMaxPress.lt(dblFiveHundred) && index <= 4) {
        store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[4].OrderCode));
      } else if (dblMaxPress.lt(dblFifteenHundred) && index <= 5) {
        store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[5].OrderCode));
      } else {
        warnings = addWarning(warnings, msg1500);
        if (transducerVal !== dvPTDataView[0].OrderCode) {
          store.dispatch(actions.setErrorMessage(msg1500));
          store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[0].OrderCode));
        }
      }
    } else if (dvPTDataView && dvPTDataView.length > 0) {
      store.dispatch(actions.setSpecifyMeterTransducer(dvPTDataView[0].OrderCode));
    }
  }

  return warnings;
};

const updateOutputSignalCombo = (
  workingVersion: SizingGlobals,
  fluidType: string,
  power: string,
  optionsOnly: boolean
): Feature[] => {
  const state = store.getState();
  const { specifyMeterInputPower, specifyMeterOutputSignal, specifyMeterParentModelNumber } = state;
  const {
    AC_POWERED,
    ALARM_BACNET,
    ALARM_BACNET_POE,
    ALARM_HART,
    ALARM_MODBUS,
    ALARM_MODBUS_POE,
    BACNET,
    BACNET_POE,
    INLINE_NON_LOOP_ORDER,
    InlineModel,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
    INSERTION_NON_LOOP_ORDER,
    LOOP_OUTPUT,
    LOOP_POWERED,
    MORE_LOOP_POWERED,
    NO_ALARM_MODBUS_POE,
    NO_ALARM_MODBUS_OUTPUT,
    ONE_OUTPUT,
    POE,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    VDC_1236_POWERED,
    V_METER,
    VTP_METER,
    VT_METER,
    VTEM_METER,
    VTEP_METER,
    VETEP_METER,
    VTPEM_METER,
    VTEPEM_METER,
    VETEPEM_METER,
    VorconeModel,
    VorconeReduceModel,
    VORTEK_NAME,
  } = workingVersion;

  // Update the combo box.
  const options = [] as Feature[];
  let outputChoiceDescription;
  let hart1;
  let opt: Feature | undefined;
  let option: Feature;
  let foundDesc = false;

  if (power === LOOP_POWERED || MORE_LOOP_POWERED.includes(specifyMeterInputPower)) {
    // Only the first one is valid for the "V" option.
    // No Alarm
    const loop = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', LOOP_OUTPUT]);
    if (loop) {
      if (specifyMeterOutputSignal.length === 0 || specifyMeterOutputSignal !== loop.Description) {
        options.push(loop);

        if (!optionsOnly) {
          store.dispatch(actions.setSpecifyMeterOutputSignal(loop.OrderCode));
        }
      }
    }
  } else if (power === POE) {
    outputChoiceDescription = specifyMeterOutputSignal;

    if (
      outputChoiceDescription !== NO_ALARM_MODBUS_POE &&
      outputChoiceDescription !== BACNET_POE &&
      outputChoiceDescription !== ALARM_MODBUS_POE &&
      outputChoiceDescription !== ALARM_BACNET_POE
    ) {
      outputChoiceDescription = NO_ALARM_MODBUS_POE;
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', NO_ALARM_MODBUS_POE]);
    if (opt) {
      if (outputChoiceDescription === NO_ALARM_MODBUS_POE && !optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = NO_ALARM_MODBUS_POE;
      option.Description = opt.Description;
      options.push(option);
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', BACNET_POE]);
    if (opt) {
      if (outputChoiceDescription === BACNET_POE && !optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = BACNET_POE;
      option.Description = opt.Description;
      options.push(option);
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', ALARM_MODBUS_POE]);
    if (opt) {
      if (outputChoiceDescription === ALARM_MODBUS_POE && !optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = ALARM_MODBUS_POE;
      option.Description = opt.Description;
      options.push(option);
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', ALARM_BACNET_POE]);
    if (opt) {
      if (outputChoiceDescription === ALARM_BACNET_POE && !optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = ALARM_BACNET_POE;
      option.Description = opt.Description;
      options.push(option);
    }
  } else if (
    fluidType === V_METER ||
    fluidType === VT_METER ||
    fluidType === VTP_METER ||
    fluidType === VTEM_METER ||
    fluidType === VTEP_METER ||
    fluidType === VETEP_METER ||
    fluidType === VTPEM_METER ||
    fluidType === VTEPEM_METER ||
    fluidType === VETEPEM_METER
  ) {
    outputChoiceDescription = specifyMeterOutputSignal;

    if (
      VORTEK_NAME === utilities.INNOVA_NAME &&
      power !== 'PV1' &&
      (outputChoiceDescription === 'V4M-IP' ||
        outputChoiceDescription === 'V4B-IP' ||
        outputChoiceDescription === 'V6M-IP' ||
        outputChoiceDescription === 'V6B-IP')
    ) {
      outputChoiceDescription = 'V4H';
    }

    if (VORTEK_NAME === utilities.AZBIL_NAME) {
      if (outputChoiceDescription === 'A') {
        outputChoiceDescription = 'B';
      }

      option = new Feature();
      option.Code = ONE_OUTPUT;
      option.OrderCode = ONE_OUTPUT;
      option.Description = constants.HART_ONLY;
      options.push(option);

      option = new Feature();
      option.Code = NO_ALARM_MODBUS_OUTPUT;
      option.OrderCode = NO_ALARM_MODBUS_OUTPUT;
      option.Description = constants.MODBUS_ONLY;
      options.push(option);

      option = new Feature();
      option.Code = BACNET;
      option.OrderCode = BACNET;
      option.Description = constants.BACNET;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_HART;
      option.OrderCode = ALARM_HART;
      option.Description = constants.HART_ALARM;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_MODBUS;
      option.OrderCode = ALARM_MODBUS;
      option.Description = constants.MODBUS_ALARM;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_BACNET;
      option.OrderCode = ALARM_BACNET;
      option.Description = constants.BACNET_ALARM;
      options.push(option);

      if (!optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(outputChoiceDescription));
      }
    } else if (VORTEK_NAME === utilities.ONICON_NAME) {
      if (outputChoiceDescription === LOOP_OUTPUT) {
        outputChoiceDescription = NO_ALARM_MODBUS_OUTPUT;
      }

      if (
        (power === AC_POWERED || power === VDC_1236_POWERED) &&
        (outputChoiceDescription === NO_ALARM_MODBUS_POE ||
          outputChoiceDescription === ALARM_MODBUS_POE ||
          outputChoiceDescription === BACNET_POE ||
          outputChoiceDescription === ALARM_BACNET_POE)
      ) {
        outputChoiceDescription = NO_ALARM_MODBUS_OUTPUT;
      }

      option = new Feature();
      option.Code = NO_ALARM_MODBUS_OUTPUT;
      option.OrderCode = NO_ALARM_MODBUS_OUTPUT;
      option.Description = constants.ONICON_MODBUS_ONLY;
      options.push(option);

      option = new Feature();
      option.Code = BACNET;
      option.OrderCode = BACNET;
      option.Description = constants.ONICON_BACNET;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_MODBUS;
      option.OrderCode = ALARM_MODBUS;
      option.Description = constants.ONICON_ALARM_MODBUS;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_BACNET;
      option.OrderCode = ALARM_BACNET;
      option.Description = constants.ONICON_ALARM_BACNET;
      options.push(option);

      hart1 = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', outputChoiceDescription ?? '']);
      if (hart1 && !optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(hart1.OrderCode));
      }
    } else if (
      VORTEK_NAME === utilities.PROV_NAME ||
      VORTEK_NAME === utilities.PANAFLOW_NAME ||
      VORTEK_NAME === utilities.SPIRAX_NAME ||
      VORTEK_NAME === utilities.GESTRA_NAME ||
      VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
    ) {
      if (outputChoiceDescription === LOOP_OUTPUT) {
        outputChoiceDescription = ONE_OUTPUT;
      }

      if (
        (power === AC_POWERED || power === VDC_1236_POWERED) &&
        (outputChoiceDescription === NO_ALARM_MODBUS_POE ||
          outputChoiceDescription === ALARM_MODBUS_POE ||
          outputChoiceDescription === BACNET_POE ||
          outputChoiceDescription === ALARM_BACNET_POE)
      ) {
        outputChoiceDescription = ONE_OUTPUT;
      }

      option = new Feature();
      option.Code = ONE_OUTPUT;
      option.OrderCode = ONE_OUTPUT;
      option.Description = constants.HART_ONLY;
      options.push(option);

      option = new Feature();
      option.Code = NO_ALARM_MODBUS_OUTPUT;
      option.OrderCode = NO_ALARM_MODBUS_OUTPUT;
      option.Description = constants.MODBUS_ONLY;
      options.push(option);

      if (power !== AC_POWERED && power !== VDC_1236_POWERED) {
        option = new Feature();
        option.Code = NO_ALARM_MODBUS_POE;
        option.OrderCode = NO_ALARM_MODBUS_POE;
        option.Description = constants.MODBUS_IP;
        options.push(option);
      }

      option = new Feature();
      option.Code = BACNET;
      option.OrderCode = BACNET;
      option.Description = constants.BACNET;
      options.push(option);

      if (power !== AC_POWERED && power !== VDC_1236_POWERED) {
        option = new Feature();
        option.Code = constants.BACNET_IP;
        option.OrderCode = constants.BACNET_IP;
        option.Description = constants.BACNET_IP;
        options.push(option);
      }

      option = new Feature();
      option.Code = ALARM_HART;
      option.OrderCode = ALARM_HART;
      option.Description = constants.HART_ALARM;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_MODBUS;
      option.OrderCode = ALARM_MODBUS;
      option.Description = constants.MODBUS_ALARM;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_BACNET;
      option.OrderCode = ALARM_BACNET;
      option.Description = constants.BACNET_ALARM;
      options.push(option);

      hart1 = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', outputChoiceDescription ?? '']);
      if (hart1 && !optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(hart1.OrderCode));
      }
    } else {
      if (outputChoiceDescription === LOOP_OUTPUT) {
        outputChoiceDescription = ONE_OUTPUT;
      }

      option = new Feature();
      option.Code = ONE_OUTPUT;
      option.OrderCode = ONE_OUTPUT;
      option.Description = constants.HART_ONLY;
      options.push(option);

      option = new Feature();
      option.Code = NO_ALARM_MODBUS_OUTPUT;
      option.OrderCode = NO_ALARM_MODBUS_OUTPUT;
      option.Description = constants.MODBUS_ONLY;
      options.push(option);

      if (VORTEK_NAME !== utilities.HEINRICH_NAME) {
        option = new Feature();
        option.Code = BACNET;
        option.OrderCode = BACNET;
        option.Description = constants.BACNET;
        options.push(option);
      }

      if (VORTEK_NAME === utilities.INNOVA_NAME && power === 'PV1') {
        option = new Feature();
        option.Code = '60';
        option.OrderCode = 'V4M-IP';
        option.Description = 'One analog output (4-20 mA), one pulse, MODBUS TCP/IP';
        options.push(option);
        option = new Feature();
        option.Code = '61';
        option.OrderCode = 'V4B-IP';
        option.Description = 'One analog output (4-20 mA), one pulse, BACnet/IP';
        options.push(option);
      }

      option = new Feature();
      option.Code = ALARM_HART;
      option.OrderCode = ALARM_HART;
      option.Description = constants.HART_ALARM;
      options.push(option);

      option = new Feature();
      option.Code = ALARM_MODBUS;
      option.OrderCode = ALARM_MODBUS;
      option.Description = constants.MODBUS_ALARM;
      options.push(option);

      if (VORTEK_NAME !== utilities.HEINRICH_NAME) {
        option = new Feature();
        option.Code = ALARM_BACNET;
        option.OrderCode = ALARM_BACNET;
        option.Description = constants.BACNET_ALARM;
        options.push(option);
      }

      hart1 = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', outputChoiceDescription ?? '']);
      if (hart1 && !optionsOnly) {
        store.dispatch(actions.setSpecifyMeterOutputSignal(hart1.OrderCode));
      }

      if (VORTEK_NAME === utilities.INNOVA_NAME && power === 'PV1') {
        option = new Feature();
        option.Code = '62';
        option.OrderCode = 'V6M-IP';
        option.Description = 'Three analog outputs (4-20 mA), one pulse, MODBUS TCP/IP';
        options.push(option);
        option = new Feature();
        option.Code = '63';
        option.OrderCode = 'V6B-IP';
        option.Description = 'Three analog outputs (4-20 mA), one pulse, BACnet/IP';
        options.push(option);
      }
    }
  } else if (power === AC_POWERED) {
    outputChoiceDescription = specifyMeterOutputSignal;

    if (
      outputChoiceDescription === NO_ALARM_MODBUS_POE ||
      outputChoiceDescription === BACNET_POE ||
      outputChoiceDescription === ALARM_MODBUS_POE ||
      outputChoiceDescription === ALARM_BACNET_POE
    ) {
      outputChoiceDescription = ONE_OUTPUT;
    }

    if (VORTEK_NAME !== utilities.ONICON_NAME) {
      opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', ONE_OUTPUT]);
      if (opt) {
        if (outputChoiceDescription === ONE_OUTPUT) {
          if (!optionsOnly) {
            store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
          }
        }

        option = new Feature();
        option.Code = opt.Code;
        option.OrderCode = ONE_OUTPUT;
        option.Description = opt.Description;
        options.push(option);
      }
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', NO_ALARM_MODBUS_OUTPUT]);
    if (opt) {
      if (outputChoiceDescription === NO_ALARM_MODBUS_OUTPUT) {
        if (!optionsOnly) {
          store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
        }
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = NO_ALARM_MODBUS_OUTPUT;
      option.Description = opt.Description;
      options.push(option);
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', BACNET]);
    if (opt) {
      if (outputChoiceDescription === BACNET) {
        if (!optionsOnly) {
          store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
        }
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = BACNET;
      option.Description = opt.Description;
      options.push(option);
    }

    if (VORTEK_NAME !== utilities.ONICON_NAME) {
      opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', ALARM_HART]);
      if (opt) {
        if (outputChoiceDescription === ALARM_HART) {
          if (!optionsOnly) {
            store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
          }
        }

        option = new Feature();
        option.Code = opt.Code;
        option.OrderCode = ALARM_HART;
        option.Description = opt.Description;
        options.push(option);
      }
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', ALARM_MODBUS]);
    if (opt) {
      if (outputChoiceDescription === ALARM_MODBUS) {
        if (!optionsOnly) {
          store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
        }
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = ALARM_MODBUS;
      option.Description = opt.Description;
      options.push(option);
    }

    opt = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', ALARM_BACNET]);
    if (opt) {
      if (outputChoiceDescription === ALARM_BACNET) {
        if (!optionsOnly) {
          store.dispatch(actions.setSpecifyMeterOutputSignal(opt.OrderCode));
        }
      }

      option = new Feature();
      option.Code = opt.Code;
      option.OrderCode = ALARM_BACNET;
      option.Description = opt.Description;
      options.push(option);
    }
  } else {
    // All options are to be made available except loop powered.  Only velocity meters
    // can be loop powered.
    const dvDataView = meterSpecify.getFeatureArrayByFeatureNumber('8');
    outputChoiceDescription = specifyMeterOutputSignal;
    if (outputChoiceDescription === LOOP_OUTPUT) {
      outputChoiceDescription = ONE_OUTPUT;
    }

    const orderCodes: string[] = [];
    let i;
    let nonLoopIndex;

    if (
      (VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.PANAFLOW_NAME ||
        VORTEK_NAME === utilities.INNOVA_NAME ||
        VORTEK_NAME === utilities.ARMSTRONG_NAME ||
        VORTEK_NAME === utilities.SPIRAX_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) &&
      (specifyMeterParentModelNumber === InlineModel ||
        specifyMeterParentModelNumber === InlineReduceModel ||
        specifyMeterParentModelNumber === InlineNonReduceModel ||
        specifyMeterParentModelNumber === VorconeModel ||
        specifyMeterParentModelNumber === VorconeReduceModel) &&
      INLINE_NON_LOOP_ORDER.length > 0
    ) {
      for (nonLoopIndex = 0; nonLoopIndex < INLINE_NON_LOOP_ORDER.length; nonLoopIndex++) {
        for (i = 0; i < dvDataView.length; i++) {
          if (
            dvDataView[i].Code === INLINE_NON_LOOP_ORDER[nonLoopIndex] &&
            dvDataView[i].OrderCode !== LOOP_OUTPUT &&
            !orderCodes.includes(dvDataView[i].OrderCode)
          ) {
            if (outputChoiceDescription === dvDataView[i].OrderCode && !optionsOnly) {
              store.dispatch(actions.setSpecifyMeterOutputSignal(dvDataView[i].OrderCode));
            }

            option = new Feature();
            option.Code = dvDataView[i].Code;
            option.OrderCode = dvDataView[i].OrderCode;
            option.Description = dvDataView[i].Description;
            options.push(option);
            orderCodes.push(dvDataView[i].OrderCode);
            break;
          }
        }
      }
    } else if (
      (VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.PANAFLOW_NAME ||
        VORTEK_NAME === utilities.INNOVA_NAME ||
        VORTEK_NAME === utilities.ARMSTRONG_NAME ||
        VORTEK_NAME === utilities.SPIRAX_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME) &&
      (specifyMeterParentModelNumber === InsertionModel ||
        specifyMeterParentModelNumber === TurbineModelShort ||
        specifyMeterParentModelNumber === UltrasonicS34Model ||
        specifyMeterParentModelNumber === UltrasonicS36Model ||
        specifyMeterParentModelNumber === UltrasonicU42Model ||
        specifyMeterParentModelNumber === UltrasonicU43Model ||
        specifyMeterParentModelNumber === UltrasonicU44Model) &&
      INSERTION_NON_LOOP_ORDER.length > 0
    ) {
      for (nonLoopIndex = 0; nonLoopIndex < INSERTION_NON_LOOP_ORDER.length; nonLoopIndex++) {
        for (i = 0; i < dvDataView.length; i++) {
          if (
            dvDataView[i].Code === INSERTION_NON_LOOP_ORDER[nonLoopIndex] &&
            dvDataView[i].OrderCode !== LOOP_OUTPUT &&
            !orderCodes.includes(dvDataView[i].OrderCode)
          ) {
            if (outputChoiceDescription === dvDataView[i].OrderCode && !optionsOnly) {
              store.dispatch(actions.setSpecifyMeterOutputSignal(dvDataView[i].OrderCode));
            }

            option = new Feature();
            option.Code = dvDataView[i].Code;
            option.OrderCode = dvDataView[i].OrderCode;
            option.Description = dvDataView[i].Description;
            options.push(option);
            orderCodes.push(dvDataView[i].OrderCode);
            break;
          }
        }
      }
    } else if (
      VORTEK_NAME === utilities.ONICON_NAME &&
      (specifyMeterParentModelNumber === InsertionModel ||
        specifyMeterParentModelNumber === TurbineModelShort ||
        specifyMeterParentModelNumber === UltrasonicS34Model ||
        specifyMeterParentModelNumber === UltrasonicS36Model ||
        specifyMeterParentModelNumber === UltrasonicU42Model ||
        specifyMeterParentModelNumber === UltrasonicU43Model ||
        specifyMeterParentModelNumber === UltrasonicU44Model)
    ) {
      for (nonLoopIndex = 0; nonLoopIndex < INSERTION_NON_LOOP_ORDER.length; nonLoopIndex++) {
        for (i = 0; i < dvDataView.length; i++) {
          if (dvDataView[i].Code === INSERTION_NON_LOOP_ORDER[nonLoopIndex] && !orderCodes.includes(dvDataView[i].OrderCode)) {
            if (outputChoiceDescription === dvDataView[i].OrderCode) {
              foundDesc = true;
            }
            break;
          }
        }
      }

      for (nonLoopIndex = 0; nonLoopIndex < INSERTION_NON_LOOP_ORDER.length; nonLoopIndex++) {
        for (i = 0; i < dvDataView.length; i++) {
          if (dvDataView[i].Code === INSERTION_NON_LOOP_ORDER[nonLoopIndex] && !orderCodes.includes(dvDataView[i].OrderCode)) {
            if (outputChoiceDescription === dvDataView[i].OrderCode || !foundDesc) {
              foundDesc = true;
              if (!optionsOnly) {
                store.dispatch(actions.setSpecifyMeterOutputSignal(dvDataView[i].OrderCode));
              }
            }

            option = new Feature();
            option.Code = dvDataView[i].Code;
            option.OrderCode = dvDataView[i].OrderCode;
            option.Description = dvDataView[i].Description;
            options.push(option);
            orderCodes.push(dvDataView[i].OrderCode);
            break;
          }
        }
      }
    } else if (
      VORTEK_NAME === utilities.ONICON_NAME &&
      (specifyMeterParentModelNumber === InlineModel ||
        specifyMeterParentModelNumber === InlineReduceModel ||
        specifyMeterParentModelNumber === InlineNonReduceModel ||
        specifyMeterParentModelNumber === VorconeModel ||
        specifyMeterParentModelNumber === VorconeReduceModel)
    ) {
      for (nonLoopIndex = 0; nonLoopIndex < INLINE_NON_LOOP_ORDER.length; nonLoopIndex++) {
        for (i = 0; i < dvDataView.length; i++) {
          if (dvDataView[i].Code === INLINE_NON_LOOP_ORDER[nonLoopIndex] && !orderCodes.includes(dvDataView[i].OrderCode)) {
            if (outputChoiceDescription === dvDataView[i].OrderCode) {
              foundDesc = true;
            }
            break;
          }
        }
      }

      for (nonLoopIndex = 0; nonLoopIndex < INLINE_NON_LOOP_ORDER.length; nonLoopIndex++) {
        for (i = 0; i < dvDataView.length; i++) {
          if (dvDataView[i].Code === INLINE_NON_LOOP_ORDER[nonLoopIndex] && !orderCodes.includes(dvDataView[i].OrderCode)) {
            if (outputChoiceDescription === dvDataView[i].OrderCode || !foundDesc) {
              foundDesc = true;
              if (!optionsOnly) {
                store.dispatch(actions.setSpecifyMeterOutputSignal(dvDataView[i].OrderCode));
              }
            }

            option = new Feature();
            option.Code = dvDataView[i].Code;
            option.OrderCode = dvDataView[i].OrderCode;
            option.Description = dvDataView[i].Description;
            options.push(option);
            orderCodes.push(dvDataView[i].OrderCode);
            break;
          }
        }
      }
    } else {
      if (
        VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.SPIRAX_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
      ) {
        if (
          power === VDC_1236_POWERED &&
          (outputChoiceDescription === NO_ALARM_MODBUS_POE ||
            outputChoiceDescription === ALARM_MODBUS_POE ||
            outputChoiceDescription === BACNET_POE ||
            outputChoiceDescription === ALARM_BACNET_POE)
        ) {
          outputChoiceDescription = ONE_OUTPUT;
        }
      }

      for (i = 0; i < dvDataView.length; i++) {
        if (dvDataView[i].OrderCode !== LOOP_OUTPUT && !orderCodes.includes(dvDataView[i].OrderCode)) {
          if (
            power !== VDC_1236_POWERED ||
            (power === VDC_1236_POWERED &&
              dvDataView[i].OrderCode !== NO_ALARM_MODBUS_POE &&
              dvDataView[i].OrderCode !== ALARM_MODBUS_POE &&
              dvDataView[i].OrderCode !== BACNET_POE &&
              dvDataView[i].OrderCode !== ALARM_BACNET_POE)
          ) {
            if (outputChoiceDescription === dvDataView[i].OrderCode && !optionsOnly) {
              store.dispatch(actions.setSpecifyMeterOutputSignal(dvDataView[i].OrderCode));
            }

            option = new Feature();
            option.Code = dvDataView[i].Code;
            option.OrderCode = dvDataView[i].OrderCode;
            option.Description = dvDataView[i].Description;
            options.push(option);
            orderCodes.push(dvDataView[i].OrderCode);
          }
        }
      }
    }
  }

  return options;
};

const testInputPower = (workingVersion: SizingGlobals) => {
  const state = store.getState();
  const { specifyMeterInputPower, specifyMeterProcessFluid } = state;
  const { LOOP_POWERED } = workingVersion;

  if (specifyMeterProcessFluid && specifyMeterInputPower) {
    const inputPowerObj = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['7', specifyMeterInputPower]);
    if (inputPowerObj) {
      const op = inputPowerObj.OrderCode;
      store.dispatch(actions.setSpecifyMeterInputPower(op));

      if (inputPowerObj.Description.toLowerCase().indexOf('loop') > 0) {
        updateOutputSignalCombo(workingVersion, specifyMeterProcessFluid, LOOP_POWERED, false);
      } else {
        updateOutputSignalCombo(workingVersion, specifyMeterProcessFluid, op, false);
      }
    }
  }
};

const testOutputSignal = (workingVersion: SizingGlobals) => {
  const state = store.getState();
  const { specifyMeterInputPower, specifyMeterOutputSignal } = state;
  const { LOOP_OUTPUT, LOOP_POWERED, MORE_LOOP_POWERED } = workingVersion;

  if (specifyMeterInputPower === LOOP_POWERED || MORE_LOOP_POWERED.includes(specifyMeterInputPower)) {
    const loop = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['8', LOOP_OUTPUT]);
    if (loop) {
      store.dispatch(actions.setSpecifyMeterOutputSignal(loop.OrderCode));
    }
  }

  if (
    specifyMeterOutputSignal === LOOP_OUTPUT &&
    !(specifyMeterInputPower === LOOP_POWERED || MORE_LOOP_POWERED.includes(specifyMeterInputPower))
  ) {
    const newInputPower = meterSpecify.getFeatureByMultiKey(['FeatureNumber', 'OrderCode'], ['7', LOOP_POWERED]);
    if (newInputPower) {
      store.dispatch(actions.setSpecifyMeterInputPower(newInputPower.OrderCode));
    }
  }
};

const testXMTR = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterDiffPressXMTR } = state;
  const { DIFF_PRESS_XMTR_CUST } = workingVersion;

  let msg;
  let warnings = sWarnings;
  if (specifyMeterDiffPressXMTR === DIFF_PRESS_XMTR_CUST) {
    msg = 'Customer supplied differential pressure transmitter output must be scaled to factory specifications.';
    warnings = addWarning(warnings, msg);
    store.dispatch(actions.setWarningMessage('Differential Pressure Transmitter Warning'));
  }

  return warnings;
};

const testXMTRMani = (workingVersion: SizingGlobals, sWarnings: string): string => {
  return sWarnings;
};

const testDiagDiffPressXMTR = (workingVersion: SizingGlobals, sWarnings: string): string => {
  return sWarnings;
};

const testDiagAddPressTop = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterDiagAddPressTap } = state;
  const { DIAG_ADD_PRESS_TAP_FT } = workingVersion;

  const selectedMeter = meterData[meterIndex];

  let msg;
  let warnings = sWarnings;
  const pipeId = new Decimal(selectedMeter.size);
  if (specifyMeterDiagAddPressTap === DIAG_ADD_PRESS_TAP_FT && (pipeId.lt(constants.TWO) || pipeId.gt(constants.FOUR))) {
    msg = 'Sizes 2-4 inch only. Spool piece with pressure tap required above 4 inches.';
    warnings = addWarning(warnings, msg);
    store.dispatch(actions.setWarningMessage('Diagnostic Additional Pressure Tap Warning'));
  }

  return warnings;
};

const testS34ProcessFluid = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { accessories, specifyMeterProcessFluid } = state;

  let warnings = sWarnings;

  if (specifyMeterProcessFluid.length > 0) {
    if (specifyMeterProcessFluid === 'VER' || specifyMeterProcessFluid === 'VET' || specifyMeterProcessFluid === 'VERER-EM') {
      if (accessories.find((accessory) => accessory.code === 'SC')) {
        warnings = addWarning(
          warnings,
          'SonoConnect™ Breakout Box is Required for these Models, External Temperature Sensor to be Supplied by Others.'
        );
      }
    }
  }

  return warnings;
};

const testS34S36Transducer = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const warnings = addWarning(sWarnings, 'Corresponding Rail Fixture/s included with the selection of each Transducer option');
  return warnings;
};

const testS34S36CableLength = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterCableLength } = state;

  if (specifyMeterCableLength.length === 0) {
    store.dispatch(actions.setSpecifyMeterCableLength('1'));
  }

  return sWarnings;
};

const testS36ProcessFluid = (workingVersion: SizingGlobals, sWarnings: string): string => {
  return sWarnings;
};

const testU42U43U44OutputAccessories = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { accessories, specifyMeterOutputSignal, specifyMeterParentModelNumber } = state;
  const { UltrasonicU44Model } = workingVersion;

  let warning;
  let warnings = sWarnings;

  if (specifyMeterOutputSignal === '1' || specifyMeterOutputSignal === '2') {
    if (specifyMeterOutputSignal === '1' && accessories.find((accessory) => accessory.code === 'PT1000')) {
      if (specifyMeterParentModelNumber === UltrasonicU44Model) {
        warning =
          'The PT1000 temperature sensor is only available with Output Option 2 (i.e. Volumetric & Energy (BTU) Flowmeter for Liquids)';
      } else {
        warning =
          'The PT1000 temperature sensor is only available with Output Option 2 (i.e. OCT, Relay, RS-232/RS-485, 4-20mA, RTD)';
      }
      warnings = addWarning(warnings, warning);
    } else if (specifyMeterOutputSignal === '2' && !accessories.find((accessory) => accessory.code === 'PT1000')) {
      warning =
        'It is recommended that the PT1000 temperature sensor, found under the "Accessories" tab,\nbe added to the model code when Output Option 2 is selected';
      warnings = addWarning(warnings, warning);
    }
  }

  return warnings;
};

const testU42U43U44TransducerTemperature = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterTransducer } = state;

  const selectedMeter = meterData[meterIndex];

  const minTemp = new Decimal(selectedMeter.newPipeData.temperatureMin);
  const maxTemp = new Decimal(selectedMeter.newPipeData.temperatureMax);
  const tempUnit = selectedMeter.newPipeData.temperatureUnit;
  let transducerText = specifyMeterTransducer;
  const feature = meterSpecify.getFeatureByMultiKey(['Name', 'OrderCode'], ['Transducer', transducerText]);
  transducerText = feature?.Description || '';

  const transducerParts = transducerText.split(' ');
  let transducerMinTemp;
  let transducerMaxTemp;
  let tempType = tempUnit;
  let warnings = sWarnings;

  // U44 has 9 parts
  if (transducerParts.length === 9) {
    if (tempUnit === 'deg F') {
      tempType = 'F';
      transducerMinTemp = transducerParts[4];
      transducerMaxTemp = transducerParts[6];
      transducerMaxTemp = transducerMaxTemp.split('(')[0];
    } else if (tempUnit === 'deg C') {
      tempType = 'C';
      transducerMinTemp = transducerParts[6];
      transducerMinTemp = transducerMinTemp.split('(')[1];
      transducerMinTemp = transducerMinTemp.substring(1);
      transducerMaxTemp = transducerParts[8];
      transducerMaxTemp = transducerMaxTemp.split('(')[0];
    } else {
      tempType = tempUnit;
      transducerMinTemp = transducerParts[4];
      transducerMaxTemp = transducerParts[6];
      transducerMaxTemp = transducerMaxTemp.split('(')[0];
    }
  } else if (transducerParts.length === 8) {
    if (tempUnit === 'deg F') {
      tempType = 'F';
      transducerMinTemp = transducerParts[2];
      transducerMaxTemp = transducerParts[4];
    } else if (tempUnit === 'deg C') {
      tempType = 'C';
      transducerMinTemp = transducerParts[5].substring(1);
      transducerMaxTemp = transducerParts[7];
    } else {
      transducerMinTemp = transducerParts[2];
      transducerMaxTemp = transducerParts[4];
    }
  }

  let index = transducerMinTemp?.indexOf('°');
  transducerMinTemp = transducerMinTemp?.substring(0, index);
  index = transducerMaxTemp?.indexOf('°');
  transducerMaxTemp = transducerMaxTemp?.substring(0, index);

  transducerMinTemp = new Decimal(transducerMinTemp || '0');
  transducerMaxTemp = new Decimal(transducerMaxTemp || '0');

  if (tempUnit !== 'deg F' && tempUnit !== 'deg C') {
    transducerMinTemp = convert.temperatureTo(tempType, transducerMinTemp, 'deg F');
    transducerMaxTemp = convert.temperatureTo(tempType, transducerMaxTemp, 'deg F');
  }

  if (minTemp.lt(transducerMinTemp) || maxTemp.gt(transducerMaxTemp)) {
    const warning = `The temperature range of the application (${minTemp.toString()}°${tempType} to ${maxTemp.toString()}°${tempType}) is outside the selected transducer temperature range (${transducerMinTemp.toString()}°${tempType} to ${transducerMaxTemp.toString()}°${tempType}).`;
    warnings = addWarning(warnings, warning);
  }

  return warnings;
};

const testApprovals = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterApprovals } = state;

  if (!specifyMeterApprovals.length) {
    store.dispatch(actions.setSpecifyMeterApprovals('S'));
  }

  return sWarnings;
};

const testFaceToFace = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterFaceToFace } = state;

  if (!specifyMeterFaceToFace.length) {
    store.dispatch(actions.setSpecifyMeterFaceToFace('1'));
  }

  return sWarnings;
};

const testProMModel = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { accessories, specifyMeterProcessFluid } = state;

  if (specifyMeterProcessFluid === '1') {
    if (accessories.find((accessory) => accessory.code === 'CRTD' || accessory.code === 'IRTD')) {
      store.dispatch(actions.setAccessories([]));
    }
  }

  return sWarnings;
};

const testAccuracy = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterAccuracy, specifyMeterProcessFluid } = state;

  if (!specifyMeterAccuracy.length) {
    store.dispatch(actions.setSpecifyMeterAccuracy('1'));
  }

  if (specifyMeterProcessFluid === '2') {
    store.dispatch(actions.setSpecifyMeterAccuracy('1'));
  }

  return sWarnings;
};

const testProMPipeMaterial = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterPipeMaterial } = state;

  let currentPipeMaterial = specifyMeterPipeMaterial;

  if (!currentPipeMaterial.length) {
    currentPipeMaterial = 'C';
    store.dispatch(actions.setSpecifyMeterPipeMaterial(currentPipeMaterial));
  }

  const selectedMeter = meterData[meterIndex];

  if (selectedMeter.diameter === '24' && currentPipeMaterial === 'S') {
    currentPipeMaterial = 'C';
    store.dispatch(actions.setSpecifyMeterPipeMaterial(currentPipeMaterial));
  }

  return sWarnings;
};

const testElectrodeMaterial = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterElectrodeMaterial } = state;

  if (!specifyMeterElectrodeMaterial.length) {
    store.dispatch(actions.setSpecifyMeterElectrodeMaterial('S'));
  }

  return sWarnings;
};

const testLinerMaterial = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { meterData, meterIndex, specifyMeterLinerMaterial } = state;

  let currentLinerMaterial = specifyMeterLinerMaterial;

  if (!specifyMeterLinerMaterial.length) {
    currentLinerMaterial = 'R';
    store.dispatch(actions.setSpecifyMeterLinerMaterial(currentLinerMaterial));
  }

  const selectedMeter = meterData[meterIndex];
  if (
    (selectedMeter.diameter === '0.5' ||
      selectedMeter.diameter === '0.75' ||
      selectedMeter.diameter === '1' ||
      selectedMeter.diameter === '1.5') &&
    currentLinerMaterial === 'R'
  ) {
    currentLinerMaterial = 'P';
    store.dispatch(actions.setSpecifyMeterLinerMaterial(currentLinerMaterial));
  }

  if (selectedMeter.diameter === '24' && currentLinerMaterial === 'F') {
    currentLinerMaterial = 'R';
    store.dispatch(actions.setSpecifyMeterLinerMaterial(currentLinerMaterial));
  }

  return sWarnings;
};

const testGrounding = (workingVersion: SizingGlobals, sWarnings: string): string => {
  const state = store.getState();
  const { specifyMeterGrounding } = state;

  if (!specifyMeterGrounding.length) {
    store.dispatch(actions.setSpecifyMeterGrounding('1'));
  }

  return sWarnings;
};

const validateAll = (): string => {
  const state = store.getState();
  const { meterData, meterIndex, meterType, provSizingGlobals, specifyMeterParentModelNumber, sizingGlobals } = state;

  let sWarnings = '';
  let bMakeVT;
  let workingVersion = sizingGlobals;

  if (
    workingVersion.VORTEK_NAME === utilities.SPIRAX_NAME &&
    (specifyMeterParentModelNumber === workingVersion.VorconeModel ||
      specifyMeterParentModelNumber === workingVersion.VorconeReduceModel)
  ) {
    workingVersion = provSizingGlobals;
  }

  sWarnings = testParent(workingVersion, sWarnings);
  if (specifyMeterParentModelNumber === workingVersion.UltrasonicS34Model) {
    sWarnings = testS34ProcessFluid(workingVersion, sWarnings);
    sWarnings = testS34S36Transducer(workingVersion, sWarnings);
    sWarnings = testS34S36CableLength(workingVersion, sWarnings);
  } else if (specifyMeterParentModelNumber === workingVersion.UltrasonicS36Model) {
    sWarnings = testS36ProcessFluid(workingVersion, sWarnings);
    sWarnings = testS34S36Transducer(workingVersion, sWarnings);
    sWarnings = testS34S36CableLength(workingVersion, sWarnings);
  } else if (
    specifyMeterParentModelNumber === workingVersion.UltrasonicU42Model ||
    specifyMeterParentModelNumber === workingVersion.UltrasonicU43Model ||
    specifyMeterParentModelNumber === workingVersion.UltrasonicU44Model
  ) {
    sWarnings = testU42U43U44OutputAccessories(workingVersion, sWarnings);
    sWarnings = testU42U43U44TransducerTemperature(workingVersion, sWarnings);
  } else if (specifyMeterParentModelNumber === workingVersion.ElectromagneticProMModel) {
    sWarnings = testProMModel(workingVersion, sWarnings);
    sWarnings = testAccuracy(workingVersion, sWarnings);
    sWarnings = testProMPipeMaterial(workingVersion, sWarnings);
    sWarnings = testElectrodeMaterial(workingVersion, sWarnings);
    sWarnings = testLinerMaterial(workingVersion, sWarnings);
    sWarnings = testGrounding(workingVersion, sWarnings);
  } else {
    bMakeVT = testFluid();
    if (meterType === 'turbine') {
      sWarnings = testProTTemperature(workingVersion, sWarnings);
    } else {
      sWarnings = testTemperature(workingVersion, sWarnings);
    }

    // set to minimum values
    testProcessConnection(workingVersion, sWarnings);
    // now see if there are warnings
    sWarnings = testProcessConnection(workingVersion, sWarnings);

    // Some of the tests are only for a specific meter.
    if (
      specifyMeterParentModelNumber === workingVersion.InlineModel ||
      specifyMeterParentModelNumber === workingVersion.InlineReduceModel ||
      specifyMeterParentModelNumber === workingVersion.InlineNonReduceModel ||
      specifyMeterParentModelNumber === workingVersion.VorconeModel ||
      specifyMeterParentModelNumber === workingVersion.VorconeReduceModel
    ) {
      sWarnings = testSize(workingVersion, sWarnings);
    } else if (specifyMeterParentModelNumber === workingVersion.InsertionModel) {
      sWarnings = testProbeLength(workingVersion, sWarnings);
    } else {
      // turbine
    }

    sWarnings = testPressureTransducer(workingVersion, sWarnings);
    testInputPower(workingVersion);
    testOutputSignal(workingVersion);

    sWarnings = testPressure(workingVersion, bMakeVT, sWarnings);

    if (
      specifyMeterParentModelNumber === workingVersion.VorconeModel ||
      specifyMeterParentModelNumber === workingVersion.VorconeReduceModel
    ) {
      sWarnings = testXMTR(workingVersion, sWarnings);
      sWarnings = testXMTRMani(workingVersion, sWarnings);
      sWarnings = testDiagDiffPressXMTR(workingVersion, sWarnings);
      sWarnings = testDiagAddPressTop(workingVersion, sWarnings);
    }

    if (workingVersion.VORTEK_NAME === utilities.SPIRAX_NAME) {
      sWarnings = testApprovals(workingVersion, sWarnings);
      sWarnings = testFaceToFace(workingVersion, sWarnings);
    }
  }

  let i;
  const meter = meterData[meterIndex].newPipeData;
  if (meter.warningsMin && meter.warningsMin.length) {
    for (i = 0; i < meter.warningsMin.length; i++) {
      sWarnings = addWarning(sWarnings, meter.warningsMin[i]);
    }
  }

  if (meter.warningsNom && meter.warningsNom.length) {
    for (i = 0; i < meter.warningsNom.length; i++) {
      sWarnings = addWarning(sWarnings, meter.warningsNom[i]);
    }
  }

  if (meter.warningsMax && meter.warningsMax.length) {
    for (i = 0; i < meter.warningsMax.length; i++) {
      sWarnings = addWarning(sWarnings, meter.warningsMax[i]);
    }
  }

  // If the warnings string is blank, then return a no warnings statement.
  if (sWarnings === '') {
    sWarnings = 'No Warnings';
  }

  return sWarnings;
};

export default {
  updateOutputSignalCombo,
  validateAll,
};

// Think of these as table names (singluar)
// that hold multiple values.

import { Action } from 'redux';

const SET_ACCESSORIES = 'SET_ACCESSORIES';
const SET_ALERTED_NEGATIVE = 'SET_ALERTED_NEGATIVE';
const SET_APP_MODE = 'SET_APP_MODE';
const SET_APP_NAME = 'SET_APP_NAME';
const SET_CLIENT = 'SET_CLIENT';
const SET_CLIENT_PARAM = 'SET_CLIENT_PARAM';
const SET_CUSTOMER = 'SET_CUSTOMER';
const SET_DATABASE = 'SET_DATABASE';
const SET_DEFAULTS = 'SET_DEFAULTS';
const SET_WORKING_DEFAULTS = 'SET_WORKING_DEFAULTS';
const SET_DEFAULT_STANDARD_CONDITIONS = 'SET_DEFAULT_STANDARD_CONDITIONS';
const SET_DEFAULT_NORMAL_CONDITIONS = 'SET_DEFAULT_NORMAL_CONDITIONS';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const SET_FLUID = 'SET_FLUID';
const SET_FLUID_TYPE = 'SET_FLUID_TYPE';
const SET_FORCE_LOGIN = 'SET_FORCE_LOGIN';
const SET_FORCE_REMOTE = 'SET_FORCE_REMOTE';
const SET_IS_ADMIN = 'SET_IS_ADMIN';
const SET_IS_CHROME = 'SET_IS_CHROME';
const SET_IS_IE89 = 'SET_IS_IE89';
const SET_IS_IE10OR11OREDGE = 'SET_IS_IE10OR11OREDGE';
const SET_IS_WIN_SAFARI = 'SET_IS_WIN_SAFARI';
const SET_LOC = 'SET_LOC';
const SET_METER_DATA = 'SET_METER_DATA';
const SET_METER_INDEX = 'SET_METER_INDEX';
const SET_METER_TYPE = 'SET_METER_TYPE';
const SET_MODEL_CODE = 'SET_MODEL_CODE';
const SET_NORMAL_CONDITIONS = 'SET_NORMAL_CONDITIONS';
const SET_ONLINE_TOKEN = 'SET_ONLINE_TOKEN';
const SET_OTHER_GAS = 'SET_OTHER_GAS';
const SET_OTHER_LIQUID = 'SET_OTHER_LIQUID';
const SET_PATHNAME = 'SET_PATHNAME';
const SET_PIPE_INSIDE_DIAMETER = 'SET_PIPE_INSIDE_DIAMETER';
const SET_PIPE_LINER_MATERIAL = 'SET_PIPE_LINER_MATERIAL';
const SET_PIPE_MATERIAL = 'SET_PIPE_MATERIAL';
const SET_PIPE_OUTSIDE_DIAMETER = 'SET_PIPE_OUTSIDE_DIAMETER';
const SET_PIPE_REDUCE_INSIDE_DIAMETER = 'SET_PIPE_REDUCE_INSIDE_DIAMETER';
const SET_PIPE_REDUCE_OUTSIDE_DIAMETER = 'SET_PIPE_REDUCE_OUTSIDE_DIAMETER';
const SET_PIPE_SCHEDULE = 'SET_PIPE_SCHEDULE';
const SET_PIPE_TYPE = 'SET_PIPE_TYPE';
const SET_PIPE_UNIT = 'SET_PIPE_UNIT';
const SET_SELECTED_PIPE_INSIDE_DIAMETER = 'SET_SELECTED_PIPE_INSIDE_DIAMETER';
const SET_SELECTED_PIPE_OUTSIDE_DIAMETER = 'SET_SELECTED_PIPE_OUTSIDE_DIAMETER';
const SET_SOUND_SPEED = 'SET_SOUND_SPEED';
const SET_SHOW_SPECIFY_METER = 'SET_SHOW_SPECIFY_METER';
const SET_SPECIFY_METER_ACCURACY = 'SET_SPECIFY_METER_ACCURACY';
const SET_SPECIFY_METER_APPROVALS = 'SET_SPECIFY_METER_APPROVALS';
const SET_SPECIFY_METER_CABLE_LENGTH = 'SET_SPECIFY_METER_CABLE_LENGTH';
const SET_SPECIFY_METER_COMMUNICATIONS = 'SET_SPECIFY_METER_COMMUNICATIONS';
const SET_SPECIFY_METER_DIAG_DIFF_PRESS_XMTR = 'SET_SPECIFY_METER_DIAG_DIFF_PRESS_XMTR';
const SET_SPECIFY_METER_DIAG_ADD_PRESS_TAP = 'SET_SPECIFY_METER_DIAG_ADD_PRESS_TAP';
const SET_SPECIFY_METER_DIFF_PRESS_XMTR = 'SET_SPECIFY_METER_DIFF_PRESS_XMTR';
const SET_SPECIFY_METER_DIFF_PRESS_XMTR_MANI = 'SET_SPECIFY_METER_DIFF_PRESS_XMTR_MANI';
const SET_SPECIFY_METER_DISPLAY_OPTION = 'SET_SPECIFY_METER_DISPLAY_OPTION';
const SET_SPECIFY_METER_ELECTRODE_MATERIAL = 'SET_SPECIFY_METER_ELECTRODE_MATERIAL';
const SET_SPECIFY_METER_ELECTRONICS_MOUNTING = 'SET_SPECIFY_METER_ELECTRONICS_MOUNTING';
const SET_SPECIFY_METER_ENERGY_METER = 'SET_SPECIFY_METER_ENERGY_METER';
const SET_SPECIFY_METER_FACE_TO_FACE = 'SET_SPECIFY_METER_FACE_TO_FACE';
const SET_SPECIFY_METER_FLANGE = 'SET_SPECIFY_METER_FLANGE';
const SET_SPECIFY_METER_GROUNDING = 'SET_SPECIFY_METER_GROUNDING';
const SET_SPECIFY_METER_INPUT_POWER = 'SET_SPECIFY_METER_INPUT_POWER';
const SET_SPECIFY_METER_OUTPUT_SIGNAL = 'SET_SPECIFY_METER_OUTPUT_SIGNAL';
const SET_SPECIFY_METER_PARENT_MODEL_NUMBER = 'SET_SPECIFY_METER_PARENT_MODEL_NUMBER';
const SET_SPECIFY_METER_LINER_MATERIAL = 'SET_SPECIFY_METER_LINER_MATERIAL';
const SET_SPECIFY_METER_PIPE_MATERIAL = 'SET_SPECIFY_METER_PIPE_MATERIAL';
const SET_SPECIFY_METER_PIPE_SIZE = 'SET_SPECIFY_METER_PIPE_SIZE';
const SET_SPECIFY_METER_PROBE_LENGTH = 'SET_SPECIFY_METER_PROBE_LENGTH';
const SET_SPECIFY_METER_PROCESS_CONNECTION = 'SET_SPECIFY_METER_PROCESS_CONNECTION';
const SET_SPECIFY_METER_PROCESS_FLUID = 'SET_SPECIFY_METER_PROCESS_FLUID';
const SET_SPECIFY_METER_PROCESS_TEMPERATURE = 'SET_SPECIFY_METER_PROCESS_TEMPERATURE';
const SET_SPECIFY_METER_ROTOR = 'SET_SPECIFY_METER_ROTOR';
const SET_SPECIFY_METER_TRANSDUCER = 'SET_SPECIFY_METER_TRANSDUCER';
const SET_SPECIFY_METER_TAG_NUMBER = 'SET_SPECIFY_METER_TAG_NUMBER';
const SET_SPECIFY_METER_TEMP_PRESS_COMP = 'SET_SPECIFY_METER_TEMP_PRESS_COMP';
const SET_SPECIFY_METER_TRANSDUCER_BRACKET = 'SET_SPECIFY_METER_TRANSDUCER_BRACKET';
const SET_SPECIFY_METER_SPECIAL_NOTES = 'SET_SPECIFY_METER_SPECIAL_NOTES';
const SET_SPECIFY_METER_WARNINGS = 'SET_SPECIFY_METER_WARNINGS';
const SET_STANDARD_CONDITIONS = 'SET_STANDARD_CONDITIONS';
const SET_SYSTEM_MESSAGE = 'SET_SYSTEM_MESSAGE';
const SET_REDUCING_VORCONE = 'SET_REDUCING_VORCONE';
const SET_REDUCING_VORTEX = 'SET_REDUCING_VORTEX';
const SET_REMOTE_CABLE_LENGTH = 'SET_REMOTE_CABLE_LENGTH';
const SET_TURBINE_ACTIVE = 'SET_TURBINE_ACTIVE';
const SET_USERNAME = 'SET_USERNAME';
const SET_VORCONE_ACTIVE = 'SET_VORCONE_ACTIVE';
const SET_WARNING_MESSAGE = 'SET_WARNING_MESSAGE';

// Process Conditions
const SET_PROCESS_CONDITIONS = 'SET_PROCESS_CONDITIONS';
const SET_FLOW_MIN = 'SET_FLOW_MIN';
const SET_FLOW_NOM = 'SET_FLOW_NOM';
const SET_FLOW_MAX = 'SET_FLOW_MAX';
const SET_FLOW_UNIT = 'SET_FLOW_UNIT';
const SET_FLOW_TIME = 'SET_FLOW_TIME';
const SET_TEMPERATURE_MIN = 'SET_TEMPERATURE_MIN';
const SET_TEMPERATURE_NOM = 'SET_TEMPERATURE_NOM';
const SET_TEMPERATURE_MAX = 'SET_TEMPERATURE_MAX';
const SET_TEMPERATURE_UNIT = 'SET_TEMPERATURE_UNIT';
const SET_PRESSURE_MIN = 'SET_PRESSURE_MIN';
const SET_PRESSURE_NOM = 'SET_PRESSURE_NOM';
const SET_PRESSURE_MAX = 'SET_PRESSURE_MAX';
const SET_PRESSURE_PREFIX = 'SET_PRESSURE_PREFIX';
const SET_PRESSURE_COEFFICIENT = 'SET_PRESSURE_COEFFICIENT';
const SET_DENSITY_MIN = 'SET_DENSITY_MIN';
const SET_DENSITY_NOM = 'SET_DENSITY_NOM';
const SET_DENSITY_MAX = 'SET_DENSITY_MAX';
const SET_DENSITY_UNIT = 'SET_DENSITY_UNIT';
const SET_VISCOSITY_MIN = 'SET_VISCOSITY_MIN';
const SET_VISCOSITY_NOM = 'SET_VISCOSITY_NOM';
const SET_VISCOSITY_MAX = 'SET_VISCOSITY_MAX';
const SET_VISCOSITY_UNIT = 'SET_VISCOSITY_UNIT';
const SET_VELOCITY_MIN = 'SET_VELOCITY_MIN';
const SET_VELOCITY_NOM = 'SET_VELOCITY_NOM';
const SET_VELOCITY_MAX = 'SET_VELOCITY_MAX';
const SET_VELOCITY_UNIT = 'SET_VELOCITY_UNIT';

// Database tables
const SET_TABLE_ANSI_PIPE_SIZE = 'SET_TABLE_ANSI_PIPE_SIZE';
const SET_TABLE_APPLICATION = 'SET_TABLE_APPLICATION';
const SET_TABLE_CUSTOMER = 'SET_TABLE_CUSTOMER';
const SET_TABLE_DEFAULT = 'SET_TABLE_DEFAULT';
const SET_TABLE_DENSITY = 'SET_TABLE_DENSITY';
const SET_TABLE_DIN_PIPE_SIZE = 'SET_TABLE_DIN_PIPE_SIZE';
const SET_TABLE_FEATURE = 'SET_TABLE_FEATURE';
const SET_TABLE_FLANGE_TEST_ASME = 'SET_TABLE_FLANGE_TEST_ASME';
const SET_TABLE_FLANGE_TEST_DIN = 'SET_TABLE_FLANGE_TEST_DIN';
const SET_TABLE_FLANGE_TEST_JIS = 'SET_TABLE_FLANGE_TEST_JIS';
const SET_TABLE_FLOW = 'SET_TABLE_FLOW';
const SET_TABLE_FLOW_WORKING = 'SET_TABLE_FLOW_WORKING';
const SET_TABLE_DENSITY_WORKING = 'SET_TABLE_DENSITY_WORKING';
const SET_TABLE_VISCOSITY_WORKING = 'SET_TABLE_VISCOSITY_WORKING';
const SET_TABLE_LENGTH_WORKING = 'SET_TABLE_LENGTH_WORKING';
const SET_TABLE_TIME_WORKING = 'SET_TABLE_TIME_WORKING';
const SET_TABLE_TEMPERATURE_WORKING = 'SET_TABLE_TEMPERATURE_WORKING';
const SET_TABLE_PRESSURE_WORKING = 'SET_TABLE_PRESSURE_WORKING';
const SET_TABLE_FLUID = 'SET_TABLE_FLUID';
const SET_TABLE_GAS = 'SET_TABLE_GAS';
const SET_TABLE_IMAGE = 'SET_TABLE_IMAGE';
const SET_TABLE_INLINE = 'SET_TABLE_INLINE';
const SET_TABLE_INLINE_PRO_M = 'SET_TABLE_INLINE_PRO_M';
const SET_TABLE_JIS_PIPE_SCHEDULE = 'SET_TABLE_JIS_PIPE_SCHEDULE';
const SET_TABLE_JIS_PIPE_SIZE = 'SET_TABLE_JIS_PIPE_SIZE';
const SET_TABLE_LENGTH = 'SET_TABLE_LENGTH';
const SET_TABLE_LINER_MATERIAL = 'SET_TABLE_LINER_MATERIAL';
const SET_TABLE_LIQUID = 'SET_TABLE_LIQUID';
const SET_TABLE_M22_FEATURE = 'SET_TABLE_M22_FEATURE';
const SET_TABLE_M23_FEATURE = 'SET_TABLE_M23_FEATURE';
const SET_TABLE_M24_FEATURE = 'SET_TABLE_M24_FEATURE';
const SET_TABLE_M24R_FEATURE = 'SET_TABLE_M24R_FEATURE';
const SET_TABLE_MVC_FEATURE = 'SET_TABLE_MVC_FEATURE';
const SET_TABLE_MVCR_FEATURE = 'SET_TABLE_MVCR_FEATURE';
const SET_TABLE_OTHER_GAS = 'SET_TABLE_OTHER_GAS';
const SET_TABLE_OTHER_LIQUID = 'SET_TABLE_OTHER_LIQUID';
const SET_TABLE_PIPE_MATERIAL = 'SET_TABLE_PIPE_MATERIAL';
const SET_TABLE_PIPE_SCHEDULE = 'SET_TABLE_PIPE_SCHEDULE';
const SET_TABLE_PIPE_DIN = 'SET_TABLE_PIPE_DIN';
const SET_TABLE_PRESSURE = 'SET_TABLE_PRESSURE';
const SET_TABLE_PRINT_HEADER = 'SET_TABLE_PRINT_HEADER';
const SET_TABLE_S34_FEATURE = 'SET_TABLE_S34_FEATURE';
const SET_TABLE_S36_FEATURE = 'SET_TABLE_S36_FEATURE';
const SET_TABLE_SOUND_SPEED = 'SET_TABLE_SOUND_SPEED';
const SET_TABLE_TEMPERATURE = 'SET_TABLE_TEMPERATURE';
const SET_TABLE_TIME = 'SET_TABLE_TIME';
const SET_TABLE_TURBINE_FEATURE = 'SET_TABLE_TURBINE_FEATURE';
const SET_TABLE_U42_FEATURE = 'SET_TABLE_U42_FEATURE';
const SET_TABLE_U43_FEATURE = 'SET_TABLE_U43_FEATURE';
const SET_TABLE_U44_FEATURE = 'SET_TABLE_U44_FEATURE';
const SET_TABLE_PROM_FEATURE = 'SET_TABLE_PROM_FEATURE';
const SET_TABLE_VISCOSITY = 'SET_TABLE_VISCOSITY';
const SET_TABLE_VUSER = 'SET_TABLE_VUSER';

export interface ISET_FORCE_REMOTE extends Action<'SET_FORCE_REMOTE'> {
  type: 'SET_FORCE_REMOTE';
  data: boolean;
}

export interface ISET_TABLE_ANSI_PIPE_SIZE extends Action<'SET_TABLE_ANSI_PIPE_SIZE'> {
  type: 'SET_TABLE_ANSI_PIPE_SIZE';
  data: JSON;
}

export interface ISET_TABLE_APPLICATION extends Action<'SET_TABLE_APPLICATION'> {
  type: 'SET_TABLE_APPLICATION';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_CUSTOMER extends Action<'SET_TABLE_CUSTOMER'> {
  type: 'SET_TABLE_CUSTOMER';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_DEFAULT extends Action<'SET_TABLE_DEFAULT'> {
  type: 'SET_TABLE_DEFAULT';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_DENSITY extends Action<'SET_TABLE_DENSITY'> {
  type: 'SET_TABLE_DENSITY';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_DIN_PIPE_SIZE extends Action<'SET_TABLE_DIN_PIPE_SIZE'> {
  type: 'SET_TABLE_DIN_PIPE_SIZE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_FEATURE extends Action<'SET_TABLE_FEATURE'> {
  type: 'SET_TABLE_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_FLANGE_TEST_ASME extends Action<'SET_TABLE_FLANGE_TEST_ASME'> {
  type: 'SET_TABLE_FLANGE_TEST_ASME';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_FLANGE_TEST_DIN extends Action<'SET_TABLE_FLANGE_TEST_DIN'> {
  type: 'SET_TABLE_FLANGE_TEST_DIN';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_FLANGE_TEST_JIS extends Action<'SET_TABLE_FLANGE_TEST_JIS'> {
  type: 'SET_TABLE_FLANGE_TEST_JIS';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_FLOW extends Action<'SET_TABLE_FLOW'> {
  type: 'SET_TABLE_FLOW';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_FLUID extends Action<'SET_TABLE_FLUID'> {
  type: 'SET_TABLE_FLUID';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_GAS extends Action<'SET_TABLE_GAS'> {
  type: 'SET_TABLE_GAS';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_IMAGE extends Action<'SET_TABLE_IMAGE'> {
  type: 'SET_TABLE_IMAGE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_INLINE extends Action<'SET_TABLE_INLINE'> {
  type: 'SET_TABLE_INLINE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_JIS_PIPE_SCHEDULE extends Action<'SET_TABLE_JIS_PIPE_SCHEDULE'> {
  type: 'SET_TABLE_JIS_PIPE_SCHEDULE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_JIS_PIPE_SIZE extends Action<'SET_TABLE_JIS_PIPE_SIZE'> {
  type: 'SET_TABLE_JIS_PIPE_SIZE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_LENGTH extends Action<'SET_TABLE_LENGTH'> {
  type: 'SET_TABLE_LENGTH';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_LINER_MATERIAL extends Action<'SET_TABLE_LINER_MATERIAL'> {
  type: 'SET_TABLE_LINER_MATERIAL';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_LIQUID extends Action<'SET_TABLE_LIQUID'> {
  type: 'SET_TABLE_LIQUID';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_M22_FEATURE extends Action<'SET_TABLE_M22_FEATURE'> {
  type: 'SET_TABLE_M22_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_M23_FEATURE extends Action<'SET_TABLE_M23_FEATURE'> {
  type: 'SET_TABLE_M23_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_M24_FEATURE extends Action<'SET_TABLE_M24_FEATURE'> {
  type: 'SET_TABLE_M24_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_M24R_FEATURE extends Action<'SET_TABLE_M24R_FEATURE'> {
  type: 'SET_TABLE_M24R_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_MVC_FEATURE extends Action<'SET_TABLE_MVC_FEATURE'> {
  type: 'SET_TABLE_MVC_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_MVCR_FEATURE extends Action<'SET_TABLE_MVCR_FEATURE'> {
  type: 'SET_TABLE_MVCR_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_OTHER_GAS extends Action<'SET_TABLE_OTHER_GAS'> {
  type: 'SET_TABLE_OTHER_GAS';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_OTHER_LIQUID extends Action<'SET_TABLE_OTHER_LIQUID'> {
  type: 'SET_TABLE_OTHER_LIQUID';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_PIPE_MATERIAL extends Action<'SET_TABLE_PIPE_MATERIAL'> {
  type: 'SET_TABLE_PIPE_MATERIAL';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_PIPE_SCHEDULE extends Action<'SET_TABLE_PIPE_SCHEDULE'> {
  type: 'SET_TABLE_PIPE_SCHEDULE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_PIPE_DIN extends Action<'SET_TABLE_PIPE_DIN'> {
  type: 'SET_TABLE_PIPE_DIN';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_PRESSURE extends Action<'SET_TABLE_PRESSURE'> {
  type: 'SET_TABLE_PRESSURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_PRINT_HEADER extends Action<'SET_TABLE_PRINT_HEADER'> {
  type: 'SET_TABLE_PRINT_HEADER';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_S34_FEATURE extends Action<'SET_TABLE_S34_FEATURE'> {
  type: 'SET_TABLE_S34_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_S36_FEATURE extends Action<'SET_TABLE_S36_FEATURE'> {
  type: 'SET_TABLE_S36_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_SOUND_SPEED extends Action<'SET_TABLE_SOUND_SPEED'> {
  type: 'SET_TABLE_SOUND_SPEED';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_TEMPERATURE extends Action<'SET_TABLE_TEMPERATURE'> {
  type: 'SET_TABLE_TEMPERATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_TIME extends Action<'SET_TABLE_TIME'> {
  type: 'SET_TABLE_TIME';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_TURBINE_FEATURE extends Action<'SET_TABLE_TURBINE_FEATURE'> {
  type: 'SET_TABLE_TURBINE_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_U42_FEATURE extends Action<'SET_TABLE_U42_FEATURE'> {
  type: 'SET_TABLE_U42_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_U43_FEATURE extends Action<'SET_TABLE_U43_FEATURE'> {
  type: 'SET_TABLE_U43_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_U44_FEATURE extends Action<'SET_TABLE_U44_FEATURE'> {
  type: 'SET_TABLE_U44_FEATURE';
  data: JSON;
  id?: string;
}
export interface ISET_TABLE_PROM_FEATURE extends Action<'SET_TABLE_PROM_FEATURE'> {
  type: 'SET_TABLE_PROM_FEATURE';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_VISCOSITY extends Action<'SET_TABLE_VISCOSITY'> {
  type: 'SET_TABLE_VISCOSITY';
  data: JSON;
  id?: string;
}

export interface ISET_TABLE_VUSER extends Action<'SET_TABLE_VUSER'> {
  type: 'SET_TABLE_VUSER';
  data: JSON;
  id?: string;
}

export type DbActions =
  | ISET_FORCE_REMOTE
  | ISET_TABLE_ANSI_PIPE_SIZE
  | ISET_TABLE_APPLICATION
  | ISET_TABLE_CUSTOMER
  | ISET_TABLE_DEFAULT
  | ISET_TABLE_DENSITY
  | ISET_TABLE_DIN_PIPE_SIZE
  | ISET_TABLE_FEATURE
  | ISET_TABLE_FLANGE_TEST_ASME
  | ISET_TABLE_FLANGE_TEST_DIN
  | ISET_TABLE_FLANGE_TEST_JIS
  | ISET_TABLE_FLOW
  | ISET_TABLE_FLUID
  | ISET_TABLE_GAS
  | ISET_TABLE_IMAGE
  | ISET_TABLE_INLINE
  | ISET_TABLE_JIS_PIPE_SCHEDULE
  | ISET_TABLE_JIS_PIPE_SIZE
  | ISET_TABLE_LENGTH
  | ISET_TABLE_LINER_MATERIAL
  | ISET_TABLE_LIQUID
  | ISET_TABLE_M22_FEATURE
  | ISET_TABLE_M23_FEATURE
  | ISET_TABLE_M24_FEATURE
  | ISET_TABLE_M24R_FEATURE
  | ISET_TABLE_MVC_FEATURE
  | ISET_TABLE_MVCR_FEATURE
  | ISET_TABLE_OTHER_GAS
  | ISET_TABLE_OTHER_LIQUID
  | ISET_TABLE_PIPE_MATERIAL
  | ISET_TABLE_PIPE_SCHEDULE
  | ISET_TABLE_PIPE_DIN
  | ISET_TABLE_PRESSURE
  | ISET_TABLE_PRINT_HEADER
  | ISET_TABLE_S34_FEATURE
  | ISET_TABLE_S36_FEATURE
  | ISET_TABLE_SOUND_SPEED
  | ISET_TABLE_TEMPERATURE
  | ISET_TABLE_TIME
  | ISET_TABLE_TURBINE_FEATURE
  | ISET_TABLE_U42_FEATURE
  | ISET_TABLE_U43_FEATURE
  | ISET_TABLE_U44_FEATURE
  | ISET_TABLE_PROM_FEATURE
  | ISET_TABLE_VISCOSITY
  | ISET_TABLE_VUSER;

export default {
  SET_ACCESSORIES,
  SET_ALERTED_NEGATIVE,
  SET_APP_MODE,
  SET_APP_NAME,
  SET_CLIENT,
  SET_CLIENT_PARAM,
  SET_CUSTOMER,
  SET_DATABASE,
  SET_DEFAULTS,
  SET_WORKING_DEFAULTS,
  SET_DEFAULT_NORMAL_CONDITIONS,
  SET_DEFAULT_STANDARD_CONDITIONS,
  SET_ERROR_MESSAGE,
  SET_FLUID,
  SET_FLUID_TYPE,
  SET_FORCE_LOGIN,
  SET_FORCE_REMOTE,
  SET_IS_ADMIN,
  SET_IS_CHROME,
  SET_IS_IE89,
  SET_IS_IE10OR11OREDGE,
  SET_IS_WIN_SAFARI,
  SET_LOC,
  SET_METER_DATA,
  SET_METER_INDEX,
  SET_METER_TYPE,
  SET_MODEL_CODE,
  SET_NORMAL_CONDITIONS,
  SET_ONLINE_TOKEN,
  SET_OTHER_GAS,
  SET_OTHER_LIQUID,
  SET_PATHNAME,
  SET_PIPE_INSIDE_DIAMETER,
  SET_PIPE_LINER_MATERIAL,
  SET_PIPE_MATERIAL,
  SET_PIPE_OUTSIDE_DIAMETER,
  SET_PIPE_REDUCE_INSIDE_DIAMETER,
  SET_PIPE_REDUCE_OUTSIDE_DIAMETER,
  SET_PIPE_SCHEDULE,
  SET_PIPE_TYPE,
  SET_PIPE_UNIT,
  SET_STANDARD_CONDITIONS,
  SET_REDUCING_VORCONE,
  SET_REDUCING_VORTEX,
  SET_REMOTE_CABLE_LENGTH,
  SET_SELECTED_PIPE_INSIDE_DIAMETER,
  SET_SELECTED_PIPE_OUTSIDE_DIAMETER,
  SET_SOUND_SPEED,
  SET_SHOW_SPECIFY_METER,
  SET_SPECIFY_METER_ACCURACY,
  SET_SPECIFY_METER_ELECTRODE_MATERIAL,
  SET_SPECIFY_METER_TAG_NUMBER,
  SET_SPECIFY_METER_PARENT_MODEL_NUMBER,
  SET_SPECIFY_METER_PROCESS_FLUID,
  SET_SPECIFY_METER_PIPE_SIZE,
  SET_SPECIFY_METER_LINER_MATERIAL,
  SET_SPECIFY_METER_PIPE_MATERIAL,
  SET_SPECIFY_METER_FLANGE,
  SET_SPECIFY_METER_GROUNDING,
  SET_SPECIFY_METER_PROBE_LENGTH,
  SET_SPECIFY_METER_ELECTRONICS_MOUNTING,
  SET_SPECIFY_METER_DISPLAY_OPTION,
  SET_SPECIFY_METER_INPUT_POWER,
  SET_SPECIFY_METER_OUTPUT_SIGNAL,
  SET_SPECIFY_METER_PROCESS_TEMPERATURE,
  SET_SPECIFY_METER_TRANSDUCER,
  SET_SPECIFY_METER_PROCESS_CONNECTION,
  SET_SPECIFY_METER_ROTOR,
  SET_SPECIFY_METER_DIFF_PRESS_XMTR,
  SET_SPECIFY_METER_DIFF_PRESS_XMTR_MANI,
  SET_SPECIFY_METER_DIAG_DIFF_PRESS_XMTR,
  SET_SPECIFY_METER_DIAG_ADD_PRESS_TAP,
  SET_SPECIFY_METER_CABLE_LENGTH,
  SET_SPECIFY_METER_COMMUNICATIONS,
  SET_SPECIFY_METER_TRANSDUCER_BRACKET,
  SET_SPECIFY_METER_TEMP_PRESS_COMP,
  SET_SPECIFY_METER_ENERGY_METER,
  SET_SPECIFY_METER_APPROVALS,
  SET_SPECIFY_METER_FACE_TO_FACE,
  SET_SPECIFY_METER_SPECIAL_NOTES,
  SET_SPECIFY_METER_WARNINGS,
  SET_SYSTEM_MESSAGE,
  SET_TURBINE_ACTIVE,
  SET_USERNAME,
  SET_VORCONE_ACTIVE,
  SET_WARNING_MESSAGE,

  SET_PROCESS_CONDITIONS,
  SET_FLOW_MIN,
  SET_FLOW_NOM,
  SET_FLOW_MAX,
  SET_FLOW_UNIT,
  SET_FLOW_TIME,
  SET_TEMPERATURE_MIN,
  SET_TEMPERATURE_NOM,
  SET_TEMPERATURE_MAX,
  SET_TEMPERATURE_UNIT,
  SET_PRESSURE_MIN,
  SET_PRESSURE_NOM,
  SET_PRESSURE_MAX,
  SET_PRESSURE_PREFIX,
  SET_PRESSURE_COEFFICIENT,
  SET_DENSITY_MIN,
  SET_DENSITY_NOM,
  SET_DENSITY_MAX,
  SET_DENSITY_UNIT,
  SET_VISCOSITY_MIN,
  SET_VISCOSITY_NOM,
  SET_VISCOSITY_MAX,
  SET_VISCOSITY_UNIT,
  SET_VELOCITY_MIN,
  SET_VELOCITY_NOM,
  SET_VELOCITY_MAX,
  SET_VELOCITY_UNIT,

  SET_TABLE_ANSI_PIPE_SIZE,
  SET_TABLE_APPLICATION,
  SET_TABLE_CUSTOMER,
  SET_TABLE_DEFAULT,
  SET_TABLE_DENSITY,
  SET_TABLE_DIN_PIPE_SIZE,
  SET_TABLE_FEATURE,
  SET_TABLE_FLANGE_TEST_ASME,
  SET_TABLE_FLANGE_TEST_DIN,
  SET_TABLE_FLANGE_TEST_JIS,
  SET_TABLE_FLOW,
  SET_TABLE_FLOW_WORKING,
  SET_TABLE_DENSITY_WORKING,
  SET_TABLE_VISCOSITY_WORKING,
  SET_TABLE_LENGTH_WORKING,
  SET_TABLE_TIME_WORKING,
  SET_TABLE_TEMPERATURE_WORKING,
  SET_TABLE_PRESSURE_WORKING,
  SET_TABLE_FLUID,
  SET_TABLE_GAS,
  SET_TABLE_IMAGE,
  SET_TABLE_INLINE,
  SET_TABLE_INLINE_PRO_M,
  SET_TABLE_JIS_PIPE_SCHEDULE,
  SET_TABLE_JIS_PIPE_SIZE,
  SET_TABLE_LENGTH,
  SET_TABLE_LINER_MATERIAL,
  SET_TABLE_LIQUID,
  SET_TABLE_M22_FEATURE,
  SET_TABLE_M23_FEATURE,
  SET_TABLE_M24_FEATURE,
  SET_TABLE_M24R_FEATURE,
  SET_TABLE_MVC_FEATURE,
  SET_TABLE_MVCR_FEATURE,
  SET_TABLE_OTHER_GAS,
  SET_TABLE_OTHER_LIQUID,
  SET_TABLE_PIPE_MATERIAL,
  SET_TABLE_PIPE_SCHEDULE,
  SET_TABLE_PIPE_DIN,
  SET_TABLE_PRESSURE,
  SET_TABLE_PRINT_HEADER,
  SET_TABLE_S34_FEATURE,
  SET_TABLE_S36_FEATURE,
  SET_TABLE_SOUND_SPEED,
  SET_TABLE_TEMPERATURE,
  SET_TABLE_TIME,
  SET_TABLE_TURBINE_FEATURE,
  SET_TABLE_U42_FEATURE,
  SET_TABLE_U43_FEATURE,
  SET_TABLE_U44_FEATURE,
  SET_TABLE_PROM_FEATURE,
  SET_TABLE_VISCOSITY,
  SET_TABLE_VUSER,
};

import Decimal from 'decimal.js';
import utilities from '../../utilities';
import ArmstrongSizingGlobals from './armstrongDefaults';
import AzbilSizingGlobals from './azbilDefaults';
import GestraSizingGlobals from './gestraDefaults';
import HeinrichSizingGlobals from './heinrichsDefaults';
import InnovaSizingGlobals from './innovaDefaults';
import OniconSizingGlobals from './oniconDefaults';
import PanaflowSizingGlobals from './panaflowDefaults';
import SpiraxSizingGlobals from './spiraxDefaults';
import ProvSizingGlobals from './provDefaults';
import WatsonMcDanielSizingGlobals from './wmDefaults';

import SizingGlobals from '../../types/sizingGlobals';
import VortekDefaults from '../../types/vortekDefaults';
import DbRow from '../../types/dbRow';
import OtherGasRow from '../../types/OtherGasRow';
import OtherLiquidRow from '../../types/OtherLiquidRow';
import PipeData from '../../types/PipeData';
import constants from '../../constants';
import ProcessCondition from '../../types/processCondition';
import Meter from '../../types/Meter';
import WorkingVortekDefaults from '../../types/workingVortekDefaults';
import Accessory from '../../types/accessory';
import CustomerListItem from '../../types/customerListItem';

// Redux initial state. Here is where all data
// in Redux is defined.
export interface InitialState {
  accessories: Accessory[];
  alertedNegative: boolean;
  appMode: 'meter_conditions' | 'sizing';
  appName: string;
  client: string;
  clientParam: string;
  customer: CustomerListItem;
  database: IDBDatabase | undefined;
  defaults: VortekDefaults;
  errorMessage: string;
  workingDefaults: WorkingVortekDefaults;
  fluid: string;
  fluidType: string;
  forceLogin: boolean;
  forceRemote: boolean;
  isAdmin: boolean;
  isChrome: boolean;
  isIE89: boolean;
  isIE10or11orEdge: boolean;
  isWinSafari: boolean;
  loc: Location;
  meterData: Meter[];
  meterIndex: number;
  meterType:
    | ''
    | 'vortex'
    | 'nonreduce_vortex'
    | 'reduce_vortex'
    | 'turbine'
    | 'vorcone'
    | 'reduce_vorcone'
    | 'S34'
    | 'S36'
    | 'U42'
    | 'U43'
    | 'U44'
    | 'pro_m';
  modelCode: string;
  onlineToken: string;
  otherGas: OtherGasRow;
  otherLiquid: OtherLiquidRow;
  pathName: string;
  pipeInsideDiameter: string;
  pipeLinerMaterial: string;
  pipeMaterial: string;
  pipeOutsideDiameter: string;
  pipeReduceInsideDiameter: string;
  pipeReduceOutsideDiameter: string;
  pipeSchedule: string;
  pipeType: '' | 'ansi' | 'din' | 'jis' | 'other';
  pipeUnit: string;
  processConditions: PipeData;
  provSizingGlobals: SizingGlobals;
  reducingVorcone: boolean;
  reducingVortex: boolean;
  remoteCableLength: string;
  selectedPipeInsideDiameter: string;
  selectedPipeOutsideDiameter: string;
  sizingGlobals: SizingGlobals;
  soundSpeed: string;
  showSpecifyMeter: boolean;
  specifyMeterAccuracy: string;
  specifyMeterElectrodeMaterial: string;
  specifyMeterGrounding: string;
  specifyMeterTagNumber: string;
  specifyMeterParentModelNumber: string;
  specifyMeterProcessFluid: string;
  specifyMeterPipeSize: string;
  specifyMeterLinerMaterial: string;
  specifyMeterPipeMaterial: string;
  specifyMeterFlange: string;
  specifyMeterProbeLength: string;
  specifyMeterElectronicsMounting: string;
  specifyMeterDisplayOption: string;
  specifyMeterInputPower: string;
  specifyMeterOutputSignal: string;
  specifyMeterProcessTemperature: string;
  specifyMeterTransducer: string;
  specifyMeterProcessConnection: string;
  specifyMeterRotor: string;
  specifyMeterDiffPressXMTR: string;
  specifyMeterDiffPressXMTRMani: string;
  specifyMeterDiagDiffPressXMTR: string;
  specifyMeterDiagAddPressTap: string;
  specifyMeterCableLength: string;
  specifyMeterCommunications: string;
  specifyMeterTransducerBracket: string;
  specifyMeterTempPressComp: string;
  specifyMeterEnergyMeter: string;
  specifyMeterApprovals: string;
  flowTableWorking: string;
  densityTableWorking: string;
  viscosityTableWorking: string;
  lengthTableWorking: string;
  timeTableWorking: string;
  temperatureTableWorking: string;
  pressureTableWorking: string;
  specifyMeterFaceToFace: string;
  specifyMeterSpecialNotes: string;
  specifyMeterWarnings: string;
  systemMessage: string;
  turbineActive: boolean;
  username: string;
  vorconeActive: boolean;
  warningMessage: string;
  db: {
    ansiPipeSizes: DbRow[];
    application: DbRow[];
    customer: DbRow[];
    defaults: DbRow[];
    density: DbRow[];
    dinPipeSizes: DbRow[];
    feature: DbRow[];
    flangeTestsAsme: DbRow[];
    flangeTestsDin: DbRow[];
    flangeTestsJis: DbRow[];
    flow: DbRow[];
    fluid: DbRow[];
    gas: DbRow[];
    image: DbRow[];
    inlines: DbRow[];
    inlinesProM: DbRow[];
    jisPipeSchedules: DbRow[];
    jisPipeSizes: DbRow[];
    length: DbRow[];
    linerMaterial: DbRow[];
    liquid: DbRow[];
    m22Feature: DbRow[];
    m23Feature: DbRow[];
    m24Feature: DbRow[];
    m24rFeature: DbRow[];
    mvcFeature: DbRow[];
    mvcrFeature: DbRow[];
    othergas: DbRow[];
    otherliquid: DbRow[];
    pipesdin: DbRow[];
    pipeMaterial: DbRow[];
    pipeSchedules: DbRow[];
    pressure: DbRow[];
    printheader: DbRow[];
    s34Feature: DbRow[];
    s36Feature: DbRow[];
    soundspeed: DbRow[];
    temperature: DbRow[];
    time: DbRow[];
    turbineFeature: DbRow[];
    u42Feature: DbRow[];
    u43Feature: DbRow[];
    u44Feature: DbRow[];
    proMFeature: DbRow[];
    viscosity: DbRow[];
    vusers: DbRow[];
  };
}

// Find out if this is Safari for Windows. If so, we don't support it.
const ua = navigator.userAgent.toLowerCase();
let isWinSafari = false;
if (
  ua.indexOf('safari/') !== -1 && // It says it's Safari
  ua.indexOf('windows') !== -1 && // It says it's on Windows
  ua.indexOf('chrom') === -1 // It DOESN'T say it's Chrome/Chromium
) {
  isWinSafari = true;
}

// Figure out the path name based on how we were loaded in the browser
const loc = window?.location;
const pathName = window?.location?.pathname?.substring(0, window.location.pathname.lastIndexOf('/') + 1);

const clientParam = utilities.clientUrlParam();
let sizingGlobals;
switch (clientParam) {
  case utilities.ARMSTRONG_NAME:
    sizingGlobals = ArmstrongSizingGlobals;
    break;

  case utilities.AZBIL_NAME:
    sizingGlobals = AzbilSizingGlobals;
    break;

  case utilities.GESTRA_NAME:
    sizingGlobals = GestraSizingGlobals;
    break;

  case utilities.HEINRICH_NAME:
    sizingGlobals = HeinrichSizingGlobals;
    break;

  case utilities.INNOVA_NAME:
    sizingGlobals = InnovaSizingGlobals;
    break;

  case utilities.ONICON_NAME:
    sizingGlobals = OniconSizingGlobals;
    break;

  case utilities.PANAFLOW_NAME:
    sizingGlobals = PanaflowSizingGlobals;
    break;

  case utilities.SPIRAX_NAME:
    sizingGlobals = SpiraxSizingGlobals;
    break;

  case utilities.WATSON_MCDANIEL_NAME:
    sizingGlobals = WatsonMcDanielSizingGlobals;
    break;

  default:
    sizingGlobals = ProvSizingGlobals;
    break;
}

const initialState: InitialState = {
  accessories: [],
  alertedNegative: false,
  appMode: 'meter_conditions',
  appName: '',
  client: '',
  clientParam,
  customer: new CustomerListItem(),
  database: undefined,
  errorMessage: '',
  fluid: '',
  fluidType: '',
  forceLogin: false,
  forceRemote: false,
  isAdmin: false,
  isChrome: typeof chrome !== 'undefined',
  isIE89: typeof document.documentMode !== 'undefined',
  isIE10or11orEdge: typeof MSBlobBuilder !== 'undefined',
  isWinSafari,
  loc,
  meterData: [],
  meterIndex: -1,
  meterType: '',
  modelCode: '',
  onlineToken: navigator.onLine ? 'online' : 'offline',
  otherGas: {
    gas_name: '',
    gas_compressibility: '',
    gas_gravity: '',
    gas_viscosity: '',
    gas_select: '',
  },
  otherLiquid: {
    liquid_name: '',
    liquid_density: '',
    liquid_viscosity: '',
    liquid_sound_speed: '',
    liquid_select_1: '',
    liquid_select_2: '',
    liquid_select_3: '',
  },
  pathName,
  pipeInsideDiameter: '',
  pipeLinerMaterial: '',
  pipeMaterial: '',
  pipeOutsideDiameter: '0.5 in',
  pipeReduceInsideDiameter: '',
  pipeReduceOutsideDiameter: '',
  pipeSchedule: '',
  pipeType: '',
  pipeUnit: '',
  processConditions: constants.defaultPipeData,
  provSizingGlobals: ProvSizingGlobals,
  reducingVorcone: false,
  reducingVortex: false,
  remoteCableLength: '',
  selectedPipeInsideDiameter: '',
  selectedPipeOutsideDiameter: '',
  sizingGlobals,
  soundSpeed: '',
  showSpecifyMeter: false,
  specifyMeterAccuracy: '',
  specifyMeterElectrodeMaterial: '',
  specifyMeterGrounding: '',
  specifyMeterTagNumber: '',
  specifyMeterParentModelNumber: '',
  specifyMeterProcessFluid: '',
  specifyMeterPipeSize: '',
  specifyMeterLinerMaterial: '',
  specifyMeterPipeMaterial: '',
  specifyMeterFlange: '',
  specifyMeterProbeLength: '',
  specifyMeterElectronicsMounting: '',
  specifyMeterDisplayOption: '',
  specifyMeterInputPower: '',
  specifyMeterOutputSignal: '',
  specifyMeterProcessTemperature: '',
  specifyMeterTransducer: '',
  specifyMeterProcessConnection: '',
  specifyMeterRotor: '',
  specifyMeterDiffPressXMTR: '',
  specifyMeterDiffPressXMTRMani: '',
  specifyMeterDiagDiffPressXMTR: '',
  specifyMeterDiagAddPressTap: '',
  specifyMeterCableLength: '',
  specifyMeterCommunications: '',
  specifyMeterTransducerBracket: '',
  specifyMeterTempPressComp: '',
  specifyMeterEnergyMeter: '',
  specifyMeterApprovals: '',
  specifyMeterFaceToFace: '',
  specifyMeterSpecialNotes: '',
  specifyMeterWarnings: '',
  systemMessage: '',
  turbineActive: false,
  username: '',
  vorconeActive: false,
  flowTableWorking: '',
  densityTableWorking: '',
  viscosityTableWorking: '',
  lengthTableWorking: '',
  timeTableWorking: '',
  temperatureTableWorking: '',
  pressureTableWorking: '',
  warningMessage: '',
  defaults: {
    defaultFormats: {
      flowRateFormat: '#,###,##0.00##',
      lengthFormat: '#,##0.00',
      massFormat: '#,###,##0.00##',
      pressureFormat: '#,###,##0.00##',
      temperatureFormat: '#,###,##0.####',
      volumeFormat: '0.00##',
      areaFormat: '0.00##',
      densityFormat: '0.00##',
      velocityFormat: '0.00##',
      viscosityFormat: '0.00##',
      unitlessFormat: '0.00##',
      generalNumberFormat: '0#',
      fixedFormat: '0.00##',
      standardFormat: '#,##0.00',
    },
    defaultProcessConditions: {
      defaultStdConditions: new ProcessCondition(),
      defaultNmlConditions: new ProcessCondition(),
      standardConditions: new ProcessCondition(),
      normalConditions: new ProcessCondition(),
    },
    defaultValues: {
      baroPressValue: new Decimal('14.69595'),
    },
    defaultUnits: {
      baroPressUnit: 'psi',
      outputPressureDropUnit: 'psi',
      densityUnit: 'lb/ft^3',
      viscosityUnit: '',
      velocityUnit: '',
      timeUnit: '',
      pressureDropUnit: '',
    },
  },
  workingDefaults: {
    defaultFormats: {
      flowRateFormat: '#,###,##0.00##',
      lengthFormat: '#,##0.00',
      massFormat: '#,###,##0.00##',
      pressureFormat: '#,###,##0.00##',
      temperatureFormat: '#,###,##0.####',
      volumeFormat: '0.00##',
      areaFormat: '0.00##',
      densityFormat: '0.00##',
      velocityFormat: '0.00##',
      viscosityFormat: '0.00##',
      unitlessFormat: '0.00##',
      generalNumberFormat: '0#',
      fixedFormat: '0.00##',
      standardFormat: '#,##0.00',
    },
    defaultProcessConditions: {
      defaultStdConditions: new ProcessCondition(),
      defaultNmlConditions: new ProcessCondition(),
      standardConditions: new ProcessCondition(),
      normalConditions: new ProcessCondition(),
    },
    defaultValues: {
      baroPressValue: new Decimal('14.69595'),
    },
    defaultUnits: {
      baroPressUnit: 'psi',
      outputPressureDropUnit: 'psi',
      densityUnit: 'lb/ft^3',
      viscosityUnit: '',
      velocityUnit: '',
      timeUnit: '',
      pressureDropUnit: '',
    },
    newValuesSaved: false,
  },
  db: {
    ansiPipeSizes: [],
    application: [],
    customer: [],
    defaults: [],
    density: [],
    dinPipeSizes: [],
    feature: [],
    flangeTestsAsme: [],
    flangeTestsDin: [],
    flangeTestsJis: [],
    flow: [],
    fluid: [],
    gas: [],
    image: [],
    inlines: [],
    inlinesProM: [],
    jisPipeSchedules: [],
    jisPipeSizes: [],
    length: [],
    linerMaterial: [],
    liquid: [],
    m22Feature: [],
    m23Feature: [],
    m24Feature: [],
    m24rFeature: [],
    mvcFeature: [],
    mvcrFeature: [],
    othergas: [],
    otherliquid: [],
    pipesdin: [],
    pipeMaterial: [],
    pipeSchedules: [],
    pressure: [],
    printheader: [],
    s34Feature: [],
    s36Feature: [],
    soundspeed: [],
    temperature: [],
    time: [],
    turbineFeature: [],
    u42Feature: [],
    u43Feature: [],
    u44Feature: [],
    proMFeature: [],
    viscosity: [],
    vusers: [],
  },
};

export default initialState;

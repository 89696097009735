/* eslint-disable camelcase */
import Decimal from 'decimal.js';
import { qrenc } from '../utilities/qrenc';
import store from '../store';
import utilities from '../utilities';
import actions from '../store/actions';
import convert from '../utilities/convert';
import api from '../api';
import constants from '../constants';
import OtherGas from '../types/fluid/OtherGas';
import calcTurbineMeter from '../utilities/calc/calcTurbineMeter';
import meterSpecify from '../utilities/meterSpecify';
import { CallbackEvent } from '../types/callbackTypes';

const PrintMeterInfo = (props: { onAfterPrint: CallbackEvent }): JSX.Element => {
  const state = store.getState();
  const {
    accessories,
    customer,
    defaults,
    fluid,
    fluidType,
    isIE89,
    meterIndex,
    meterData,
    meterType,
    modelCode,
    otherLiquid,
    pipeInsideDiameter,
    pipeLinerMaterial,
    pipeMaterial,
    pipeOutsideDiameter,
    pipeSchedule,
    pipeType,
    pipeUnit,
    processConditions,
    provSizingGlobals,
    reducingVortex,
    sizingGlobals,
    specifyMeterSpecialNotes,
    specifyMeterAccuracy,
    specifyMeterApprovals,
    specifyMeterCableLength,
    specifyMeterCommunications,
    specifyMeterDiagAddPressTap,
    specifyMeterDiagDiffPressXMTR,
    specifyMeterDiffPressXMTR,
    specifyMeterDiffPressXMTRMani,
    specifyMeterDisplayOption,
    specifyMeterElectrodeMaterial,
    specifyMeterElectronicsMounting,
    specifyMeterEnergyMeter,
    specifyMeterFaceToFace,
    specifyMeterFlange,
    specifyMeterGrounding,
    specifyMeterInputPower,
    specifyMeterLinerMaterial,
    specifyMeterOutputSignal,
    specifyMeterParentModelNumber,
    specifyMeterPipeMaterial,
    specifyMeterPipeSize,
    specifyMeterProbeLength,
    specifyMeterProcessConnection,
    specifyMeterProcessFluid,
    specifyMeterProcessTemperature,
    specifyMeterTransducer,
    specifyMeterTransducerBracket,
    specifyMeterTagNumber,
    specifyMeterWarnings,
    username,
  } = state;

  const { flowUnit, flowTime, temperatureUnit, pressurePrefix, pressureCoefficient } = processConditions;
  const { defaultUnits } = defaults;
  const { densityUnit, viscosityUnit } = defaultUnits;

  const {
    CODE_NAME,
    CODE_DESCRIPTION,
    DATA_SHEET,
    InlineModel,
    InlineNonReduceModel,
    InlineReduceModel,
    InlineReduceModelStr,
    InsertionModel,
    TurbineModelShort,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
    ElectromagneticProMModelStr,
    VORTEK_ADDRESS0,
    VORTEK_ADDRESS1,
    VORTEK_CITY,
    VORTEK_COUNTRY,
    VORTEK_FAX,
    VORTEK_NAME,
    VORTEK_POSTAL_CODE,
    VORTEK_PRINT_NAME,
    VORTEK_STATE,
    VORTEK_TELEPHONE,
  } = sizingGlobals;

  const selectedMeter = meterData[meterIndex];
  let remoteCableLength;
  let finalModelCode = modelCode;

  // first, check values and make adjustments if necessary
  if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
    //
  } else if (VORTEK_NAME === utilities.AZBIL_NAME) {
    //
  } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
    if (specifyMeterElectronicsMounting && specifyMeterElectronicsMounting.substring(2, 3) === ')') {
      store.dispatch(actions.setSpecifyMeterElectronicsMounting('R(15m)'));
      finalModelCode = meterSpecify.createModelCode();
    }
  } else if (VORTEK_NAME === utilities.ONICON_NAME) {
    //
  } else if (VORTEK_NAME === utilities.PANAFLOW_NAME) {
    //
  } else if (VORTEK_NAME === utilities.INNOVA_NAME) {
    if (
      specifyMeterElectronicsMounting &&
      ((specifyMeterElectronicsMounting.length > 3 && specifyMeterElectronicsMounting.substring(3, 4) === ')') ||
        specifyMeterElectronicsMounting === '')
    ) {
      store.dispatch(actions.setSpecifyMeterElectronicsMounting('E4(50)'));
      finalModelCode = meterSpecify.createModelCode();
    }
  } else if (
    (VORTEK_NAME === utilities.SPIRAX_NAME && (meterType === 'vorcone' || meterType === 'reduce_vorcone')) ||
    VORTEK_NAME === utilities.PROV_NAME ||
    VORTEK_NAME === utilities.GESTRA_NAME ||
    VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
  ) {
    if (
      specifyMeterElectronicsMounting &&
      specifyMeterElectronicsMounting.length > 2 &&
      specifyMeterElectronicsMounting.substring(2, 3) === ')'
    ) {
      store.dispatch(actions.setSpecifyMeterElectronicsMounting('R(50)'));
      finalModelCode = meterSpecify.createModelCode();
    }
  }

  if (
    specifyMeterElectronicsMounting &&
    specifyMeterElectronicsMounting.length > 2 &&
    specifyMeterElectronicsMounting.substring(2, 3) !== ')'
  ) {
    remoteCableLength = specifyMeterElectronicsMounting.substring(
      specifyMeterElectronicsMounting.indexOf('('),
      specifyMeterElectronicsMounting.indexOf(')') + 1
    );
  }

  let printInfo = '<html><head>';
  const fontSize = '10px';
  let headerFontSize = '10px';
  let fontWeight = 'normal';

  if (VORTEK_NAME === utilities.INNOVA_NAME) {
    headerFontSize = '12px';
  }

  if (VORTEK_NAME === utilities.HEINRICH_NAME) {
    headerFontSize = '12px';
    fontWeight = 'bold';
  }

  printInfo += '<style type="text/css">\n';
  printInfo += '@media print{\n';
  printInfo += `body        { background-color:#FFFFFF; color:#000000; line-height:1.0; font-family:arial,helvetica,sans-serif; font-size:${fontSize};}\n`;
  printInfo += `table       { background-color:#FFFFFF; color:#000000; line-height:1.0; font-family:arial,helvetica,sans-serif; font-size:${fontSize};}\n`;
  printInfo += `tr          { background-color:#FFFFFF; color:#000000; line-height:1.0; font-family:arial,helvetica,sans-serif; font-size:${fontSize};}\n`;
  printInfo += `td          { background-color:#FFFFFF; color:#000000; line-height:1.0; font-family:arial,helvetica,sans-serif; font-size:${fontSize};}\n`;
  printInfo += `div         { background-color:#FFFFFF; color:#000000; line-height:1.0; font-family:arial,helvetica,sans-serif; font-size:${fontSize};}\n`;
  printInfo += `canvas      { background-color:#FFFFFF; color:#000000; line-height:1.0; font-family:arial,helvetica,sans-serif; font-size:${fontSize};}\n`;
  printInfo += `h2          { background-color:#FFFFFF; color:#000000; line-height:1.0; font-family:arial,helvetica,sans-serif; font-size:${headerFontSize}; font-weight: ${fontWeight};}\n`;
  printInfo += 'hr          { margin: 1px;}\n';
  printInfo += 'img         { background-color:#FFFFFF; color:#000000; border: 0; display: inline !important;}\n';
  printInfo += '#ad         { display:none;}\n';
  printInfo += '#leftbar    { display:none;}\n';
  printInfo += '#contentarea{ width:90%;}\n';
  printInfo += '}\n';
  printInfo += '</style>\n';

  printInfo += '</head><body>';

  const calculData = selectedMeter.newPipeData;

  // customer information...
  const companyName = VORTEK_PRINT_NAME;
  let industryCode = CODE_NAME;
  let typeOfContact = CODE_DESCRIPTION;
  const address1 = VORTEK_ADDRESS0;
  const address2 = VORTEK_ADDRESS1;
  const city = VORTEK_CITY;
  const vortekState = VORTEK_STATE;
  const zipcode = VORTEK_POSTAL_CODE;
  const country = VORTEK_COUNTRY;
  const phoneCustomer = VORTEK_TELEPHONE;
  const faxCustomer = VORTEK_FAX;

  if (meterType === 'turbine') {
    industryCode = TurbineModelShort;
    typeOfContact = 'Insertion Turbine Flow Meter';
  } else if (
    (specifyMeterParentModelNumber === InlineModel && meterType === 'vortex') ||
    specifyMeterParentModelNumber === InlineNonReduceModel
  ) {
    industryCode = specifyMeterParentModelNumber;
    typeOfContact = `Inline ${typeOfContact}`;
  } else if (specifyMeterParentModelNumber === InlineReduceModel) {
    if (VORTEK_NAME === utilities.ONICON_NAME) {
      industryCode = InlineReduceModelStr;
      typeOfContact = `Inline Reducing ONICON Vortex Flow Meter`;
    } else {
      industryCode = InlineReduceModel;
      typeOfContact = `Inline ${typeOfContact}`;
    }
  } else if (
    meterType === UltrasonicS34Model ||
    meterType === UltrasonicS36Model ||
    meterType === UltrasonicU42Model ||
    meterType === UltrasonicU43Model
  ) {
    industryCode = meterType;
    typeOfContact = 'Ultrasonic Clamp-On Flow Meter';
  } else if (meterType === UltrasonicU44Model) {
    industryCode = meterType;
    typeOfContact = 'Portable Ultrasonic Clamp-On Flow Meter';
  } else if (meterType === 'vorcone' || meterType === 'reduce_vorcone') {
    industryCode = selectedMeter.type;
    typeOfContact = 'VorCone Flow Meter';
  } else if (meterType === ElectromagneticProMModel) {
    industryCode = ElectromagneticProMModelStr;
    typeOfContact = `Pro-M ${industryCode}`;
  } else {
    industryCode = InsertionModel;
    typeOfContact = `Insertion ${typeOfContact}`;
  }

  let workingVersion = sizingGlobals;

  if (VORTEK_NAME === utilities.SPIRAX_NAME && (meterType === 'vorcone' || meterType === 'reduce_vorcone')) {
    workingVersion = provSizingGlobals;
  }

  const printCompanyName = customer?.customer?.company_name || '';
  const printIndustryCode = printCompanyName ? customer?.customer?.industry_code : '';
  const printFirstName = customer?.customer?.first_name || '';
  const printLastName = customer?.customer?.last_name || '';
  const printEmail = customer?.customer?.email || '';
  const printAddress1 = customer?.customer?.address1 || '';
  const printAddress2 = customer?.customer?.address2 || '';
  const printCity = customer?.customer?.city || '';
  const printState = customer?.customer?.state || '';
  const printZipCode = customer?.customer?.zipcode || '';
  const printCountry = customer?.customer?.country || '';
  const printTitleCustomer = customer?.customer?.title_customer || '';
  const printPhoneCustomer = customer?.customer?.phone_customer || '';
  const printExtensionCustomer = customer?.customer?.extension_customer || '';
  const printFaxCustomer = customer?.customer?.fax_customer || '';
  const printDoNotPrint = customer?.customer?.donotprint;

  let temp_unit = selectedMeter.newPipeData.temperatureUnit;

  // min...
  const min_flow = convert.formatNumber(calculData.flowMin, defaults.defaultFormats.flowRateFormat);
  const min_temp = selectedMeter.newPipeData.temperatureMin;
  const min_press = selectedMeter.newPipeData.pressureMin;
  const min_density = selectedMeter.newPipeData.densityMinDisplay;
  const min_visc = selectedMeter.newPipeData.viscosityMinDisplay;

  // nom...
  const nom_flow = convert.formatNumber(calculData.flowNom, defaults.defaultFormats.flowRateFormat);
  const nom_temp = selectedMeter.newPipeData.temperatureNom;
  const nom_press = selectedMeter.newPipeData.pressureNom;
  const nom_density = selectedMeter.newPipeData.densityNomDisplay;
  const nom_visc = selectedMeter.newPipeData.viscosityNomDisplay;

  // max...
  const max_flow = convert.formatNumber(calculData.flowMax, defaults.defaultFormats.flowRateFormat);
  const max_temp = selectedMeter.newPipeData.temperatureMax;
  const max_press = selectedMeter.newPipeData.pressureMax;
  const max_density = selectedMeter.newPipeData.densityMaxDisplay;
  const max_visc = selectedMeter.newPipeData.viscosityMaxDisplay;

  let pipeTypeStr: string = pipeType;
  if (pipeTypeStr === 'ansi') {
    pipeTypeStr = 'ANSI';
  } else if (pipeTypeStr === 'din') {
    pipeTypeStr = 'DIN';
  } else if (pipeTypeStr === 'jis') {
    pipeTypeStr = 'JIS';
  }

  let appPipeArea = convert.getArea(new Decimal(pipeInsideDiameter)).toString();
  appPipeArea = convert.formatNumber(appPipeArea, defaults.defaultFormats.unitlessFormat);

  const pipeSched = calculData.pipeSchedule;
  const pipeId = calculData.pipeInsideDiameter;

  const flow_unit = flowUnit;
  const flow_time = flowTime;
  const temp_units = temperatureUnit;

  if (temp_units === 'F') {
    temp_unit = 'deg F';
  } else if (temp_units === 'C') {
    temp_unit = 'deg C';
  } else if (temp_units === 'R') {
    temp_unit = 'Rk';
  }

  const baromerticPressure = convert.formatNumber(
    defaults.defaultValues.baroPressValue.toString(),
    defaults.defaultFormats.pressureFormat
  );
  let printNormalConditions = false;
  let printStdConditions = false;

  // specifyMeter Model Code...

  const TagNumber = specifyMeterTagNumber;
  const specialNotes = specifyMeterSpecialNotes;
  const warning = specifyMeterWarnings; // Warnings...
  let printHeaderLogoData;
  let printLogoSeparatorData;
  let printIconData;
  let logoName = 'print_header_logo';
  if (workingVersion.VORTEK_NAME === utilities.HEINRICH_NAME) {
    logoName = 'print_header_logo_pdf';
  }

  const res = api.readOneSync(false, {
    requestData: {
      client: VORTEK_NAME,
      username,
      collection: 'image',
    },
  });

  const defaultRes = api.readOneSync(false, {
    requestData: {
      client: VORTEK_NAME,
      username: 'DEFAULT',
      collection: 'image',
    },
  });

  if (res.status !== 'fail') {
    if (res.data?.data) {
      printHeaderLogoData = utilities.findInArrayByName(res.data.data, logoName);
    }

    if (res.data?.data) {
      printIconData = utilities.findInArrayByName(res.data.data, 'sizing_app');
    }

    if (res.data?.data) {
      printLogoSeparatorData = utilities.findInArrayByName(res.data.data, 'logo_separator');
    }
  }

  if (defaultRes.status !== 'fail') {
    if (!printHeaderLogoData) {
      printHeaderLogoData = utilities.findInArrayByName(defaultRes.data.data, logoName);

      if (printHeaderLogoData) {
        printHeaderLogoData = printHeaderLogoData.imgdata;
      }
    }

    if (!printIconData) {
      printIconData = utilities.findInArrayByName(defaultRes.data.data, 'sizing_app');

      if (printIconData) {
        printIconData = printIconData.imgdata;
      }
    }

    if (!printLogoSeparatorData) {
      printLogoSeparatorData = utilities.findInArrayByName(defaultRes.data.data, 'logo_separator');

      if (printLogoSeparatorData) {
        printLogoSeparatorData = printLogoSeparatorData.imgdata;
      }
    }

    if (!printHeaderLogoData) {
      printHeaderLogoData = `./images/${encodeURIComponent(VORTEK_NAME)}/print_header_logo.png`;
    }

    if (!printIconData) {
      printIconData = `./images/${encodeURIComponent(VORTEK_NAME)}/sizing_app.png`;
    }

    if (!printLogoSeparatorData) {
      printLogoSeparatorData = `./images/${encodeURIComponent(VORTEK_NAME)}/logo_separator.png`;
    }
  }

  if (workingVersion.VORTEK_NAME === utilities.AZBIL_NAME) {
    printInfo += `${
      '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr><td style="vertical-align:top; background-color:white;"><img id="print_header_logo"' +
      ' src="'
    }${printHeaderLogoData}" alt="" style="max-height: 223px; max-width: 131px;"><br>`;
    if (companyName) {
      printInfo += companyName;
      printInfo += '<br>';
    }

    if (address1) {
      printInfo += address1;
    }
    printInfo += '<br>';
    if (address2) {
      printInfo += address2;
    }

    printInfo += '<br>';
    if (city) {
      printInfo += `${city}, `;
    }

    if (vortekState && vortekState.trim()) {
      printInfo += vortekState.trim();
    }

    if (zipcode) {
      printInfo += ` ${zipcode}`;
    }

    printInfo += '<br>';
    if (country) {
      printInfo += country;
    }

    printInfo += '</td><td style="vertical-align:top; text-align:center">';

    printInfo += '<h2>';
    if (industryCode) {
      printInfo += industryCode;
    }
    printInfo += '<br>';
    if (typeOfContact) {
      printInfo += typeOfContact;
    }
    printInfo += `<br>${DATA_SHEET}`;
    printInfo += '</h2>';

    printInfo += `</td><td style="vertical-align:top; text-align:right; background-color:white;"><img style="max-width: 40px; max-height: 40px;" id="print_sizing_logo" src="${printIconData}" alt=""><br><br><span>`;

    if (phoneCustomer) {
      printInfo += phoneCustomer;
    }

    printInfo += '<br>';
    if (faxCustomer) {
      printInfo += faxCustomer;
    }
    printInfo += `<br>Revision ${constants.REVISION}`;

    printInfo += '</span></td></tr>\n</table>\n';
    printInfo += '<hr style="background-color:#333; height:1px; border: 0; color: #333;"/>\n';
  } else if (workingVersion.VORTEK_NAME === utilities.INNOVA_NAME) {
    const styleSmallBold =
      ' style="font-family:arial,helvetica,sans-serif; font-size:8.1px; font-weight: bold; line-height:0.75 !important;"';
    const styleSmallNormal = ' style="font-family:arial,helvetica,sans-serif; font-size:8.1px; line-height:0.75 !important;"';
    const styleTinyNormal = ' style="font-family:arial,helvetica,sans-serif; font-size:8px; line-height:0.75 !important;"';

    printInfo +=
      `${
        '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr><td style="vertical-align: text-top; background-color:white;"><img style="vertical-align: text-top" id="print_header_logo"' +
        ' src="'
      }${printHeaderLogoData}" alt=""></td><td style="vertical-align: text-top; background-color:white;"><img style="vertical-align: text-top" id="logo_separator"` +
      ` src="${printLogoSeparatorData}" alt=""></td><td><table>\n` +
      ` <tr><td${styleSmallBold}>NORTH AMERICA</td>` +
      `<td${styleSmallBold}>ASIA-PACIFIC</td>` +
      `<td${styleSmallBold}>EUROPE</td></tr>\n` +
      ` <tr><td${styleSmallNormal}>5 HARRIS COURT, BLDG. L</td>` +
      `<td${styleSmallNormal}>RM.618, TOMSON CENTRE, BLDG. A</td>` +
      `<td${styleSmallNormal}>BIJLMANSWEID 2</td></tr>\n` +
      ` <tr><td${styleSmallNormal}>MONTEREY, CA 93940</td>` +
      `<td${styleSmallNormal}>188 ZHANG YANG ROAD</td>` +
      `<td${styleSmallNormal}>1934RE EGMOND AAN DEN HOEF</td></tr>\n` +
      ` <tr><td${styleSmallNormal}>TOLL FREE: 800-866-0200</td>` +
      `<td${styleSmallNormal}>PU DONG NEW DISTRICT</td>` +
      `<td${styleSmallNormal}>THE NETHERLANDS</td></tr>\n` +
      ` <tr><td${styleSmallNormal}>PHONE: 831-373-0200</td>` +
      `<td${styleSmallNormal}>SHANGHAI, P.R. CHINA 200122</td>` +
      `<td${styleSmallNormal}>PHONE: +31 72 5071400</td></tr>\n` +
      ` <tr><td${styleSmallNormal}>FAX: 831-373-4402</td>` +
      `<td${styleSmallNormal}>PHONE: +8621 5879 8521/22</td>` +
      `<td${styleSmallNormal}>FAX: +31 72 5071401</td></tr>\n` +
      ` <tr><td${styleTinyNormal}>www.sierrainstruments.com</td>` +
      `<td${styleSmallNormal}>FAX: +8621 5879 8586</td>` +
      `<td${styleSmallNormal}>&nbsp;</td></tr>\n` +
      ` </table>\n</td></tr>\n<tr><td colspan="3">`;

    printInfo +=
      '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr><td style="width: 131px;">&nbsp;</td><td style="vertical-align:top; text-align:center">';

    printInfo += '<h2>';
    if (industryCode) {
      printInfo += industryCode;
    }
    printInfo += '<br>';
    if (typeOfContact) {
      printInfo += typeOfContact;
    }
    printInfo += `<br>${DATA_SHEET}`;
    printInfo += `<br>Revision ${constants.REVISION}`;
    printInfo += '</h2>';

    printInfo += `</td><td style="vertical-align:top; background-color:white;"><img style="float: right; max-width: 40px; max-height: 40px;" id="print_sizing_logo" src="${printIconData}" alt=""></td></tr>\n</table>\n</td></tr>\n`;
    printInfo +=
      '<tr><td colspan="3"><hr style="background-color:#333; height:1px; border: 0; color: #333;"/></td></tr>\n</table>\n';
  } else if (workingVersion.VORTEK_NAME === utilities.HEINRICH_NAME) {
    printInfo += `${
      '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr><td style="vertical-align:top; background-color:white; width: 10%;"><img id="print_header_logo"' +
      ' src="'
    }${printHeaderLogoData}" alt="" style="max-height: 223px; max-width: 131px;"></td><td style="vertical-align:top; text-align:center; width: 50%;">\n`;

    printInfo += '<h2>';
    if (industryCode) {
      printInfo += industryCode;
    }
    if (typeOfContact) {
      printInfo += ` - ${typeOfContact}`;
    }
    printInfo += `<br><br>${DATA_SHEET}`;
    printInfo += '</h2>';

    printInfo += '</td><td style="vertical-align:bottom; width: 30%;">\n';
    printInfo += '    <table>\n';
    printInfo += '        <tbody>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:12px; font-weight: bold; line-height:0.75 !important;">Heinrichs Messtechnik GmbH</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">Robert-Perthel-Stra&szlig;e 9</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">50739 Cologne</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">Germany</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">&nbsp;</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">Tel: +49 (221) 49708-0</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">Fax: +49 (221) 49708-178</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;"><a href="http://www.heinrichs.eu" style="text-decoration: none">www.heinrichs.eu</a></td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;"><a href="mailto:info@heinrichs.eu" style="text-decoration: none">info@heinrichs.eu</a></td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo +=
      '                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">&nbsp;</td>\n';
    printInfo += '            </tr>\n';
    printInfo += '            <tr>\n';
    printInfo += `                <td style="font-family:arial,helvetica,sans-serif; font-size:10px; line-height:0.75 !important;">Revision ${constants.REVISION}</td>\n`;
    printInfo += '            </tr>\n';
    printInfo += '        </tbody>\n';
    printInfo += '    </table>\n';

    printInfo += `</td>\n<td style="vertical-align:top; background-color:white; width: 10%;">\n<img style="float: right; max-width: 40px; max-height: 40px;" id="print_sizing_logo" src="${printIconData}" alt="">\n</td>\n</tr>\n</table>\n`;
    printInfo += '<hr style="background-color:#333; height:1px; border: 0; color: #333;"/>\n';
  } else {
    printInfo += `${
      '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr>\n<td style="vertical-align:top; background-color:white;">\n<img id="print_header_logo"' +
      ' src="'
    }${printHeaderLogoData}" alt="" style="max-height: 223px; max-width: 131px;">\n</td>\n<td style="vertical-align:top; text-align:center">\n`;

    printInfo += '<h2>\n';
    if (industryCode) {
      printInfo += industryCode;
    }
    printInfo += '<br>\n';
    if (typeOfContact) {
      printInfo += typeOfContact;
    }
    printInfo += `<br>\n${DATA_SHEET}`;
    printInfo += '</h2>\n';

    printInfo += '</td>\n<td style="vertical-align:bottom">\n';

    if (companyName) {
      printInfo += `${companyName}<br>\n`;
    }

    if (address1) {
      printInfo += `${address1}<br>\n`;
    }

    if (address2) {
      printInfo += `${address2}<br>\n`;
    }

    if (city) {
      printInfo += city;
    }

    if (vortekState && vortekState.trim()) {
      printInfo += `, ${vortekState.trim()}`;
    }

    if (zipcode) {
      printInfo += ` ${zipcode}`;
    }

    printInfo += '<br>\n';
    if (phoneCustomer) {
      printInfo += `${phoneCustomer}<br>\n`;
    }

    if (country) {
      printInfo += `${country}<br>\n`;
    }

    if (faxCustomer) {
      printInfo += faxCustomer;
    }

    printInfo += `<br>Revision ${constants.REVISION}`;

    printInfo += '</td>\n<td style="vertical-align:top; background-color:white;">';
    if (VORTEK_NAME !== utilities.SPIRAX_NAME) {
      printInfo += `\n<img style="float: right; max-width: 40px; max-height: 40px;" id="print_sizing_logo" src="${printIconData}" alt="">`;
    }
    printInfo += '\n</td>\n</tr>\n</table>\n';
    printInfo += '<hr style="background-color:#333; height:1px; border: 0; color: #333;"/>\n';
  }

  if (workingVersion.VORTEK_NAME === utilities.ONICON_NAME && !printCompanyName) {
    printInfo += '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n';
  } else {
    printInfo +=
      '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr>\n<td colspan="2" style="text-align:center">CUSTOMER INFORMATION</td>\n</tr>\n';
  }

  if (printDoNotPrint === 'print') {
    if (printCompanyName || printIndustryCode) {
      printInfo += '<tr>\n<td>\n';
      if (printCompanyName) {
        printInfo += `Company Name: ${printCompanyName}`;
      }

      printInfo += '</td>\n<td style="text-align:right">Industry Code: ';
      if (printIndustryCode) {
        printInfo += printIndustryCode;
      }

      printInfo += '</td>\n</tr>\n';
    }

    if (printFirstName || printLastName || printPhoneCustomer || printExtensionCustomer) {
      printInfo += '<tr><td>&nbsp;</td></tr><tr><td>User</td></tr></table>\n';
      printInfo += '<hr style="background-color:#333; height:1px; border: 0; color: #333;"/>';
      printInfo += '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">';

      printInfo += '<tr><td>';
      if (printFirstName) {
        printInfo += `${printFirstName} `;
      }

      if (printFirstName && printLastName) {
        printInfo += ' ';
      }

      if (printLastName) {
        printInfo += printLastName;
      }

      printInfo += '</td><td style="text-align:right">';

      if (printPhoneCustomer && printExtensionCustomer) {
        printInfo += `${printPhoneCustomer} `;
      }

      if (printExtensionCustomer) {
        printInfo += `ext: ${printExtensionCustomer}`;
      }

      printInfo += '</td></tr>';
    } else {
      printInfo += '<tr><td>&nbsp;</td></tr><tr><td></td></tr></table>\n';
      printInfo += '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">';
    }

    if (printTitleCustomer || printFaxCustomer) {
      printInfo += '<tr><td>';

      if (printTitleCustomer) {
        printInfo += printTitleCustomer;
      }

      printInfo += '</td><td style="text-align:right">FAX: ';

      if (printFaxCustomer) {
        printInfo += printFaxCustomer;
      }

      printInfo += '</td></tr>';
    }

    if (printAddress1 || printEmail) {
      printInfo += '<tr><td>';

      if (printAddress1) {
        printInfo += printAddress1;
      }

      printInfo += '</td><td style="text-align:right">';

      if (printEmail) {
        printInfo += printEmail;
      }

      printInfo += '</td></tr>';
    }

    if (printAddress2) {
      printInfo += `<tr><td colspan="2">${printAddress2}</td></tr>`;
    }

    if (printCity || printState || printZipCode) {
      printInfo += '<tr><td colspan="2">';
      if (printCity) {
        printInfo += printCity;
      }

      if (printCity && (printState || printZipCode)) {
        printInfo += ', ';
      }

      if (printState) {
        printInfo += printState;
      }

      if (printState && printZipCode) {
        printInfo += ' ';
      }

      if (printZipCode) {
        printInfo += printZipCode;
      }

      printInfo += '</td></tr>';
    }

    if (printCountry) {
      printInfo += `<tr><td colspan="2">${printCountry}</td></tr>`;
    }
  } else if (printCompanyName || printIndustryCode) {
    printInfo += '<tr><td>Company Name:</td><td style="text-align:right">Industry Code:</td></tr>';
  }

  printInfo += '</table>\n';

  printInfo +=
    '<div style="text-align: center">Application Name</div><hr style="background-color:#333; height:1px; border: 0; color: #333;"/>\n';

  if (calculData) {
    printInfo += `
      <div class="mail_contain">
        <div style="text-align: center">PROCESS CONDITIONS for ${fluidType}, ${fluid}</div>
        <br />
        <table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">
          <tr><td>&nbsp;</td><td>Minimum</td><td>Nominal</td><td>Maximum</td><td>Units</td></tr>
          <tr><td>Flow Rate:</td><td>${min_flow}</td><td>${nom_flow}</td><td>${max_flow}</td><td>${flow_unit}/${flow_time}</td></tr>
          <tr><td>Temperature:</td><td>${min_temp}</td><td>${nom_temp}</td><td>${max_temp}</td><td>${temp_unit}</td></tr>
          <tr><td>Pressure:</td><td>${min_press}</td><td>${nom_press}</td><td>${max_press}</td><td>${pressurePrefix}-${pressureCoefficient}</td></tr>
          <tr><td>Density:</td><td>${min_density}</td><td>${nom_density}</td><td>${max_density}</td><td>${densityUnit}</td></tr>
          <tr><td>Viscosity:</td><td>${min_visc}</td><td>${nom_visc}</td><td>${max_visc}</td><td>${viscosityUnit}</td></tr>
        </table>\n`;

    printInfo +=
      '<table style="width: 60%; border-width: 0; border-spacing: 0; padding: 0;"><tr><td colspan="2">&nbsp;</td></tr><tr><td>';
    if (pipeTypeStr === 'other') {
      printInfo += `Other pipe: Outside diameter = ${pipeOutsideDiameter} ${pipeUnit}, Wall Thickness = ${pipeSchedule} ${pipeUnit}`;
      printInfo += '</td>';
      if (
        meterType === workingVersion.UltrasonicS34Model ||
        meterType === workingVersion.UltrasonicS36Model ||
        meterType === workingVersion.UltrasonicU42Model ||
        meterType === workingVersion.UltrasonicU43Model ||
        meterType === workingVersion.UltrasonicU44Model
      ) {
        printInfo += `<td>Pipe Material: ${pipeMaterial}</td>`;
      } else {
        printInfo += '<td>&nbsp;</td>';
      }
      printInfo += '</tr>';
      printInfo += `<tr><td>Inside Diameter = ${pipeInsideDiameter}, Area = ${appPipeArea} square ${pipeUnit}</td>`;
      if (
        meterType === workingVersion.UltrasonicS34Model ||
        meterType === workingVersion.UltrasonicS36Model ||
        meterType === workingVersion.UltrasonicU42Model ||
        meterType === workingVersion.UltrasonicU43Model ||
        meterType === workingVersion.UltrasonicU44Model
      ) {
        printInfo += `<td>Pipe Liner: ${pipeLinerMaterial}</td>`;
      } else {
        printInfo += '<td>&nbsp;</td>';
      }
    } else {
      printInfo += `${pipeTypeStr} Standard Pipe: ${pipeOutsideDiameter}`;
      if (pipeSched) {
        printInfo += ` Schedule ${pipeSchedule}`;
      }

      printInfo += '</td>';
      if (
        meterType === workingVersion.UltrasonicS34Model ||
        meterType === workingVersion.UltrasonicS36Model ||
        meterType === workingVersion.UltrasonicU42Model ||
        meterType === workingVersion.UltrasonicU43Model ||
        meterType === workingVersion.UltrasonicU44Model
      ) {
        printInfo += `<td>Pipe Material: ${pipeMaterial}</td>`;
      } else {
        printInfo += '<td>&nbsp;</td>';
      }
      printInfo += '</tr>';
      printInfo += `<tr><td>Inside Diameter = ${convert.formatNumber(
        pipeInsideDiameter,
        defaults.defaultFormats.unitlessFormat
      )} ${pipeUnit}, Area = ${appPipeArea} square ${pipeUnit}</td>`;
      if (
        meterType === workingVersion.UltrasonicS34Model ||
        meterType === workingVersion.UltrasonicS36Model ||
        meterType === workingVersion.UltrasonicU42Model ||
        meterType === workingVersion.UltrasonicU43Model ||
        meterType === workingVersion.UltrasonicU44Model
      ) {
        printInfo += `<td>Pipe Liner: ${pipeLinerMaterial}</td>`;
      } else {
        printInfo += '<td>&nbsp;</td>';
      }
    }

    printInfo += '</tr>';

    printNormalConditions = convert.getFlowRateType(calculData.flowUnit) === constants.NORMAL;
    printStdConditions = convert.getFlowRateType(calculData.flowUnit) === constants.STANDARD;

    if (printNormalConditions) {
      printInfo += '<tr><td colspan="2">&nbsp;</td></tr>';
      printInfo += `<tr><td colspan="2">Normal Temperature = ${convert.formatThroughMask(
        convert.formatNumber(
          defaults.defaultProcessConditions.defaultNmlConditions.dblTemperature.toString(),
          defaults.defaultFormats.temperatureFormat
        ),
        defaults.defaultFormats.temperatureFormat
      )} ${defaults.defaultProcessConditions.defaultNmlConditions.sTemperatureUnit} </td></tr>`;

      let normalPressureUnit = defaults.defaultProcessConditions.defaultNmlConditions.sPressureUnit;
      if (normalPressureUnit === 'psi') {
        normalPressureUnit += '-A';
      }

      printInfo += `<tr><td colspan="2">Normal Pressure = ${convert.formatThroughMask(
        convert.formatNumber(
          defaults.defaultProcessConditions.defaultNmlConditions.dblPressure.toString(),
          defaults.defaultFormats.pressureFormat
        ),
        defaults.defaultFormats.pressureFormat
      )} ${normalPressureUnit} </td></tr>`;
    } else if (printStdConditions) {
      printInfo += '<tr><td colspan="2">&nbsp;</td></tr>';
      printInfo += `<tr><td colspan="2">Standard Temperature = ${convert.formatThroughMask(
        convert.formatNumber(
          defaults.defaultProcessConditions.defaultStdConditions.dblTemperature.toString(),
          defaults.defaultFormats.temperatureFormat
        ),
        defaults.defaultFormats.temperatureFormat
      )} ${defaults.defaultProcessConditions.defaultStdConditions.sTemperatureUnit} </td></tr>`;

      let standardPressureUnit = defaults.defaultProcessConditions.defaultStdConditions.sPressureUnit;
      if (standardPressureUnit === 'psi') {
        standardPressureUnit += '-A';
      }

      printInfo += `<tr><td colspan="2">Standard Pressure = ${convert.formatThroughMask(
        convert.formatNumber(
          defaults.defaultProcessConditions.defaultStdConditions.dblPressure.toString(),
          defaults.defaultFormats.pressureFormat
        ),
        defaults.defaultFormats.pressureFormat
      )} ${standardPressureUnit} </td></tr>`;
    }

    let dispTemp;

    if (fluidType === 'Other Gas') {
      const otherGas = new OtherGas();
      otherGas.retrieveOtherGas();
      printInfo += '</tr><tr><td colspan="2">&nbsp;</td></tr>';
      dispTemp = convert.formatThroughMask(
        convert.formatNumber(otherGas.dblSpecificGravity.toString(), defaults.defaultFormats.densityFormat),
        defaults.defaultFormats.densityFormat
      );
      printInfo += `</tr><tr><td colspan="2">Specific Gravity = ${dispTemp}</td></tr>`;

      dispTemp = convert.formatThroughMask(
        convert.formatNumber(otherGas.dblCompressibility.toString(), defaults.defaultFormats.densityFormat),
        defaults.defaultFormats.densityFormat
      );
      printInfo += `</tr><tr><td colspan="2">Compressibility = ${dispTemp}</td></tr>`;
    }

    printInfo += '</tr><tr><td colspan="2">&nbsp;</td></tr>';
    printInfo += `<tr><td colspan="2">Barometric Pressure = ${baromerticPressure}${defaults.defaultUnits.baroPressUnit} </td></tr>`;
    printInfo += '</tr><tr><td colspan="2">&nbsp;</td></tr>';
    printInfo += '</table>\n';

    printInfo += `${
      '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n' +
      '<tr><td style="font-weight: bold; white-space: nowrap">Meter Code: '
    }${modelCode}</td></tr>\n`;
    if (TagNumber) {
      printInfo += `<tr><td style="white-space: nowrap">Tag Number: ${TagNumber}</td></tr>\n`;
    }

    printInfo += '<tr><td colspan="2"><canvas id="barcode"></canvas></td></tr>\n';

    const accuracyDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Accuracy', specifyMeterAccuracy]
    )?.Description;
    const approvalsDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Approvals', specifyMeterApprovals]
    )?.Description;
    const cableLengthDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['CableLength', specifyMeterCableLength]
    )?.Description;
    const communicationsDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Communications', specifyMeterCommunications]
    )?.Description;
    const diagAddPressTapDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['DiagAddPressTap', specifyMeterDiagAddPressTap]
    )?.Description;
    const diagDiffPressXMTRDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['DiagDiffPressXMTR', specifyMeterDiagDiffPressXMTR]
    )?.Description;
    const diffPressXMTRDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['DiffPressXMTR', specifyMeterDiffPressXMTR]
    )?.Description;
    const diffPressXMTRManiDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['DiffPressXMTRMani', specifyMeterDiffPressXMTRMani]
    )?.Description;
    const displayDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Display', specifyMeterDisplayOption]
    )?.Description;
    let electronicsMountingDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['ElectronicsMounting', specifyMeterElectronicsMounting]
    )?.Description;
    if (!electronicsMountingDescription) {
      let searchField = 'R()';
      if (workingVersion.VORTEK_NAME === utilities.INNOVA_NAME) {
        searchField = 'E4()';
      } else if (workingVersion.VORTEK_NAME === utilities.HEINRICH_NAME) {
        searchField = 'R';
      }
      electronicsMountingDescription = meterSpecify.getFeatureByMultiKey(
        ['Name', 'OrderCode'],
        ['ElectronicsMounting', searchField]
      )?.Description;
      if (!electronicsMountingDescription) {
        electronicsMountingDescription = meterSpecify.getFeatureByMultiKey(
          ['Name', 'OrderCode'],
          ['ElectronicsEnclosure', specifyMeterElectronicsMounting]
        )?.Description;
      }
    }
    const electrodeMaterialDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['ElectrodeMaterial', specifyMeterElectrodeMaterial]
    )?.Description;
    const energyMeterDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['EnergyMeter', specifyMeterEnergyMeter]
    )?.Description;
    const faceToFaceDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['FaceToFace', specifyMeterFaceToFace]
    )?.Description;
    const flangeDescription = meterSpecify.getFeatureByMultiKey(['Name', 'OrderCode'], ['Flange', specifyMeterFlange])?.Description;
    const groundingDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Grounding', specifyMeterGrounding]
    )?.Description;
    let inputPowerDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['InputPower', specifyMeterInputPower]
    )?.Description;
    if (!inputPowerDescription) {
      inputPowerDescription = meterSpecify.getFeatureByMultiKey(
        ['Name', 'OrderCode'],
        ['PowerSupply', specifyMeterInputPower]
      )?.Description;
    }
    const linerDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['LinerMaterial', specifyMeterLinerMaterial]
    )?.Description;
    let materialDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Material', specifyMeterPipeMaterial]
    )?.Description;
    if (!materialDescription) {
      materialDescription = meterSpecify.getFeatureByMultiKey(
        ['Name', 'OrderCode'],
        ['MeterBodyMaterial', specifyMeterPipeMaterial]
      )?.Description;
    }
    const outputDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Output', specifyMeterOutputSignal]
    )?.Description;
    const outputSignalDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['OutputSignal', specifyMeterOutputSignal]
    )?.Description;
    const parentModelNumberDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['ParentModelNumber', specifyMeterParentModelNumber]
    )?.Description;
    const probeLengthDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['ProbeLength', specifyMeterProbeLength]
    )?.Description;
    const processConnectionDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['ProcessConnection', specifyMeterProcessConnection]
    )?.Description;
    let processFluidDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['ProcessFluid', specifyMeterProcessFluid]
    )?.Description;
    if (!processFluidDescription) {
      processFluidDescription = meterSpecify.getFeatureByMultiKey(
        ['Name', 'OrderCode'],
        ['Model', specifyMeterProcessFluid]
      )?.Description;
    }
    const processTemperatureDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['ProcessTemperature', specifyMeterProcessTemperature]
    )?.Description;
    const transducerDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['Transducer', specifyMeterTransducer]
    )?.Description;
    const pressureTransducerDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['PressureTransducer', specifyMeterTransducer]
    )?.Description;
    let sizeDescription = meterSpecify.getFeatureByMultiKey(['Name', 'OrderCode'], ['Size', specifyMeterPipeSize])?.Description;
    if (!sizeDescription) {
      sizeDescription = meterSpecify.getFeatureByMultiKey(['Name', 'OrderCode'], ['FlowBody', specifyMeterPipeSize])?.Description;
    }
    const transducerBracketDescription = meterSpecify.getFeatureByMultiKey(
      ['Name', 'OrderCode'],
      ['TransducerBracket', specifyMeterTransducerBracket]
    )?.Description;

    if (meterType === workingVersion.UltrasonicS34Model) {
      printInfo += `<tr><td style="white-space: nowrap">${parentModelNumberDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessFluid} - ${processFluidDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterTransducer} - ${transducerDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterCableLength} - ${cableLengthDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessTemperature} - ${processTemperatureDescription}`;
    } else if (meterType === workingVersion.UltrasonicS36Model) {
      printInfo += `<tr><td style="white-space: nowrap">${parentModelNumberDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessFluid} - ${processFluidDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterTransducer} - ${transducerDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterCableLength} - ${cableLengthDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterCommunications} - ${communicationsDescription}`;
    } else if (meterType === workingVersion.UltrasonicU42Model || meterType === workingVersion.UltrasonicU43Model) {
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterParentModelNumber} - ${parentModelNumberDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterOutputSignal} - ${outputDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterTransducer} - ${transducerDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterCableLength} - ${cableLengthDescription}`;
    } else if (meterType === workingVersion.UltrasonicU44Model) {
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterParentModelNumber} - ${parentModelNumberDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterOutputSignal} - ${outputDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterTransducer} - ${transducerDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterTransducerBracket} - ${transducerBracketDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterCableLength} - ${cableLengthDescription}`;
    } else if (meterType === workingVersion.ElectromagneticProMModel) {
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterParentModelNumber} - ${parentModelNumberDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterProcessFluid} - ${processFluidDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterAccuracy} - ${accuracyDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterPipeSize} - ${sizeDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterPipeMaterial} - ${materialDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterProcessConnection} - ${processConnectionDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterElectronicsMounting} - ${electronicsMountingDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterInputPower} - ${inputPowerDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterCommunications} - ${communicationsDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterElectrodeMaterial} - ${electrodeMaterialDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterLinerMaterial} - ${linerDescription}</td></tr>\n`;
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterGrounding} - ${groundingDescription}`;
    } else {
      printInfo += `<tr><td style="white-space: nowrap">${specifyMeterParentModelNumber} - ${parentModelNumberDescription}`;
      if (workingVersion.VORTEK_NAME === utilities.ONICON_NAME) {
        if (
          specifyMeterParentModelNumber === workingVersion.InsertionModel ||
          specifyMeterParentModelNumber === workingVersion.TurbineModelShort
        ) {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessConnection} - ${processConnectionDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterElectronicsMounting} - ${electronicsMountingDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessFluid} - ${processFluidDescription}`;
          if (meterType === 'turbine') {
            printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${calcTurbineMeter.getOniconTurbineRotorModelCode(
              selectedMeter.name
            )} - ${calcTurbineMeter.getOniconTurbineRotorText(selectedMeter.name)}`;
          }
        } else {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeSize} - ${sizeDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterFlange} - ${flangeDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterElectronicsMounting} - ${electronicsMountingDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessFluid} - ${processFluidDescription}`;
        }

        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterInputPower} - ${inputPowerDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterOutputSignal} - ${outputSignalDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessTemperature} - ${processTemperatureDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterEnergyMeter} - ${energyMeterDescription}`;
      } else if (workingVersion.VORTEK_NAME === utilities.ARMSTRONG_NAME) {
        if (processConnectionDescription) {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessConnection} - ${processConnectionDescription}`;
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProbeLength} - ${probeLengthDescription}`;
        } else if (sizeDescription) {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterFlange} - ${flangeDescription}`;
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeSize} - ${sizeDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeMaterial} - ${materialDescription}`;
        }
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterElectronicsMounting} - ${electronicsMountingDescription}`;
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessFluid} - ${processFluidDescription}`;
      } else {
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessFluid} - ${processFluidDescription}`;
        if (workingVersion.VORTEK_NAME === utilities.INNOVA_NAME && reducingVortex) {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">R - Reducing`;
        }

        if (sizeDescription) {
          if (workingVersion.VORTEK_NAME === utilities.HEINRICH_NAME) {
            printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeSize} - ${sizeDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterFlange} - ${flangeDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeMaterial} - ${materialDescription}`;
          } else if (workingVersion.VORTEK_NAME === utilities.INNOVA_NAME && reducingVortex) {
            printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterFlange} - ${flangeDescription}\n</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeSize} - ${sizeDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeMaterial} - ${materialDescription}`;
          } else {
            printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeSize} - ${sizeDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterPipeMaterial} - ${materialDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterFlange} - ${flangeDescription}`;
          }
        }

        if (probeLengthDescription) {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProbeLength} - ${probeLengthDescription}`;
        }

        let eslen = 1;
        if (workingVersion.VORTEK_NAME === utilities.AZBIL_NAME || workingVersion.VORTEK_NAME === utilities.INNOVA_NAME) {
          eslen = 2;
        }

        if (workingVersion.VORTEK_NAME === utilities.PANAFLOW_NAME || workingVersion.VORTEK_NAME === utilities.SPIRAX_NAME) {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterElectronicsMounting} - ${electronicsMountingDescription}`;
        } else {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterElectronicsMounting.substring(0, eslen)}`;
          if (remoteCableLength) {
            printInfo += remoteCableLength;
          }

          printInfo += ` - ${electronicsMountingDescription}`;
        }
      }

      if (specifyMeterDisplayOption) {
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterDisplayOption} - ${displayDescription}`;
      }

      if (workingVersion.VORTEK_NAME !== utilities.ONICON_NAME) {
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterInputPower} - ${inputPowerDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterOutputSignal} - ${outputSignalDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessTemperature} - ${processTemperatureDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterTransducer} - ${pressureTransducerDescription}`;
      }

      if (workingVersion.VORTEK_NAME !== utilities.ARMSTRONG_NAME && processConnectionDescription) {
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterProcessConnection} - ${processConnectionDescription}`;
      }

      if (meterType === 'vorcone' || meterType === 'reduce_vorcone') {
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterDiffPressXMTR} - ${diffPressXMTRDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterDiffPressXMTRMani} - ${diffPressXMTRManiDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterDiagDiffPressXMTR} - ${diagDiffPressXMTRDescription}</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterDiagAddPressTap} - ${diagAddPressTapDescription}</td></tr>\n<tr><td style="white-space: nowrap">${selectedMeter.beta} - Cone Meter Beta Ratio`;
      }

      if (meterType !== 'vorcone' && meterType !== 'reduce_vorcone' && workingVersion.VORTEK_NAME === utilities.SPIRAX_NAME) {
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterApprovals} - ${approvalsDescription}`;

        if (
          specifyMeterParentModelNumber === workingVersion.InlineModel ||
          specifyMeterParentModelNumber === workingVersion.InlineReduceModel ||
          specifyMeterParentModelNumber === workingVersion.InlineNonReduceModel
        ) {
          printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${specifyMeterFaceToFace} - ${faceToFaceDescription}`;
        }
      }

      if (meterType === 'turbine') {
        printInfo += `</td></tr>\n<tr><td style="white-space: nowrap">${selectedMeter.name} - Rotor`;
      }
    }

    printInfo +=
      '</td></tr>\n<tr><td>&nbsp;</td></tr>\n</table>\n<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n' +
      '<tr><td style="width: 20%;">Accessory</td><td style="width: 60%;">Description</td><td style="width: 20%;">Quantity</td></tr>\n';

    if (accessories) {
      accessories.forEach((accessory) => {
        printInfo += '<tr>';
        printInfo += `<td style="white-space: nowrap">${accessory.code}</td>`;
        printInfo += `<td style="white-space: nowrap">${accessory.description}</td>`;
        printInfo += `<td style="white-space: nowrap">${accessory.quantity}</td>`;
        printInfo += '</tr>\n';
      });

      printInfo += '</table>\n';
    } else {
      printInfo += '<tr><td colspan="3">None</td></tr>\n</table>\n';
    }

    if (
      specifyMeterParentModelNumber === workingVersion.InsertionModel ||
      meterType === workingVersion.UltrasonicS34Model ||
      meterType === workingVersion.UltrasonicS36Model ||
      meterType === workingVersion.UltrasonicU42Model ||
      meterType === workingVersion.UltrasonicU44Model
    ) {
      printInfo += '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n';
      printInfo += '<tr><td>';
      if (
        meterType === workingVersion.UltrasonicS34Model ||
        meterType === workingVersion.UltrasonicS36Model ||
        meterType === workingVersion.UltrasonicU42Model ||
        meterType === workingVersion.UltrasonicU43Model
      ) {
        printInfo += 'Ultrasonic ';
      } else if (meterType === workingVersion.UltrasonicU44Model) {
        printInfo += 'Portable Ultrasonic ';
      } else {
        printInfo += 'Insertion ';
      }
      printInfo += `meter sizing based on a ${pipeId}${pipeUnit === 'in' ? '.' : ''} I.D. pipe.`;
      printInfo += '</td>\n';

      if (
        meterType === workingVersion.UltrasonicS34Model ||
        meterType === workingVersion.UltrasonicS36Model ||
        meterType === workingVersion.UltrasonicU42Model ||
        meterType === workingVersion.UltrasonicU44Model
      ) {
        let soundspeedItem;

        if (fluidType === 'Liquid' || fluidType === 'Water') {
          const result = api.readOneSync(true, {
            requestData: {
              client: VORTEK_NAME,
              username,
              collection: 'soundspeed',
            },
          });

          if (result.status !== 'fail') {
            soundspeedItem = utilities.findInArrayByFieldName(result.data.data, 'fluid', fluid);
            if (soundspeedItem) {
              printInfo += '<td>';
              printInfo += `Sound speed = ${soundspeedItem.ssfts} ft/s (${soundspeedItem.ssms}m/s) Delta V = ${soundspeedItem.dftsf} ft/s/F (${soundspeedItem.dmsc} m/s/C)`;
              printInfo += '</td>\n';
            }
          }
        } else if (fluidType === 'Other Liquid') {
          soundspeedItem = otherLiquid;
          if (soundspeedItem) {
            printInfo += '<td>';
            printInfo += `Sound speed = ${soundspeedItem.liquid_sound_speed ? soundspeedItem.liquid_sound_speed : 'Not Entered'} ${
              soundspeedItem.liquid_select_3 ? soundspeedItem.liquid_select_3 : ''
            }`;
            printInfo += '</td>\n';
          }
        }
      }

      printInfo += '</tr></table>\n';
    }

    if (meterType === 'vorcone' || meterType === 'reduce_vorcone') {
      printInfo +=
        `${
          '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">' +
          '<tr><td colspan="3">&nbsp;</td></tr>' +
          '<tr><td style="width: 30%; white-space: nowrap">Sizing Test</td><td style="width: 20%; white-space: nowrap">Results</td><td style="width: 50%; white-space: nowrap">Warnings</td></tr>' +
          '<tr><td style="white-space: nowrap">Meter Minimum Flow</td><td style="white-space: nowrap">'
        }${selectedMeter.minflow}</td><td style="white-space: nowrap">${selectedMeter.minflowwarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Meter Maximum Flow</td><td style="white-space: nowrap">${selectedMeter.maxflow}</td><td style="white-space: nowrap">${selectedMeter.maxflowwarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Meter Minimum DP</td><td style="white-space: nowrap">${selectedMeter.mindp} INWC</td><td style="white-space: nowrap">${selectedMeter.mindpwarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Meter Maximum DP</td><td style="white-space: nowrap">${selectedMeter.maxdp} INWC</td><td style="white-space: nowrap">${selectedMeter.maxdpwarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Head Loss @ ${max_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.maxpressloss}</td><td style="white-space: nowrap">${selectedMeter.headlosswarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Min Reynolds</td><td style="white-space: nowrap">${selectedMeter.minrey}</td><td style="white-space: nowrap">${selectedMeter.minreynwarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Max Reynolds</td><td style="white-space: nowrap">${selectedMeter.maxrey}</td><td style="white-space: nowrap">${selectedMeter.maxreynwarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Min Velocity @ ${min_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.minvel}</td><td style="white-space: nowrap">${selectedMeter.minvelowarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Nom Velocity @ ${nom_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.nomvel}</td><td style="white-space: nowrap">${selectedMeter.nomvelowarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Max Velocity @ ${max_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.maxvel}</td><td style="white-space: nowrap">${selectedMeter.maxvelowarn}</td></tr>` +
        `<tr><td style="white-space: nowrap">Usable Meter</td><td style="white-space: nowrap">${selectedMeter.usable}</td><td>&nbsp;</td></tr></table>`;
    } else {
      printInfo +=
        `${
          '<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n' +
          '<tr><td colspan="3">&nbsp;</td></tr>\n' +
          '<tr><td style="width: 30%; white-space: nowrap">Sizing Test</td><td style="width: 20%; white-space: nowrap">Results</td><td style="width: 50%; white-space: nowrap">Warnings</td></tr>\n' +
          '<tr><td style="white-space: nowrap">Meter Minimum Flow</td><td style="white-space: nowrap">'
        }${selectedMeter.minflow}</td><td style="white-space: nowrap">${selectedMeter.minflowwarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Meter Maximum Flow</td><td style="white-space: nowrap">${selectedMeter.maxflow}</td><td style="white-space: nowrap">${selectedMeter.maxflowwarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Pressure Drop @ ${max_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.pressdrop}</td><td style="white-space: nowrap">${selectedMeter.pressdropwarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Min Reynolds</td><td style="white-space: nowrap">${selectedMeter.minrey}</td><td style="white-space: nowrap">${selectedMeter.minreynwarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Max Reynolds</td><td style="white-space: nowrap">${selectedMeter.maxrey}</td><td style="white-space: nowrap">${selectedMeter.maxreynwarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Min Velocity @ ${min_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.minvel}</td><td style="white-space: nowrap">${selectedMeter.minvelowarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Nom Velocity @ ${nom_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.nomvel}</td><td style="white-space: nowrap">${selectedMeter.nomvelowarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Max Velocity @ ${max_flow}${flow_unit}/${flow_time}</td><td style="white-space: nowrap">${selectedMeter.maxvel}</td><td style="white-space: nowrap">${selectedMeter.maxvelowarn}</td></tr>\n` +
        `<tr><td style="white-space: nowrap">Usable Meter</td><td style="white-space: nowrap">${selectedMeter.usable}</td><td>&nbsp;</td></tr>\n</table>\n`;
    }
  }

  if (warning) {
    printInfo += `<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr><td>&nbsp;</td></tr>\n<tr><td>Validation Warnings</td></tr>\n<tr><td>${warning}</td></tr>\n</table>\n`;
  }

  if (specialNotes) {
    printInfo += `<table style="width: 100%; border-width: 0; border-spacing: 0; padding: 0;">\n<tr><td>&nbsp;</td></tr>\n<tr><td>Special Notes</td></tr>\n<tr><td>${specialNotes}</td></tr>\n</table>\n`;
  }

  printInfo += '</body>\n</html>\n';

  return (
    <iframe
      srcDoc="<!DOCTYPE html>"
      title="print_frame"
      style={{ position: 'absolute', top: 0, left: -2000, zIndex: 20000, width: '1024px', height: '768px', border: 0 }}
      onLoad={(e) => {
        const iframe = e.currentTarget as HTMLIFrameElement;
        if (iframe?.contentDocument) {
          const { onAfterPrint } = props;
          iframe.contentDocument.body.onafterprint = onAfterPrint;
          iframe.contentDocument.body.innerHTML = printInfo;
          iframe.contentWindow?.focus();
          qrenc.fn.doqr(
            iframe.contentDocument.getElementById('barcode'),
            200,
            200,
            2,
            `Meter Code:${finalModelCode}$Tag Number:${TagNumber || 'N/A'}`
          );
        }

        // Pre-57 Firefox has this property, no other browsers do
        if (typeof navigator.mozApps !== 'undefined') {
          const ffWindow = window.open('', '', '');
          if (ffWindow) {
            ffWindow.document.write(printInfo);
            ffWindow.document.close();
            ffWindow.print();
            ffWindow.close();
          }
          // IE 8 and 9 have this special command to print, no other browsers do
        } else if (isIE89) {
          iframe?.contentDocument?.execCommand('print', false);
          // Chrome, Safari, MS 10, 11, Edge, Opera, and Firefox 57+ don't need anything special, yay!
        } else {
          iframe?.contentWindow?.print();
        }
      }}
    />
  );
};

export default PrintMeterInfo;

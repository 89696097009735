import { AnyAction } from 'redux';
import initialState, { InitialState } from './initialState';
import Types from '../actions/types';
import CustomerListItem from '../../types/customerListItem';

// TODO: if id is passed only replace the one item in the JSON

const reducer = (state: InitialState = initialState, action: AnyAction = { type: '' }): InitialState => {
  switch (action.type) {
    case Types.SET_ACCESSORIES:
      return {
        ...state,
        accessories: action.data,
      };

    case Types.SET_ALERTED_NEGATIVE:
      return {
        ...state,
        alertedNegative: action.data,
      };

    case Types.SET_APP_MODE:
      return {
        ...state,
        appMode: action.data,
      };

    case Types.SET_APP_NAME:
      return {
        ...state,
        appName: action.data,
      };

    case Types.SET_CLIENT:
      return {
        ...state,
        client: action.data,
      };

    case Types.SET_CLIENT_PARAM:
      return {
        ...state,
        clientParam: action.data,
      };

    case Types.SET_CUSTOMER:
      return {
        ...state,
        customer: { ...(action.data as CustomerListItem) },
      };

    case Types.SET_DATABASE:
      return {
        ...state,
        database: action.data,
      };

    case Types.SET_DEFAULTS:
      return {
        ...state,
        defaults: action.data,
      };

    case Types.SET_WORKING_DEFAULTS:
      return {
        ...state,
        workingDefaults: action.data,
      };

    case Types.SET_DEFAULT_NORMAL_CONDITIONS:
      return {
        ...state,
        defaults: {
          ...state.defaults,
          defaultProcessConditions: {
            ...state.defaults.defaultProcessConditions,
            defaultNmlConditions: action.data,
          },
        },
      };

    case Types.SET_DEFAULT_STANDARD_CONDITIONS:
      return {
        ...state,
        defaults: {
          ...state.defaults,
          defaultProcessConditions: {
            ...state.defaults.defaultProcessConditions,
            defaultStdConditions: action.data,
          },
        },
      };

    case Types.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.data,
      };

    case Types.SET_FLUID:
      return {
        ...state,
        fluid: action.data,
      };

    case Types.SET_FLUID_TYPE:
      return {
        ...state,
        fluidType: action.data,
      };

    case Types.SET_FORCE_LOGIN:
      return {
        ...state,
        forceLogin: action.data,
      };

    case Types.SET_FORCE_REMOTE:
      return {
        ...state,
        forceRemote: action.data,
      };

    case Types.SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.data,
      };

    case Types.SET_IS_CHROME:
      return {
        ...state,
        isChrome: action.data,
      };

    case Types.SET_IS_IE89:
      return {
        ...state,
        isIE89: action.data,
      };

    case Types.SET_IS_IE10OR11OREDGE:
      return {
        ...state,
        isIE10or11orEdge: action.data,
      };

    case Types.SET_IS_WIN_SAFARI:
      return {
        ...state,
        isWinSafari: action.data,
      };

    case Types.SET_LOC:
      return {
        ...state,
        loc: action.data,
      };

    case Types.SET_METER_DATA:
      return {
        ...state,
        meterData: action.data,
      };

    case Types.SET_METER_INDEX:
      return {
        ...state,
        meterIndex: action.data,
      };

    case Types.SET_METER_TYPE:
      return {
        ...state,
        meterType: action.data,
      };

    case Types.SET_MODEL_CODE:
      return {
        ...state,
        modelCode: action.data,
      };

    case Types.SET_NORMAL_CONDITIONS:
      return {
        ...state,
        defaults: {
          ...state.defaults,
          defaultProcessConditions: {
            ...state.defaults.defaultProcessConditions,
            normalConditions: action.data,
          },
        },
      };

    case Types.SET_ONLINE_TOKEN:
      return {
        ...state,
        onlineToken: action.data,
      };

    case Types.SET_OTHER_GAS:
      return {
        ...state,
        otherGas: action.data,
      };

    case Types.SET_OTHER_LIQUID:
      return {
        ...state,
        otherLiquid: action.data,
      };

    case Types.SET_PATHNAME:
      return {
        ...state,
        pathName: action.data,
      };

    case Types.SET_PIPE_INSIDE_DIAMETER:
      return {
        ...state,
        pipeInsideDiameter: action.data,
      };

    case Types.SET_PIPE_LINER_MATERIAL:
      return {
        ...state,
        pipeLinerMaterial: action.data,
      };

    case Types.SET_PIPE_MATERIAL:
      return {
        ...state,
        pipeMaterial: action.data,
      };

    case Types.SET_PIPE_OUTSIDE_DIAMETER:
      return {
        ...state,
        pipeOutsideDiameter: action.data,
      };

    case Types.SET_PIPE_REDUCE_INSIDE_DIAMETER:
      return {
        ...state,
        pipeReduceInsideDiameter: action.data,
      };

    case Types.SET_PIPE_REDUCE_OUTSIDE_DIAMETER:
      return {
        ...state,
        pipeReduceOutsideDiameter: action.data,
      };

    case Types.SET_PIPE_SCHEDULE:
      return {
        ...state,
        pipeSchedule: action.data,
      };

    case Types.SET_PIPE_TYPE:
      return {
        ...state,
        pipeType: action.data,
      };

    case Types.SET_PIPE_UNIT:
      return {
        ...state,
        pipeUnit: action.data,
      };

    case Types.SET_SELECTED_PIPE_INSIDE_DIAMETER:
      return {
        ...state,
        selectedPipeInsideDiameter: action.data,
      };

    case Types.SET_SELECTED_PIPE_OUTSIDE_DIAMETER:
      return {
        ...state,
        selectedPipeOutsideDiameter: action.data,
      };

    case Types.SET_SOUND_SPEED:
      return {
        ...state,
        soundSpeed: action.data,
      };

    case Types.SET_SHOW_SPECIFY_METER:
      return {
        ...state,
        showSpecifyMeter: action.data,
      };

    case Types.SET_SPECIFY_METER_ACCURACY:
      return {
        ...state,
        specifyMeterAccuracy: action.data,
      };

    case Types.SET_SPECIFY_METER_ELECTRODE_MATERIAL:
      return {
        ...state,
        specifyMeterElectrodeMaterial: action.data,
      };

    case Types.SET_SPECIFY_METER_GROUNDING:
      return {
        ...state,
        specifyMeterGrounding: action.data,
      };

    case Types.SET_SPECIFY_METER_TAG_NUMBER:
      return {
        ...state,
        specifyMeterTagNumber: action.data,
      };

    case Types.SET_SPECIFY_METER_PARENT_MODEL_NUMBER:
      return {
        ...state,
        specifyMeterParentModelNumber: action.data,
      };

    case Types.SET_SPECIFY_METER_PROCESS_FLUID:
      return {
        ...state,
        specifyMeterProcessFluid: action.data,
      };

    case Types.SET_SPECIFY_METER_PIPE_SIZE:
      return {
        ...state,
        specifyMeterPipeSize: action.data,
      };

    case Types.SET_SPECIFY_METER_LINER_MATERIAL:
      return {
        ...state,
        specifyMeterLinerMaterial: action.data,
      };

    case Types.SET_SPECIFY_METER_PIPE_MATERIAL:
      return {
        ...state,
        specifyMeterPipeMaterial: action.data,
      };

    case Types.SET_SPECIFY_METER_FLANGE:
      return {
        ...state,
        specifyMeterFlange: action.data,
      };

    case Types.SET_SPECIFY_METER_PROBE_LENGTH:
      return {
        ...state,
        specifyMeterProbeLength: action.data,
      };

    case Types.SET_SPECIFY_METER_ELECTRONICS_MOUNTING:
      return {
        ...state,
        specifyMeterElectronicsMounting: action.data,
      };

    case Types.SET_SPECIFY_METER_DISPLAY_OPTION:
      return {
        ...state,
        specifyMeterDisplayOption: action.data,
      };

    case Types.SET_SPECIFY_METER_INPUT_POWER:
      return {
        ...state,
        specifyMeterInputPower: action.data,
      };

    case Types.SET_SPECIFY_METER_OUTPUT_SIGNAL:
      return {
        ...state,
        specifyMeterOutputSignal: action.data,
      };

    case Types.SET_SPECIFY_METER_PROCESS_TEMPERATURE:
      return {
        ...state,
        specifyMeterProcessTemperature: action.data,
      };

    case Types.SET_SPECIFY_METER_TRANSDUCER:
      return {
        ...state,
        specifyMeterTransducer: action.data,
      };

    case Types.SET_SPECIFY_METER_PROCESS_CONNECTION:
      return {
        ...state,
        specifyMeterProcessConnection: action.data,
      };

    case Types.SET_SPECIFY_METER_ROTOR:
      return {
        ...state,
        specifyMeterRotor: action.data,
      };

    case Types.SET_SPECIFY_METER_DIFF_PRESS_XMTR:
      return {
        ...state,
        specifyMeterDiffPressXMTR: action.data,
      };

    case Types.SET_SPECIFY_METER_DIFF_PRESS_XMTR_MANI:
      return {
        ...state,
        specifyMeterDiffPressXMTRMani: action.data,
      };

    case Types.SET_SPECIFY_METER_DIAG_DIFF_PRESS_XMTR:
      return {
        ...state,
        specifyMeterDiagDiffPressXMTR: action.data,
      };

    case Types.SET_SPECIFY_METER_DIAG_ADD_PRESS_TAP:
      return {
        ...state,
        specifyMeterDiagAddPressTap: action.data,
      };

    case Types.SET_SPECIFY_METER_CABLE_LENGTH:
      return {
        ...state,
        specifyMeterCableLength: action.data,
      };

    case Types.SET_SPECIFY_METER_COMMUNICATIONS:
      return {
        ...state,
        specifyMeterCommunications: action.data,
      };

    case Types.SET_SPECIFY_METER_TRANSDUCER_BRACKET:
      return {
        ...state,
        specifyMeterTransducerBracket: action.data,
      };

    case Types.SET_SPECIFY_METER_TEMP_PRESS_COMP:
      return {
        ...state,
        specifyMeterTempPressComp: action.data,
      };

    case Types.SET_SPECIFY_METER_ENERGY_METER:
      return {
        ...state,
        specifyMeterEnergyMeter: action.data,
      };

    case Types.SET_SPECIFY_METER_APPROVALS:
      return {
        ...state,
        specifyMeterApprovals: action.data,
      };

    case Types.SET_SPECIFY_METER_FACE_TO_FACE:
      return {
        ...state,
        specifyMeterFaceToFace: action.data,
      };

    case Types.SET_SPECIFY_METER_SPECIAL_NOTES:
      return {
        ...state,
        specifyMeterSpecialNotes: action.data,
      };

    case Types.SET_SPECIFY_METER_WARNINGS:
      return {
        ...state,
        specifyMeterWarnings: action.data,
      };

    case Types.SET_STANDARD_CONDITIONS:
      return {
        ...state,
        defaults: {
          ...state.defaults,
          defaultProcessConditions: {
            ...state.defaults.defaultProcessConditions,
            standardConditions: action.data,
          },
        },
      };

    case Types.SET_SYSTEM_MESSAGE:
      return {
        ...state,
        systemMessage: action.data,
      };

    case Types.SET_REDUCING_VORCONE:
      return {
        ...state,
        reducingVorcone: action.data,
      };

    case Types.SET_REDUCING_VORTEX:
      return {
        ...state,
        reducingVortex: action.data,
      };

    case Types.SET_REMOTE_CABLE_LENGTH:
      return {
        ...state,
        remoteCableLength: action.data,
      };

    case Types.SET_TURBINE_ACTIVE:
      return {
        ...state,
        turbineActive: action.data,
      };

    case Types.SET_USERNAME:
      return {
        ...state,
        username: action.data,
      };

    case Types.SET_VORCONE_ACTIVE:
      return {
        ...state,
        vorconeActive: action.data,
      };

    case Types.SET_WARNING_MESSAGE:
      return {
        ...state,
        warningMessage: action.data,
      };

    case Types.SET_PROCESS_CONDITIONS:
      return {
        ...state,
        processConditions: action.data,
      };

    case Types.SET_FLOW_MIN:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          flowMin: action.data,
        },
      };

    case Types.SET_FLOW_NOM:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          flowNom: action.data,
        },
      };

    case Types.SET_FLOW_MAX:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          flowMax: action.data,
        },
      };

    case Types.SET_FLOW_UNIT:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          flowUnit: action.data,
        },
      };

    case Types.SET_FLOW_TIME:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          flowTime: action.data,
        },
      };

    case Types.SET_TEMPERATURE_MIN:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          temperatureMin: action.data,
        },
      };

    case Types.SET_TEMPERATURE_NOM:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          temperatureNom: action.data,
        },
      };

    case Types.SET_TEMPERATURE_MAX:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          temperatureMax: action.data,
        },
      };

    case Types.SET_TEMPERATURE_UNIT:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          temperatureUnit: action.data,
        },
      };

    case Types.SET_PRESSURE_MIN:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          pressureMin: action.data,
        },
      };

    case Types.SET_PRESSURE_NOM:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          pressureNom: action.data,
        },
      };

    case Types.SET_PRESSURE_MAX:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          pressureMax: action.data,
        },
      };

    case Types.SET_PRESSURE_PREFIX:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          pressurePrefix: action.data,
        },
      };

    case Types.SET_PRESSURE_COEFFICIENT:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          pressureCoefficient: action.data,
        },
      };

    case Types.SET_DENSITY_MIN:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          densityMin: action.data,
        },
      };

    case Types.SET_DENSITY_NOM:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          densityNom: action.data,
        },
      };

    case Types.SET_DENSITY_MAX:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          densityMax: action.data,
        },
      };

    case Types.SET_DENSITY_UNIT:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          densityUnit: action.data,
        },
      };

    case Types.SET_VISCOSITY_MIN:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          viscosityMin: action.data,
        },
      };

    case Types.SET_VISCOSITY_NOM:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          viscosityNom: action.data,
        },
      };

    case Types.SET_VISCOSITY_MAX:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          viscosityMax: action.data,
        },
      };

    case Types.SET_VISCOSITY_UNIT:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          viscosityUnit: action.data,
        },
      };

    case Types.SET_VELOCITY_MIN:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          velocityMin: action.data,
        },
      };

    case Types.SET_VELOCITY_NOM:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          velocityNom: action.data,
        },
      };

    case Types.SET_VELOCITY_MAX:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          velocityMax: action.data,
        },
      };

    case Types.SET_VELOCITY_UNIT:
      return {
        ...state,
        processConditions: {
          ...state.processConditions,
          velocityUnit: action.data,
        },
      };

    case Types.SET_TABLE_ANSI_PIPE_SIZE:
      return {
        ...state,
        db: {
          ...state.db,
          ansiPipeSizes: action.data,
        },
      };

    case Types.SET_TABLE_APPLICATION:
      return {
        ...state,
        db: {
          ...state.db,
          application: action.data,
        },
      };

    case Types.SET_TABLE_CUSTOMER:
      return {
        ...state,
        db: {
          ...state.db,
          customer: action.data,
        },
      };

    case Types.SET_TABLE_DEFAULT:
      return {
        ...state,
        db: {
          ...state.db,
          defaults: action.data,
        },
      };

    case Types.SET_TABLE_DENSITY:
      return {
        ...state,
        db: {
          ...state.db,
          density: action.data,
        },
      };

    case Types.SET_TABLE_DIN_PIPE_SIZE:
      return {
        ...state,
        db: {
          ...state.db,
          dinPipeSizes: action.data,
        },
      };

    case Types.SET_TABLE_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          feature: action.data,
        },
      };

    case Types.SET_TABLE_FLANGE_TEST_ASME:
      return {
        ...state,
        db: {
          ...state.db,
          flangeTestsAsme: action.data,
        },
      };

    case Types.SET_TABLE_FLANGE_TEST_DIN:
      return {
        ...state,
        db: {
          ...state.db,
          flangeTestsDin: action.data,
        },
      };

    case Types.SET_TABLE_FLANGE_TEST_JIS:
      return {
        ...state,
        db: {
          ...state.db,
          flangeTestsJis: action.data,
        },
      };

    case Types.SET_TABLE_FLOW:
      return {
        ...state,
        db: {
          ...state.db,
          flow: action.data,
        },
      };

    case Types.SET_TABLE_FLOW_WORKING:
      return {
        ...state,
        flowTableWorking: action.data,
      };

    case Types.SET_TABLE_DENSITY_WORKING:
      return {
        ...state,
        densityTableWorking: action.data,
      };

    case Types.SET_TABLE_VISCOSITY_WORKING:
      return {
        ...state,
        viscosityTableWorking: action.data,
      };

    case Types.SET_TABLE_LENGTH_WORKING:
      return {
        ...state,
        lengthTableWorking: action.data,
      };

    case Types.SET_TABLE_TIME_WORKING:
      return {
        ...state,
        timeTableWorking: action.data,
      };

    case Types.SET_TABLE_TEMPERATURE_WORKING:
      return {
        ...state,
        temperatureTableWorking: action.data,
      };

    case Types.SET_TABLE_PRESSURE_WORKING:
      return {
        ...state,
        pressureTableWorking: action.data,
      };

    case Types.SET_TABLE_FLUID:
      return {
        ...state,
        db: {
          ...state.db,
          fluid: action.data,
        },
      };

    case Types.SET_TABLE_GAS:
      return {
        ...state,
        db: {
          ...state.db,
          gas: action.data,
        },
      };

    case Types.SET_TABLE_IMAGE:
      return {
        ...state,
        db: {
          ...state.db,
          image: action.data,
        },
      };

    case Types.SET_TABLE_INLINE:
      return {
        ...state,
        db: {
          ...state.db,
          inlines: action.data,
        },
      };

    case Types.SET_TABLE_INLINE_PRO_M:
      return {
        ...state,
        db: {
          ...state.db,
          inlinesProM: action.data,
        },
      };

    case Types.SET_TABLE_JIS_PIPE_SCHEDULE:
      return {
        ...state,
        db: {
          ...state.db,
          jisPipeSchedules: action.data,
        },
      };

    case Types.SET_TABLE_JIS_PIPE_SIZE:
      return {
        ...state,
        db: {
          ...state.db,
          jisPipeSizes: action.data,
        },
      };

    case Types.SET_TABLE_LENGTH:
      return {
        ...state,
        db: {
          ...state.db,
          length: action.data,
        },
      };

    case Types.SET_TABLE_LINER_MATERIAL:
      return {
        ...state,
        db: {
          ...state.db,
          linerMaterial: action.data,
        },
      };

    case Types.SET_TABLE_LIQUID:
      return {
        ...state,
        db: {
          ...state.db,
          liquid: action.data,
        },
      };

    case Types.SET_TABLE_M22_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          m22Feature: action.data,
        },
      };

    case Types.SET_TABLE_M23_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          m23Feature: action.data,
        },
      };

    case Types.SET_TABLE_M24_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          m24Feature: action.data,
        },
      };

    case Types.SET_TABLE_M24R_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          m24rFeature: action.data,
        },
      };

    case Types.SET_TABLE_MVC_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          mvcFeature: action.data,
        },
      };

    case Types.SET_TABLE_MVCR_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          mvcrFeature: action.data,
        },
      };

    case Types.SET_TABLE_OTHER_GAS:
      return {
        ...state,
        db: {
          ...state.db,
          othergas: action.data,
        },
      };

    case Types.SET_TABLE_OTHER_LIQUID:
      return {
        ...state,
        db: {
          ...state.db,
          otherliquid: action.data,
        },
      };

    case Types.SET_TABLE_PIPE_DIN:
      return {
        ...state,
        db: {
          ...state.db,
          pipesdin: action.data,
        },
      };

    case Types.SET_TABLE_PIPE_MATERIAL:
      return {
        ...state,
        db: {
          ...state.db,
          pipeMaterial: action.data,
        },
      };

    case Types.SET_TABLE_PIPE_SCHEDULE:
      return {
        ...state,
        db: {
          ...state.db,
          pipeSchedules: action.data,
        },
      };

    case Types.SET_TABLE_PRESSURE:
      return {
        ...state,
        db: {
          ...state.db,
          pressure: action.data,
        },
      };

    case Types.SET_TABLE_PRINT_HEADER:
      return {
        ...state,
        db: {
          ...state.db,
          printheader: action.data,
        },
      };

    case Types.SET_TABLE_S34_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          s34Feature: action.data,
        },
      };

    case Types.SET_TABLE_S36_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          s36Feature: action.data,
        },
      };

    case Types.SET_TABLE_SOUND_SPEED:
      return {
        ...state,
        db: {
          ...state.db,
          soundspeed: action.data,
        },
      };

    case Types.SET_TABLE_TEMPERATURE:
      return {
        ...state,
        db: {
          ...state.db,
          temperature: action.data,
        },
      };

    case Types.SET_TABLE_TIME:
      return {
        ...state,
        db: {
          ...state.db,
          time: action.data,
        },
      };

    case Types.SET_TABLE_TURBINE_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          turbineFeature: action.data,
        },
      };

    case Types.SET_TABLE_U42_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          u42Feature: action.data,
        },
      };

    case Types.SET_TABLE_U43_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          u43Feature: action.data,
        },
      };

    case Types.SET_TABLE_U44_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          u44Feature: action.data,
        },
      };

    case Types.SET_TABLE_PROM_FEATURE:
      return {
        ...state,
        db: {
          ...state.db,
          proMFeature: action.data,
        },
      };

    case Types.SET_TABLE_VISCOSITY:
      return {
        ...state,
        db: {
          ...state.db,
          viscosity: action.data,
        },
      };

    case Types.SET_TABLE_VUSER:
      return {
        ...state,
        db: {
          ...state.db,
          vusers: action.data,
        },
      };

    default:
      break;
  }
  return state;
};

export default reducer;

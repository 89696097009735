import SizingGlobals from '../../types/sizingGlobals';

/* Sierra is Innova */
const InnovaSizingGlobals: SizingGlobals = {
  goodUsableColor: '#003300',
  reducing: true,
  turbine: false,
  vorcone: false,
  ultrasonic: false,
  s36: false,
  u44: false,
  proM: false,
  VorconeModel: 'MVC VorCone',
  VorconeReduceModel: 'MVCR Reducing VorCone',
  VorconeModelStr: 'MVC VorCone',
  VorconeReduceModelStr: 'MVCR Reducing VorCone',
  InlineReduceModel: '240S',
  InlineNonReduceModel: '242',
  InlineModel: '240S',
  InlineModelPrev: '240',
  InsertionModel: '241S',
  InsertionModelPrev: '241',
  TurbineModel: '',
  TurbineModelShort: '',
  InlineModelStr: 'Innova Model 240S',
  InlineNonReduceModelStr: 'Innova Model 242',
  InlineReduceModelStr: 'Innova Model 240S Reducing',
  InsertionModelStr: 'Innova Model 241S',
  UltrasonicS34Model: 'S34',
  UltrasonicS34ModelStr: 'S34 Ultrasonic',
  UltrasonicS36Model: 'S36',
  UltrasonicS36ModelStr: 'S36 Ultrasonic',
  UltrasonicU42Model: 'U42',
  UltrasonicU42ModelStr: 'U42 Ultrasonic',
  UltrasonicU43Model: 'U43',
  UltrasonicU43ModelStr: 'U43 Ultrasonic',
  UltrasonicU44Model: 'U44',
  UltrasonicU44ModelStr: 'U44 Ultrasonic',
  ElectromagneticProMModel: 'Pro-M',
  ElectromagneticProMModelStr: 'Electromagnetic Flow Meter',
  ProductLineStr: 'Innova',
  HelpMenuStr: 'Innova Sizer Help...',
  STANDARD_TEMP: 'ST',
  HIGH_TEMP: 'HT',
  WAFER: 'W',
  ANSI_150: 'F',
  ANSI_300: 'G',
  ANSI_600: 'H',
  ANSI_900: '',
  DN_PN16: 'FD',
  DN_PN40: 'GD',
  DN_PN64: 'HD',
  DN_PN100: '100',
  JIS_10K_J1: '',
  JIS_16K_K: '',
  JIS_20K_J2: '',
  JIS_30K_J3: '',
  JIS_40K_J4: '',
  JIS_10K_Y1: '',
  JIS_20K_Y2: '',
  LocalStr: 'E2',
  RemoteStr: 'E4()',
  RemoteStr1: '',
  RemoteStr2: '',
  RemoteStr3: '',
  RemoteStr4: '',
  CNPT: 'CM',
  C150: 'CF',
  C16: 'CFD',
  CJ10: '',
  CFJ1K: '',
  C300: 'CG',
  C40: 'CGD',
  CJ20: '',
  CFJ2K: '',
  C600: 'CH',
  C64: 'CHD',
  CJ30: '',
  CFJ3K: '',
  C900: '',
  CFJ4K: '',
  PNPT: 'PM',
  P150: 'PF',
  P16: 'PFD',
  PJ10: '',
  PGJ1K: '',
  P300: 'PG',
  P40: 'PGD',
  PJ20: '',
  PJ30: '',
  PGJ2K: '',
  PGJ3K: '',
  PGJ4K: '',
  PNPTR: 'PMR',
  PNPTRE: '',
  P150R: 'PFR',
  P150RE: '',
  P16R: 'PFDR',
  P16RE: '',
  PJ10R: '',
  PGJ1R: '',
  P300R: 'PGR',
  P300RE: '',
  P40R: 'PGDR',
  P40RE: '',
  PJ20R: '',
  PGJ2R: '',
  P600R: 'PHR',
  P600RE: '',
  P600: 'PH',
  P64R: 'PHDR',
  P64RE: '',
  PJ30R: '',
  P64: 'PHD',
  PGJ3R: '',
  PGJ4R: '',
  ENPTR: '',
  E5016R: '',
  E5040R: '',
  E5064R: '',
  E150R: '',
  E300R: '',
  E600R: '',
  StandardLength: 'LS',
  CompactLength: 'LC',
  ExtendedLength: 'LE',
  V_METER: 'V',
  VT_METER: 'VT',
  VTP_METER: 'VTP',
  VTP_METER_P1: '',
  VTP_METER_P2: '',
  VTP_METER_P3: '',
  VTP_METER_P4: '',
  VTEP_METER: 'VTEP',
  VETEP_METER: '',
  VTEM_METER: 'VT EMS',
  VTPEM_METER: 'VTP EMS',
  VTEPEM_METER: '',
  VETEPEM_METER: '',
  LOOP_OUTPUT: 'V4LH',
  LOOP_POWERED: 'PV1L',
  VDC_1236_POWERED: 'PV1',
  AC_POWERED: 'PS',
  SPECIAL_STUFF: '',
  POE: 'DCHPOE',
  ONE_OUTPUT: 'V4H',
  NO_ALARM_MODBUS_OUTPUT: 'V4M',
  NO_ALARM_MODBUS_POE: 'V4MIP',
  ALARM_MODBUS: 'V6M',
  ALARM_MODBUS_POE: 'V6MIP',
  BACNET: 'V4B',
  BACNET_POE: 'V4BIP',
  ALARM_BACNET: 'V6B',
  ALARM_BACNET_POE: 'V6BIP',
  ALARM_HART: 'V6H',
  MORE_LOOP_POWERED: [],
  INLINE_NON_LOOP_ORDER: ['24', '25', '46', '26', '27', '47'],
  INSERTION_NON_LOOP_ORDER: ['15', '16', '55', '17', '18', '56'],
  NO_PRESSURE_TRANSDUCER: 'MP0',
  DIFF_PRESS_XMTR_FACT: '',
  DIFF_PRESS_XMTR_CUST: '',
  DIAG_ADD_PRESS_TAP_FT: '',

  // This is the Meter and code information.
  CODE_NAME: 'Innova',
  CODE_DESCRIPTION: 'Innova Vortex Flow Meter',
  TURBINE_CODE_NAME: 'Innova',
  TURBINE_CODE_DESCRIPTION: 'Innova Turbine Flow Meter',
  DATA_SHEET: 'APPLICATION DATA SHEET',
  DESCRIPTION: 'Innova Model 240S and Model 241S Vortex Meter Sizing Program. Written for Sierra Instruments',
  COPYRIGHT:
    'Copyright 2007-2023, VorTek Instruments, LLC\nAll rights reserved.\nCopy and Distribute freely.\nWarning: No warranty is expressed or implied about the accuracy of the calculations used in this program.',

  // This is the Sierra Instruments information.
  VORTEK_DISPLAY_NAME: 'Sierra Instruments',
  VORTEK_PRINT_NAME: 'Sierra Instruments',
  VORTEK_NAME: 'Sierra Instruments',
  VORTEK_ADDRESS0: '5 Harris Court',
  VORTEK_ADDRESS1: 'Building L',
  VORTEK_CITY: 'Monterey',
  VORTEK_STATE: 'California',
  VORTEK_POSTAL_CODE: '93940',
  VORTEK_COUNTRY: 'USA',
  VORTEK_TELEPHONE: 'Telephone: 800-866-0200',
  VORTEK_FAX: 'FAX: 831-373-4402',
};

export default InnovaSizingGlobals;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../common/Modal';
import { CallbackNoParams } from '../../types/callbackTypes';
import api from '../../api';
import { RootState } from '../../store';
import utilities from '../../utilities';
import constants from '../../constants';

const About = (props: { onClose: CallbackNoParams }): JSX.Element | null => {
  const { onClose } = props;
  const sizingGlobals = useSelector((state: RootState) => state.sizingGlobals);
  const { DESCRIPTION, COPYRIGHT, VORTEK_NAME } = sizingGlobals;

  const [logo, setLogo] = useState('');
  const [addressImage, setAddressImage] = useState('');
  const [smallImage, setSmallImage] = useState('');

  useEffect(() => {
    setLogo(api.loadLogo());
    setAddressImage(api.loadLogo('address'));
    setSmallImage(api.loadLogo('sizing_app'));
  }, []);

  const getCopyright = () => {
    return COPYRIGHT.split('\n').map((item) => {
      return (
        <p key={item} className="flexRowNoGrow noMargin font14">
          {item}
        </p>
      );
    });
  };

  return (
    <Modal title="About" visible showButtons={false}>
      <div className="leftRight10 topBottom10">
        <div className="flexRow">
          <div className="leftRight10 flexCol white">
            <img width={223} height={114} style={{ backgroundColor: 'white' }} src={logo} className="logo_about" alt="" />
          </div>
          <div className="flexCol white">
            <p className="topBottom8 font17 bold">Vortex Meter Sizing Program</p>
            <p className="topBottom8 font17 bold">Version: {constants.REVISION}</p>
            <p className="topBottom8 font14 width360">{DESCRIPTION}</p>
          </div>
          <div className="flexColCenter">
            <img src={smallImage} alt="" width={50} height={55} />
          </div>
        </div>
        {VORTEK_NAME === utilities.INNOVA_NAME && (
          <div className="leftRight10 topBottom10 flexTextRow white">
            <img src={addressImage} alt="" />
          </div>
        )}
        <div className="leftRight10 topBottom10 flexColNoGrow white">{getCopyright()}</div>
        <div className="leftRight5 topBottom20">
          <button type="button" onClick={() => onClose()}>
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default About;

import Decimal from 'decimal.js';
import PipeData from '../../types/PipeData';
import ProcessCondition from '../../types/processCondition';
import utilities from '..';
import constants from '../../constants';
import convert from '../convert';
import VortekDefaults from '../../types/vortekDefaults';
import VortekDbDefaults from '../../types/VortekDbDefaults';
import store from '../../store';
import actions from '../../store/actions';
import meterSpecify from '../meterSpecify';
import calcTurbineMeter from '../calc/calcTurbineMeter';
import calcMeter from '../calc/calcMeter';
import Accessory from '../../types/accessory';
import api from '../../api';
import Application from '../../types/application';
import Feature from '../../types/Feature';

const newApplication = (formShow: string): void => {
  const state = store.getState();
  const { appName, processConditions } = state;
  const { flowMin, flowNom, flowMax, temperatureMin, temperatureNom, temperatureMax, pressureMin, pressureNom, pressureMax } =
    processConditions;

  let newFormShow = formShow;

  if (formShow === 'yes') {
    if (
      appName !== '' &&
      flowMin !== '' &&
      flowNom !== '' &&
      flowMax !== '' &&
      temperatureMin !== '' &&
      temperatureNom !== '' &&
      temperatureMax !== '' &&
      pressureMin !== '' &&
      pressureNom !== '' &&
      pressureMax !== ''
    ) {
      return;
    }

    if (
      appName !== '' ||
      flowMin !== '' ||
      flowNom !== '' ||
      flowMax !== '' ||
      temperatureMin !== '' ||
      temperatureNom !== '' ||
      temperatureMax !== '' ||
      pressureMin !== '' ||
      pressureNom !== '' ||
      pressureMax !== ''
    ) {
      return;
    }
    newFormShow = 'no';
  }

  if (newFormShow === 'no') {
    store.dispatch(actions.setAppName(''));
    store.dispatch(actions.setFlowMin(''));
    store.dispatch(actions.setFlowNom(''));
    store.dispatch(actions.setFlowMax(''));
    store.dispatch(actions.setTemperatureMin(''));
    store.dispatch(actions.setTemperatureNom(''));
    store.dispatch(actions.setTemperatureMax(''));
    store.dispatch(actions.setPressureMin(''));
    store.dispatch(actions.setPressureNom(''));
    store.dispatch(actions.setPressureMax(''));
    store.dispatch(actions.setFluidType('Steam'));
    store.dispatch(actions.setFluid('Saturated Pressure'));

    store.dispatch(actions.setProcessConditions(constants.defaultPipeData));

    store.dispatch(actions.setSystemMessage(''));
    store.dispatch(actions.setWarningMessage(''));

    store.dispatch(actions.setAppMode('meter_conditions'));

    store.dispatch(actions.setFlowUnit('lb'));
    store.dispatch(actions.setFlowTime('hr'));
    store.dispatch(actions.setTemperatureUnit('deg F'));
    store.dispatch(actions.setPressurePrefix('psi'));
    store.dispatch(actions.setPressureCoefficient('G'));

    store.dispatch(actions.setErrorMessage(''));
    store.dispatch(actions.setMeterType('vortex'));
    store.dispatch(actions.setMeterData([]));
    store.dispatch(actions.setMeterIndex(-1));
    store.dispatch(actions.setModelCode(''));

    store.dispatch(actions.setPipeType('ansi'));
    store.dispatch(actions.setPipeOutsideDiameter('0.5 in'));
    store.dispatch(actions.setPipeSchedule('40'));
    store.dispatch(actions.setPipeInsideDiameter('0.622'));
    store.dispatch(actions.setPipeLinerMaterial(''));
    store.dispatch(actions.setPipeMaterial(''));
    store.dispatch(actions.setPipeReduceInsideDiameter(''));
    store.dispatch(actions.setPipeReduceOutsideDiameter(''));

    store.dispatch(actions.setReducingVorcone(false));
    store.dispatch(actions.setReducingVortex(false));
    store.dispatch(actions.setRemoteCableLength(''));
    store.dispatch(actions.setSelectedPipeOutsideDiameter(''));
    store.dispatch(actions.setSelectedPipeInsideDiameter(''));
    store.dispatch(actions.setSoundSpeed(''));

    store.dispatch(actions.setShowSpecifyMeter(false));
    store.dispatch(actions.setSpecifyMeterTagNumber(''));
    store.dispatch(actions.setSpecifyMeterParentModelNumber(''));
    store.dispatch(actions.setSpecifyMeterProcessFluid(''));
    store.dispatch(actions.setSpecifyMeterPipeSize(''));
    store.dispatch(actions.setSpecifyMeterPipeMaterial(''));
    store.dispatch(actions.setSpecifyMeterFlange(''));
    store.dispatch(actions.setSpecifyMeterProbeLength(''));
    store.dispatch(actions.setSpecifyMeterElectronicsMounting(''));
    store.dispatch(actions.setSpecifyMeterDisplayOption(''));
    store.dispatch(actions.setSpecifyMeterInputPower(''));
    store.dispatch(actions.setSpecifyMeterOutputSignal(''));
    store.dispatch(actions.setSpecifyMeterProcessTemperature(''));
    store.dispatch(actions.setSpecifyMeterTransducer(''));
    store.dispatch(actions.setSpecifyMeterProcessConnection(''));
    store.dispatch(actions.setSpecifyMeterRotor(''));
    store.dispatch(actions.setSpecifyMeterDiffPressXMTR(''));
    store.dispatch(actions.setSpecifyMeterDiffPressXMTRMani(''));
    store.dispatch(actions.setSpecifyMeterDiagDiffPressXMTR(''));
    store.dispatch(actions.setSpecifyMeterDiagAddPressTap(''));
    store.dispatch(actions.setSpecifyMeterCableLength(''));
    store.dispatch(actions.setSpecifyMeterCommunications(''));
    store.dispatch(actions.setSpecifyMeterTransducerBracket(''));
    store.dispatch(actions.setSpecifyMeterTempPressComp(''));
    store.dispatch(actions.setSpecifyMeterEnergyMeter(''));
    store.dispatch(actions.setSpecifyMeterApprovals(''));
    store.dispatch(actions.setSpecifyMeterFaceToFace(''));
    store.dispatch(actions.setSpecifyMeterSpecialNotes(''));
    store.dispatch(actions.setSpecifyMeterWarnings(''));
  }
};

const getApplicationData = () => {
  const state = store.getState();
  const {
    accessories,
    appName,
    defaults,
    meterData,
    meterIndex,
    processConditions,
    fluid,
    fluidType,
    pipeInsideDiameter,
    pipeLinerMaterial,
    pipeMaterial,
    pipeOutsideDiameter,
    pipeSchedule,
    pipeType,
    pipeUnit,
    specifyMeterTagNumber,
    specifyMeterSpecialNotes,
  } = state;

  const {
    flowMin,
    flowNom,
    flowMax,
    flowUnit,
    flowTime,
    temperatureMin,
    temperatureNom,
    temperatureMax,
    temperatureUnit,
    pressureMin,
    pressureNom,
    pressureMax,
    pressurePrefix,
    pressureCoefficient,
    densityMin,
    densityNom,
    densityMax,
    densityUnit,
    viscosityMin,
    viscosityNom,
    viscosityMax,
    viscosityUnit,
    velocityMin,
    velocityNom,
    velocityMax,
    velocityUnit,
  } = processConditions;

  let selectedMeter;

  if (meterData?.length && meterIndex !== undefined && meterIndex !== null && meterIndex >= 0) {
    selectedMeter = meterData[meterIndex];
  }

  let modelCode: string | undefined = meterSpecify.createModelCode();
  if (modelCode === 'XX' || (modelCode && modelCode.indexOf('undefined') > -1)) {
    modelCode = undefined;
  }

  let accessoryData = '';
  if (accessories?.length) {
    accessories.forEach((accessory) => {
      accessoryData += `${accessory.quantity}^${accessory.code}^${accessory.description}^`;
    });
  }

  const newDefaults: VortekDbDefaults = {
    densityUnit: defaults.defaultUnits.densityUnit,
    viscosityUnit: defaults.defaultUnits.viscosityUnit,
    velocityUnit: defaults.defaultUnits.velocityUnit,
    timeUnit: defaults.defaultUnits.timeUnit,
    pressureDropUnit: defaults.defaultUnits.pressureDropUnit,
    defaultStdConditions: {
      sDensityUnit: defaults.defaultProcessConditions.defaultStdConditions.sDensityUnit || '',
      dblDensityValue: defaults.defaultProcessConditions.defaultStdConditions.dblDensityValue.toString(),
      sFlowRateTime: defaults.defaultProcessConditions.defaultStdConditions.sFlowRateTime || '',
      sFlowRateUnit: defaults.defaultProcessConditions.defaultStdConditions.sFlowRateUnit || '',
      dblFlowRate: defaults.defaultProcessConditions.defaultStdConditions.dblFlowRate.toString(),
      dblTemperature: defaults.defaultProcessConditions.defaultStdConditions.dblTemperature.toString(),
      sTemperatureUnit: defaults.defaultProcessConditions.defaultStdConditions.sTemperatureUnit || '',
      dblPressure: defaults.defaultProcessConditions.defaultStdConditions.dblPressure.toString(),
      sPressureUnit: defaults.defaultProcessConditions.defaultStdConditions.sPressureUnit || '',
      sPressureGaugeAbsolute: defaults.defaultProcessConditions.defaultStdConditions.sPressureGaugeAbsolute || '',
      dblViscosityValue: defaults.defaultProcessConditions.defaultStdConditions.dblViscosityValue.toString(),
      sViscosityUnit: defaults.defaultProcessConditions.defaultStdConditions.sViscosityUnit || '',
      warnings: defaults.defaultProcessConditions.defaultStdConditions.warnings || [],
    },
    defaultNmlConditions: {
      sDensityUnit: defaults.defaultProcessConditions.defaultNmlConditions.sDensityUnit || '',
      dblDensityValue: defaults.defaultProcessConditions.defaultNmlConditions.dblDensityValue.toString(),
      sFlowRateTime: defaults.defaultProcessConditions.defaultNmlConditions.sFlowRateTime || '',
      sFlowRateUnit: defaults.defaultProcessConditions.defaultNmlConditions.sFlowRateUnit || '',
      dblFlowRate: defaults.defaultProcessConditions.defaultNmlConditions.dblFlowRate.toString(),
      dblTemperature: defaults.defaultProcessConditions.defaultNmlConditions.dblTemperature.toString(),
      sTemperatureUnit: defaults.defaultProcessConditions.defaultNmlConditions.sTemperatureUnit || '',
      dblPressure: defaults.defaultProcessConditions.defaultNmlConditions.dblPressure.toString(),
      sPressureUnit: defaults.defaultProcessConditions.defaultNmlConditions.sPressureUnit || '',
      sPressureGaugeAbsolute: defaults.defaultProcessConditions.defaultNmlConditions.sPressureGaugeAbsolute || '',
      dblViscosityValue: defaults.defaultProcessConditions.defaultNmlConditions.dblViscosityValue.toString(),
      sViscosityUnit: defaults.defaultProcessConditions.defaultNmlConditions.sViscosityUnit || '',
      warnings: defaults.defaultProcessConditions.defaultNmlConditions.warnings || [],
    },
    standardConditions: {
      sDensityUnit: defaults.defaultProcessConditions.standardConditions.sDensityUnit || '',
      dblDensityValue: defaults.defaultProcessConditions.standardConditions.dblDensityValue.toString(),
      sFlowRateTime: defaults.defaultProcessConditions.standardConditions.sFlowRateTime || '',
      sFlowRateUnit: defaults.defaultProcessConditions.standardConditions.sFlowRateUnit || '',
      dblFlowRate: defaults.defaultProcessConditions.standardConditions.dblFlowRate.toString(),
      dblTemperature: defaults.defaultProcessConditions.standardConditions.dblTemperature.toString(),
      sTemperatureUnit: defaults.defaultProcessConditions.standardConditions.sTemperatureUnit || '',
      dblPressure: defaults.defaultProcessConditions.standardConditions.dblPressure.toString(),
      sPressureUnit: defaults.defaultProcessConditions.standardConditions.sPressureUnit || '',
      sPressureGaugeAbsolute: defaults.defaultProcessConditions.standardConditions.sPressureGaugeAbsolute || '',
      dblViscosityValue: defaults.defaultProcessConditions.standardConditions.dblViscosityValue.toString(),
      sViscosityUnit: defaults.defaultProcessConditions.standardConditions.sViscosityUnit || '',
      warnings: defaults.defaultProcessConditions.standardConditions.warnings || [],
    },
    normalConditions: {
      sDensityUnit: defaults.defaultProcessConditions.normalConditions.sDensityUnit || '',
      dblDensityValue: defaults.defaultProcessConditions.normalConditions.dblDensityValue.toString(),
      sFlowRateTime: defaults.defaultProcessConditions.normalConditions.sFlowRateTime || '',
      sFlowRateUnit: defaults.defaultProcessConditions.normalConditions.sFlowRateUnit || '',
      dblFlowRate: defaults.defaultProcessConditions.normalConditions.dblFlowRate.toString(),
      dblTemperature: defaults.defaultProcessConditions.normalConditions.dblTemperature.toString(),
      sTemperatureUnit: defaults.defaultProcessConditions.normalConditions.sTemperatureUnit || '',
      dblPressure: defaults.defaultProcessConditions.normalConditions.dblPressure.toString(),
      sPressureUnit: defaults.defaultProcessConditions.normalConditions.sPressureUnit || '',
      sPressureGaugeAbsolute: defaults.defaultProcessConditions.normalConditions.sPressureGaugeAbsolute || '',
      dblViscosityValue: defaults.defaultProcessConditions.normalConditions.dblViscosityValue.toString(),
      sViscosityUnit: defaults.defaultProcessConditions.normalConditions.sViscosityUnit || '',
      warnings: defaults.defaultProcessConditions.normalConditions.warnings || [],
    },
    baroPressValue: defaults.defaultValues.baroPressValue.toString(),
    baroPressUnit: defaults.defaultUnits.baroPressUnit,
    outputPressureDropUnit: defaults.defaultUnits.outputPressureDropUnit,
    flowRateFormat: defaults.defaultFormats.flowRateFormat,
    lengthFormat: defaults.defaultFormats.lengthFormat,
    massFormat: defaults.defaultFormats.massFormat,
    pressureFormat: defaults.defaultFormats.pressureFormat,
    temperatureFormat: defaults.defaultFormats.temperatureFormat,
    volumeFormat: defaults.defaultFormats.volumeFormat,
    areaFormat: defaults.defaultFormats.areaFormat,
    densityFormat: defaults.defaultFormats.densityFormat,
    velocityFormat: defaults.defaultFormats.velocityFormat,
    viscosityFormat: defaults.defaultFormats.viscosityFormat,
    unitlessFormat: defaults.defaultFormats.unitlessFormat,
    generalNumberFormat: defaults.defaultFormats.generalNumberFormat,
    fixedFormat: defaults.defaultFormats.fixedFormat,
    standardFormat: defaults.defaultFormats.standardFormat,
  };

  const fileContain: Application = {
    appname: appName?.trim(),
    'fluid-type': fluidType,
    fluid,
    'pipe-type': pipeType,
    'pipe-size': pipeOutsideDiameter,
    'pipe-sched': pipeSchedule,
    'pipe-id': pipeInsideDiameter?.trim(),
    'pipe-unit': pipeUnit,
    'min-flow': flowMin?.trim(),
    'min-temp': temperatureMin?.trim(),
    'min-press': pressureMin?.trim(),
    'nom-flow': flowNom?.trim(),
    'nom-temp': temperatureNom?.trim(),
    'nom-press': pressureNom?.trim(),
    'max-flow': flowMax?.trim(),
    'max-temp': temperatureMax?.trim(),
    'max-press': pressureMax?.trim(),
    'temp-unit': temperatureUnit,
    'press-unit': pressurePrefix,
    'density-unit': densityUnit,
    'visc-unit': viscosityUnit,
    'velo-unit': velocityUnit,
    'press-type': pressureCoefficient,
    'flow-unit': flowUnit,
    'flow-time': flowTime,
    'min-density': densityMin,
    'min-visc': viscosityMin,
    'min-velo': velocityMin,
    'nom-density': densityNom,
    'nom-visc': viscosityNom,
    'nom-velo': velocityNom,
    'max-density': densityMax,
    'max-visc': viscosityMax,
    'max-velo': velocityMax,
    'min-reynolds': selectedMeter?.minrey || '',
    'nom-reynolds': selectedMeter?.nomrey || '',
    'max-reynolds': selectedMeter?.maxrey || '',
    modelCode,
    tagNumber: specifyMeterTagNumber || '',
    specialNotes: specifyMeterSpecialNotes || '',
    accessoryData,
    pipeMaterial: pipeMaterial || null,
    linerMaterial: pipeLinerMaterial || null,
    defaults: newDefaults,
    version: constants.REVISION,
    creationDate: new Date().toUTCString(),
  };

  return fileContain;
};

const collectApplicationData = (pipeData: PipeData, minNomMax: string): [PipeData, ProcessCondition] => {
  // Calculate the minimum flow rate through the suggested pipe.
  const cConditions = new ProcessCondition();

  cConditions.sPressureUnit = pipeData.pressurePrefix;
  cConditions.sPressureGaugeAbsolute = pipeData.pressureCoefficient;
  cConditions.sDensityUnit = pipeData.densityUnit;
  cConditions.sViscosityUnit = pipeData.viscosityUnit;
  cConditions.sTemperatureUnit = pipeData.temperatureUnit;
  cConditions.sFlowRateUnit = pipeData.flowUnit;
  cConditions.sFlowRateTime = pipeData.flowTime;

  if (minNomMax === 'min') {
    if (pipeData.calcDensityMinVal?.greaterThan(constants.ZERO)) {
      cConditions.dblDensityValue = pipeData.calcDensityMinVal;
    } else {
      cConditions.dblDensityValue = utilities.isValid(pipeData.densityMin)
        ? new Decimal(pipeData.densityMin.replace(',', ''))
        : constants.ZERO;
    }

    if (pipeData.calcViscosityMinVal?.greaterThan(constants.ZERO)) {
      cConditions.dblViscosityValue = pipeData.calcViscosityMinVal;
    } else {
      cConditions.dblViscosityValue = utilities.isValid(pipeData.viscosityMin)
        ? new Decimal(pipeData.viscosityMin.replace(',', ''))
        : constants.ZERO;
    }

    cConditions.dblFlowRate = utilities.isValid(pipeData.flowMin) ? new Decimal(pipeData.flowMin.replace(',', '')) : constants.ZERO;

    cConditions.dblTemperature = utilities.isValid(pipeData.temperatureMin)
      ? new Decimal(pipeData.temperatureMin.replace(',', ''))
      : constants.ZERO;

    cConditions.dblPressure = utilities.isValid(pipeData.pressureMin)
      ? new Decimal(pipeData.pressureMin.replace(',', ''))
      : constants.ZERO;
  } else if (minNomMax === 'nom') {
    if (pipeData.calcDensityNomVal?.greaterThan(constants.ZERO)) {
      cConditions.dblDensityValue = pipeData.calcDensityNomVal;
    } else {
      cConditions.dblDensityValue = utilities.isValid(pipeData.densityNom)
        ? new Decimal(pipeData.densityNom.replace(',', ''))
        : constants.ZERO;
    }

    if (pipeData.calcViscosityNomVal?.greaterThan(constants.ZERO)) {
      cConditions.dblViscosityValue = pipeData.calcViscosityNomVal;
    } else {
      cConditions.dblViscosityValue = utilities.isValid(pipeData.viscosityNom)
        ? new Decimal(pipeData.viscosityNom.replace(',', ''))
        : constants.ZERO;
    }

    cConditions.dblFlowRate = utilities.isValid(pipeData.flowNom) ? new Decimal(pipeData.flowNom.replace(',', '')) : constants.ZERO;

    cConditions.dblTemperature = utilities.isValid(pipeData.temperatureNom)
      ? new Decimal(pipeData.temperatureNom.replace(',', ''))
      : constants.ZERO;

    cConditions.dblPressure = utilities.isValid(pipeData.pressureNom)
      ? new Decimal(pipeData.pressureNom.replace(',', ''))
      : constants.ZERO;
  } else if (minNomMax === 'max') {
    if (pipeData.calcDensityMaxVal?.greaterThan(constants.ZERO)) {
      cConditions.dblDensityValue = pipeData.calcDensityMaxVal;
    } else {
      cConditions.dblDensityValue = utilities.isValid(pipeData.densityMax)
        ? new Decimal(pipeData.densityMax.replace(',', ''))
        : constants.ZERO;
    }

    if (pipeData.calcViscosityMaxVal?.greaterThan(constants.ZERO)) {
      cConditions.dblViscosityValue = pipeData.calcViscosityMaxVal;
    } else {
      cConditions.dblViscosityValue = utilities.isValid(pipeData.viscosityMax)
        ? new Decimal(pipeData.viscosityMax.replace(',', ''))
        : constants.ZERO;
    }

    cConditions.dblFlowRate = utilities.isValid(pipeData.flowMax) ? new Decimal(pipeData.flowMax.replace(',', '')) : constants.ZERO;

    cConditions.dblTemperature = utilities.isValid(pipeData.temperatureMax)
      ? new Decimal(pipeData.temperatureMax.replace(',', ''))
      : constants.ZERO;

    cConditions.dblPressure = utilities.isValid(pipeData.pressureMax)
      ? new Decimal(pipeData.pressureMax.replace(',', ''))
      : constants.ZERO;
  }

  cConditions.dblPressure = convert.pressureTo(
    'psi-G',
    cConditions.dblPressure,
    cConditions.sPressureUnit,
    cConditions.sPressureGaugeAbsolute
  );

  const newPipeData = { ...pipeData };

  newPipeData.originalPressurePrefix = pipeData.pressurePrefix;
  newPipeData.originalPressureCoefficient = pipeData.pressureCoefficient;

  cConditions.sPressureUnit = 'psi';
  cConditions.sPressureGaugeAbsolute = 'G';
  return [newPipeData, cConditions];
};

const processDefaultsFromApp = (appDefaults: VortekDbDefaults): VortekDefaults => {
  const state = store.getState();
  const { defaults } = state;

  const newDefaults = new VortekDefaults();
  newDefaults.defaultUnits.densityUnit = appDefaults.densityUnit || defaults.defaultUnits.densityUnit;
  newDefaults.defaultUnits.viscosityUnit = appDefaults.viscosityUnit || defaults.defaultUnits.viscosityUnit;
  newDefaults.defaultUnits.velocityUnit = appDefaults.velocityUnit || defaults.defaultUnits.velocityUnit;
  newDefaults.defaultUnits.timeUnit = appDefaults.timeUnit || defaults.defaultUnits.timeUnit;
  newDefaults.defaultUnits.pressureDropUnit = appDefaults.pressureDropUnit || defaults.defaultUnits.pressureDropUnit;
  newDefaults.defaultUnits.baroPressUnit = appDefaults.baroPressUnit || defaults.defaultUnits.baroPressUnit;
  newDefaults.defaultUnits.outputPressureDropUnit = 'psi';

  newDefaults.defaultFormats.areaFormat = appDefaults.areaFormat || defaults.defaultFormats.areaFormat;
  newDefaults.defaultFormats.densityFormat = appDefaults.densityFormat || defaults.defaultFormats.densityFormat;
  newDefaults.defaultFormats.fixedFormat = '0#';
  newDefaults.defaultFormats.flowRateFormat = appDefaults.flowRateFormat || defaults.defaultFormats.flowRateFormat;
  newDefaults.defaultFormats.generalNumberFormat = '0.00##';
  newDefaults.defaultFormats.lengthFormat = appDefaults.lengthFormat || defaults.defaultFormats.lengthFormat;
  newDefaults.defaultFormats.massFormat = appDefaults.massFormat || defaults.defaultFormats.massFormat;
  newDefaults.defaultFormats.pressureFormat = appDefaults.pressureFormat || defaults.defaultFormats.pressureFormat;
  newDefaults.defaultFormats.standardFormat = '#,##0.00';
  newDefaults.defaultFormats.temperatureFormat = appDefaults.temperatureFormat || defaults.defaultFormats.temperatureFormat;
  newDefaults.defaultFormats.unitlessFormat = appDefaults.unitlessFormat || defaults.defaultFormats.unitlessFormat;
  newDefaults.defaultFormats.velocityFormat = appDefaults.velocityFormat || defaults.defaultFormats.velocityFormat;
  newDefaults.defaultFormats.viscosityFormat = appDefaults.viscosityFormat || defaults.defaultFormats.viscosityFormat;
  newDefaults.defaultFormats.volumeFormat = appDefaults.volumeFormat || defaults.defaultFormats.volumeFormat;

  newDefaults.defaultValues.baroPressValue = new Decimal(appDefaults.baroPressValue || defaults.defaultValues.baroPressValue);

  let c = new ProcessCondition();

  c.dblDensityValue = new Decimal(appDefaults.defaultStdConditions.dblDensityValue);
  c.dblFlowRate = new Decimal(appDefaults.defaultStdConditions.dblFlowRate);
  c.dblTemperature = new Decimal(appDefaults.defaultStdConditions.dblTemperature);
  c.dblPressure = new Decimal(appDefaults.defaultStdConditions.dblPressure);
  c.dblViscosityValue = new Decimal(appDefaults.defaultStdConditions.dblViscosityValue);
  c.sDensityUnit =
    appDefaults.defaultStdConditions.sDensityUnit || defaults.defaultProcessConditions.defaultStdConditions.sDensityUnit;
  c.sFlowRateTime =
    appDefaults.defaultStdConditions.sFlowRateTime || defaults.defaultProcessConditions.defaultStdConditions.sFlowRateTime;
  c.sFlowRateUnit =
    appDefaults.defaultStdConditions.sFlowRateUnit || defaults.defaultProcessConditions.defaultStdConditions.sFlowRateUnit;
  c.sPressureGaugeAbsolute =
    appDefaults.defaultStdConditions.sPressureGaugeAbsolute ||
    defaults.defaultProcessConditions.defaultStdConditions.sPressureGaugeAbsolute;
  c.sPressureUnit =
    appDefaults.defaultStdConditions.sPressureUnit || defaults.defaultProcessConditions.defaultStdConditions.sPressureUnit;
  c.sTemperatureUnit =
    appDefaults.defaultStdConditions.sTemperatureUnit || defaults.defaultProcessConditions.defaultStdConditions.sTemperatureUnit;
  c.sViscosityUnit =
    appDefaults.defaultStdConditions.sViscosityUnit || defaults.defaultProcessConditions.defaultStdConditions.sViscosityUnit || '';
  newDefaults.defaultProcessConditions.defaultStdConditions = c;

  c = new ProcessCondition();
  c.dblDensityValue = new Decimal(appDefaults.defaultNmlConditions.dblDensityValue);
  c.dblFlowRate = new Decimal(appDefaults.defaultNmlConditions.dblFlowRate);
  c.dblTemperature = new Decimal(appDefaults.defaultNmlConditions.dblTemperature);
  c.dblPressure = new Decimal(appDefaults.defaultNmlConditions.dblPressure);
  c.dblViscosityValue = new Decimal(appDefaults.defaultNmlConditions.dblViscosityValue);
  c.sDensityUnit =
    appDefaults.defaultNmlConditions.sDensityUnit || defaults.defaultProcessConditions.defaultNmlConditions.sDensityUnit;
  c.sFlowRateTime =
    appDefaults.defaultNmlConditions.sFlowRateTime || defaults.defaultProcessConditions.defaultNmlConditions.sFlowRateTime;
  c.sFlowRateUnit =
    appDefaults.defaultNmlConditions.sFlowRateUnit || defaults.defaultProcessConditions.defaultNmlConditions.sFlowRateUnit;
  c.sPressureGaugeAbsolute =
    appDefaults.defaultNmlConditions.sPressureGaugeAbsolute ||
    defaults.defaultProcessConditions.defaultNmlConditions.sPressureGaugeAbsolute;
  c.sPressureUnit =
    appDefaults.defaultNmlConditions.sPressureUnit || defaults.defaultProcessConditions.defaultNmlConditions.sPressureUnit;
  c.sTemperatureUnit =
    appDefaults.defaultNmlConditions.sTemperatureUnit || defaults.defaultProcessConditions.defaultNmlConditions.sTemperatureUnit;
  c.sViscosityUnit =
    appDefaults.defaultNmlConditions.sViscosityUnit || defaults.defaultProcessConditions.defaultNmlConditions.sViscosityUnit || '';
  newDefaults.defaultProcessConditions.defaultNmlConditions = c;

  c = new ProcessCondition();
  c.dblDensityValue = new Decimal(appDefaults.standardConditions.dblDensityValue);
  c.dblFlowRate = new Decimal(appDefaults.standardConditions.dblFlowRate);
  c.dblTemperature = new Decimal(appDefaults.standardConditions.dblTemperature);
  c.dblPressure = new Decimal(appDefaults.standardConditions.dblPressure);
  c.dblViscosityValue = new Decimal(appDefaults.standardConditions.dblViscosityValue);
  c.sDensityUnit = appDefaults.standardConditions.sDensityUnit || defaults.defaultProcessConditions.standardConditions.sDensityUnit;
  c.sFlowRateTime =
    appDefaults.standardConditions.sFlowRateTime || defaults.defaultProcessConditions.standardConditions.sFlowRateTime;
  c.sFlowRateUnit =
    appDefaults.standardConditions.sFlowRateUnit || defaults.defaultProcessConditions.standardConditions.sFlowRateUnit;
  c.sPressureGaugeAbsolute =
    appDefaults.standardConditions.sPressureGaugeAbsolute ||
    defaults.defaultProcessConditions.standardConditions.sPressureGaugeAbsolute;
  c.sPressureUnit =
    appDefaults.standardConditions.sPressureUnit || defaults.defaultProcessConditions.standardConditions.sPressureUnit;
  c.sTemperatureUnit =
    appDefaults.standardConditions.sTemperatureUnit || defaults.defaultProcessConditions.standardConditions.sTemperatureUnit;
  c.sViscosityUnit =
    appDefaults.standardConditions.sViscosityUnit || defaults.defaultProcessConditions.standardConditions.sViscosityUnit || '';
  newDefaults.defaultProcessConditions.standardConditions = c;

  c = new ProcessCondition();
  c.dblDensityValue = new Decimal(appDefaults.normalConditions.dblDensityValue);
  c.dblFlowRate = new Decimal(appDefaults.normalConditions.dblFlowRate);
  c.dblTemperature = new Decimal(appDefaults.normalConditions.dblTemperature);
  c.dblPressure = new Decimal(appDefaults.normalConditions.dblPressure);
  c.dblViscosityValue = new Decimal(appDefaults.normalConditions.dblViscosityValue);
  c.sDensityUnit = appDefaults.normalConditions.sDensityUnit || defaults.defaultProcessConditions.normalConditions.sDensityUnit;
  c.sFlowRateTime = appDefaults.normalConditions.sFlowRateTime || defaults.defaultProcessConditions.normalConditions.sFlowRateTime;
  c.sFlowRateUnit = appDefaults.normalConditions.sFlowRateUnit || defaults.defaultProcessConditions.normalConditions.sFlowRateUnit;
  c.sPressureGaugeAbsolute =
    appDefaults.normalConditions.sPressureGaugeAbsolute ||
    defaults.defaultProcessConditions.normalConditions.sPressureGaugeAbsolute;
  c.sPressureUnit = appDefaults.normalConditions.sPressureUnit || defaults.defaultProcessConditions.normalConditions.sPressureUnit;
  c.sTemperatureUnit =
    appDefaults.normalConditions.sTemperatureUnit || defaults.defaultProcessConditions.normalConditions.sTemperatureUnit;
  c.sViscosityUnit =
    appDefaults.normalConditions.sViscosityUnit || defaults.defaultProcessConditions.normalConditions.sViscosityUnit || '';
  newDefaults.defaultProcessConditions.normalConditions = c;
  return newDefaults;
};

const saveApplicationToServer = async (): Promise<string> => {
  const state = store.getState();
  const { fluid, sizingGlobals, username } = state;
  const { VORTEK_NAME } = sizingGlobals;

  const fileContain = getApplicationData();
  let flag = 0;
  let missingData = '';

  if (!fileContain.appname) {
    store.dispatch(actions.setErrorMessage('Missing Data: Application Name'));
    return '';
  }

  if (!fileContain['min-flow']) {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Minimum Flow Rate';
  }

  if (!fileContain['nom-flow']) {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Nominal Flow Rate';
  }

  if (!fileContain['max-flow']) {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Maximum Flow Rate';
  }

  if (!fileContain['min-press'] && fluid !== 'Saturated Temperature') {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Minimum Pressure';
  }

  if (!fileContain['nom-press'] && fluid !== 'Saturated Temperature') {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Nominal Pressure';
  }

  if (!fileContain['max-press'] && fluid !== 'Saturated Temperature') {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Maximum Pressure';
  }

  if (!fileContain['min-temp'] && fluid !== 'Saturated Pressure') {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Minimum Temperature';
  }

  if (!fileContain['nom-temp'] && fluid !== 'Saturated Pressure') {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Nominal Temperature';
  }

  if (!fileContain['max-temp'] && fluid !== 'Saturated Pressure') {
    if (missingData) {
      missingData += '\n';
    }
    missingData += 'Maximum Temperature';
  }

  // Process Conditions is fields is blank...
  if (
    fileContain.appname !== '' &&
    fileContain['min-flow'] !== '' &&
    fileContain['nom-flow'] !== '' &&
    fileContain['max-flow'] !== '' &&
    fileContain['min-press'] !== '' &&
    fileContain['nom-press'] !== '' &&
    fileContain['max-press'] !== '' &&
    fluid === 'Saturated Pressure'
  ) {
    flag = 1;
  } else if (
    fileContain.appname !== '' &&
    fileContain['min-flow'] !== '' &&
    fileContain['nom-flow'] !== '' &&
    fileContain['max-flow'] !== '' &&
    fileContain['min-temp'] !== '' &&
    fileContain['nom-temp'] !== '' &&
    fileContain['max-temp'] !== '' &&
    fluid === 'Saturated Temperature'
  ) {
    flag = 1;
  } else if (
    fileContain.appname !== '' &&
    fileContain['min-flow'] !== '' &&
    fileContain['nom-flow'] !== '' &&
    fileContain['max-flow'] !== '' &&
    fileContain['min-temp'] !== '' &&
    fileContain['nom-temp'] !== '' &&
    fileContain['max-temp'] !== '' &&
    fileContain['min-press'] !== '' &&
    fileContain['nom-press'] !== '' &&
    fileContain['max-press'] !== ''
  ) {
    flag = 1;
  }

  if (flag === 0) {
    if (missingData) {
      missingData = `Missing Data:\n${missingData}`;
      store.dispatch(actions.setErrorMessage(missingData));
      return '';
    }

    store.dispatch(actions.setErrorMessage('Cannot save application'));
    return '';
  }

  // convert defaults to b64
  if (fileContain && fileContain.defaults) {
    fileContain.defaults_b64 = Buffer.from(JSON.stringify(fileContain.defaults)).toString('base64');
    delete fileContain.defaults;
  }

  const res = await api.writeItem({
    requestData: {
      client: VORTEK_NAME,
      username,
      key: fileContain.appname,
      _id: `${VORTEK_NAME}${username}application${fileContain.appname}`,
      overwrite: true,
      collection: 'application',
      data: {
        client: VORTEK_NAME,
        username,
        key: fileContain.appname,
        _id: `${VORTEK_NAME}${username}application${fileContain.appname}`,
        data: JSON.parse(JSON.stringify(fileContain)),
      },
    },
  });

  if (res.status === 'fail') {
    utilities.handleAjaxFailure(res);
    return '';
  }

  return 'Application saved.';
};

const downloadApplication = (): string => {
  const state = store.getState();
  const { appName } = state;

  if (!appName) {
    store.dispatch(actions.setErrorMessage('Missing Data: Application Name'));
    return '';
  }

  const fileContain = JSON.stringify(getApplicationData());

  api.downloadFile({
    filename: `${appName}.json`,
    content: fileContain,
  });

  return '';
};

// eslint-disable-next-line
const parseApplication = (appName: string, data: any): void => {
  newApplication('no');

  const state = store.getState();
  const { sizingGlobals } = state;
  const {
    InlineModel,
    InlineModelPrev,
    InlineNonReduceModel,
    InlineReduceModel,
    InsertionModel,
    InsertionModelPrev,
    UltrasonicS34Model,
    UltrasonicS36Model,
    UltrasonicU42Model,
    UltrasonicU43Model,
    UltrasonicU44Model,
    ElectromagneticProMModel,
    VorconeModel,
    VorconeReduceModel,
    VORTEK_NAME,
  } = sizingGlobals;

  let i: number;
  let obj = data;
  let realProbe;
  let realMounting;
  let endMounting;
  let featurelist;
  let display;
  let power;
  let signal;
  let temperature;
  let transducer;
  let realMatSize;
  let flange;
  let match: string | undefined;
  let model: string[];
  let realModel = '';
  let fluid;
  let regex;
  let size;
  let beta;
  let material;
  let rest;
  let realFlange;
  let energy;
  let turbine;
  let version;

  if (typeof data === 'string') {
    obj = JSON.parse(data);
    if (obj.defaults) {
      obj.defaults = processDefaultsFromApp(obj.defaults);
    }
  }

  if (obj.version) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    version = obj.version;
  }

  if (obj.defaults) {
    store.dispatch(actions.setDefaults(obj.defaults));
    store.dispatch(actions.setDensityUnit(obj.defaults.defaultUnits.densityUnit));
    store.dispatch(actions.setViscosityUnit(obj.defaults.defaultUnits.viscosityUnit));
    store.dispatch(actions.setVelocityUnit(`${obj.defaults.defaultUnits.velocityUnit}/${obj.defaults.defaultUnits.timeUnit}`));
  }

  // support loading previously saved crap data
  if (obj['temp-unit'] === 'F') {
    obj['temp-unit'] = 'deg F';
  } else if (obj['temp-unit'] === 'C') {
    obj['temp-unit'] = 'deg C';
  } else if (obj['temp-unit'] === 'R') {
    obj['temp-unit'] = 'Rk';
  }

  if (obj['flow-unit'] === 'lb/ft 3') {
    obj['flow-unit'] = 'lb/ft^3';
  }

  store.dispatch(actions.setAppName(appName));
  store.dispatch(actions.setFluidType(obj['fluid-type']));
  store.dispatch(actions.setFluid(obj.fluid));
  store.dispatch(actions.setPipeType(obj['pipe-type']));
  const pipeOutsideDiameter = obj['pipe-size'];
  store.dispatch(actions.setPipeOutsideDiameter(pipeOutsideDiameter));
  store.dispatch(actions.setPipeSchedule(obj['pipe-sched']));
  store.dispatch(actions.setPipeInsideDiameter(obj['pipe-id']));
  store.dispatch(actions.setPipeUnit(obj['pipe-unit']));
  store.dispatch(actions.setFlowUnit(obj['flow-unit']));
  store.dispatch(actions.setFlowTime(obj['flow-time']));
  store.dispatch(actions.setTemperatureUnit(obj['temp-unit']));
  store.dispatch(actions.setPressurePrefix(obj['press-unit']));
  store.dispatch(actions.setPressureCoefficient(obj['press-type']));
  store.dispatch(actions.setDensityMin(obj['min-density']));
  store.dispatch(actions.setDensityNom(obj['nom-density']));
  store.dispatch(actions.setDensityMax(obj['max-density']));
  store.dispatch(actions.setViscosityMin(obj['min-visc']));
  store.dispatch(actions.setViscosityNom(obj['nom-visc']));
  store.dispatch(actions.setViscosityMax(obj['max-visc']));
  store.dispatch(actions.setVelocityMin(obj['min-velo']));
  store.dispatch(actions.setVelocityNom(obj['nom-velo']));
  store.dispatch(actions.setVelocityMax(obj['max-velo']));
  store.dispatch(actions.setDensityUnit(obj['density-unit']));
  store.dispatch(actions.setViscosityUnit(obj['visc-unit']));
  store.dispatch(actions.setVelocityUnit(obj['velo-unit']));
  store.dispatch(actions.setModelCode(obj.modelCode));
  store.dispatch(actions.setSpecifyMeterTagNumber(obj.tagNumber));
  store.dispatch(actions.setFlowMin(obj['min-flow']));
  store.dispatch(actions.setFlowNom(obj['nom-flow']));
  store.dispatch(actions.setFlowMax(obj['max-flow']));
  store.dispatch(actions.setTemperatureMin(obj['min-temp']));
  store.dispatch(actions.setTemperatureNom(obj['nom-temp']));
  store.dispatch(actions.setTemperatureMax(obj['max-temp']));
  store.dispatch(actions.setPressureMin(obj['min-press']));
  store.dispatch(actions.setPressureNom(obj['nom-press']));
  store.dispatch(actions.setPressureMax(obj['max-press']));

  const { pipeMaterial } = obj;
  const { linerMaterial } = obj;

  let { specialNotes } = obj;
  if (specialNotes) {
    regex = /<br\s*[/]?>/gi;
    specialNotes = specialNotes.replace(regex, '\n');
    store.dispatch(actions.setSpecifyMeterSpecialNotes(specialNotes));
  } else {
    store.dispatch(actions.setSpecifyMeterSpecialNotes(''));
  }

  if (utilities.checkValues()) {
    // recalculate based on new values
    utilities.readyData();

    // make sure main app view is active
    store.dispatch(actions.setMeterData([]));
    store.dispatch(actions.setMeterIndex(-1));
    store.dispatch(actions.setAppMode('meter_conditions'));

    let turbineActive = false;
    let reducingVortex = false;
    let vorconeActive = false;
    let reducingVorcone = false;

    // if the app has a model code saved
    if (obj.modelCode) {
      // separate parts by -
      model = obj.modelCode.split('-');

      // Onicon has a strange model code that needs further split apart
      if (VORTEK_NAME === utilities.ONICON_NAME) {
        // first join back the real model code that was split apart incorrectly
        realModel = `${model[0]}-${model[1]}`;
        model[0] = realModel;
        // now remove the unnecessary item in the list
        model.splice(1, 1);
        if (realModel.substring(0, 4) === InlineModel || realModel.substring(0, 4) === InlineReduceModel) {
          model[0] = realModel.substring(0, 4);
          size = realModel.substring(4, 6);
          model.splice(1, 0, size as string);
          if (!utilities.isNumber(Number(size))) {
            reducingVortex = true;
            store.dispatch(actions.setMeterType('reduce_vortex'));
          } else {
            store.dispatch(actions.setMeterType('vortex'));
          }
        }
      } else if (VORTEK_NAME === utilities.INNOVA_NAME) {
        if (model[0].substring(0, 4) === InlineModel || model[0].substring(0, 4) === InlineReduceModel) {
          if (model[2] === 'R') {
            reducingVortex = true;
            store.dispatch(actions.setMeterType('reduce_vortex'));
            // now remove the item in the list and process like inline
            model.splice(2, 1);
          } else {
            store.dispatch(actions.setMeterType('vortex'));
          }
        }

        if (model[7] === 'IP') {
          model[6] += '-IP';
          delete model[7];
        }
      }
      // Azbil has a weird model code that needs further split apart
      else if (VORTEK_NAME === utilities.AZBIL_NAME) {
        if (model[0].substring(0, 5) === InlineReduceModel) {
          realModel = model[0].substring(0, 5);
          fluid = model[0].substring(5, 6);
        } else {
          realModel = model[0].substring(0, 4);
          fluid = model[0].substring(4, 6);
        }

        model[0] = realModel;
        model.splice(1, 0, fluid);
        // Spirax and Vortek have two sections of the model code that need more processing
      } else if (
        VORTEK_NAME === utilities.PROV_NAME ||
        VORTEK_NAME === utilities.SPIRAX_NAME ||
        VORTEK_NAME === utilities.GESTRA_NAME ||
        VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
      ) {
        if (model[2] === 'EM') {
          model[1] = `${model[1]}-${model[2]}`;
          model.splice(2, 1);
        }

        if (model[10] === 'E') {
          model[9] = `${model[9]}-${model[10]}`;
          model.splice(10, 1);
        }

        if (model[0] === 'Pro' && model[1] === 'M') {
          realModel = `${model[0]}-${model[1]}`;
          model[0] = realModel;
          // now remove the unnecessary item in the list
          model.splice(1, 1);
        }
      }

      // map old models to new to support loading old model data
      if (model[0] === InlineModelPrev) {
        model[0] = InlineModel;
      } else if (model[0] === InsertionModelPrev) {
        model[0] = InsertionModel;
      }

      if (model[0] === InlineReduceModel && VORTEK_NAME !== utilities.ONICON_NAME && VORTEK_NAME !== utilities.INNOVA_NAME) {
        reducingVortex = true;
        store.dispatch(actions.setMeterType('reduce_vortex'));
      } else if (model[0] === InlineNonReduceModel) {
        store.dispatch(actions.setMeterType('nonreduce_vortex'));
      } else if (model[0] === InlineModel && VORTEK_NAME !== utilities.ONICON_NAME && VORTEK_NAME !== utilities.INNOVA_NAME) {
        store.dispatch(actions.setMeterType('vortex'));
      } else if (model[0] === 'Pro') {
        model[0] = `${model[0]}-${model[1]}`;
        model.splice(1, 1);
        turbineActive = true;
        store.dispatch(actions.setMeterType('turbine'));
        // eslint-disable-next-line prefer-destructuring
        match = model[10];
      } else if (model[0] === 'F-1500') {
        turbineActive = true;
        store.dispatch(actions.setMeterType('turbine'));
      } else if (model[0] === 'RIM20') {
        turbineActive = true;
        store.dispatch(actions.setMeterType('turbine'));
        // eslint-disable-next-line prefer-destructuring
        match = model[11];
      } else if (model[0] === VorconeModel) {
        vorconeActive = true;
        beta = `0.${model[14]}`;
        store.dispatch(actions.setMeterType('vorcone'));
      } else if (model[0] === VorconeReduceModel) {
        reducingVorcone = true;
        beta = `0.${model[14]}`;
        store.dispatch(actions.setMeterType('reduce_vorcone'));
      } else if (model[0] === ElectromagneticProMModel) {
        store.dispatch(actions.setMeterType(ElectromagneticProMModel));
      } else if (
        model[0] === UltrasonicS34Model ||
        model[0] === UltrasonicS36Model ||
        model[0] === UltrasonicU42Model ||
        model[0] === UltrasonicU43Model ||
        model[0] === UltrasonicU44Model
      ) {
        store.dispatch(actions.setMeterType(model[0]));
        store.dispatch(actions.setFluidType(obj['fluid-type']));
        store.dispatch(actions.setFluid(obj.fluid));
        if (pipeMaterial) {
          store.dispatch(actions.setPipeMaterial(pipeMaterial));
        } else {
          store.dispatch(actions.setPipeMaterial(''));
        }

        if (linerMaterial) {
          store.dispatch(actions.setPipeLinerMaterial(linerMaterial));
        } else {
          store.dispatch(actions.setPipeLinerMaterial(''));
        }
      } else if (!turbineActive && VORTEK_NAME !== utilities.ONICON_NAME && VORTEK_NAME !== utilities.INNOVA_NAME) {
        store.dispatch(actions.setMeterType('vortex'));
      }

      // reset after setting global vars
      if (utilities.checkValues()) {
        utilities.readyData();
      }

      // simulate size meters click
      store.dispatch(actions.setAppMode('sizing'));

      if (!turbineActive && obj['pipe-size'] && !(VORTEK_NAME === utilities.HEINRICH_NAME && model[0] === InlineModel)) {
        match = obj['pipe-size'];
      }

      // figure out if model is in-line or insertion
      if ((model[0] === InlineModel && !reducingVortex) || model[0] === InlineNonReduceModel || model[0] === VorconeModel) {
        if (VORTEK_NAME === utilities.ONICON_NAME) {
          flange = model[2].substring(0, 1);
          realMounting = model[2].substring(1, 2);
          endMounting = 2;
          if (model[2].substring(2, 3) === '(') {
            endMounting = model[2].indexOf(')');
            realMounting = model[2].substring(1, endMounting);
          }

          fluid = model[2].substring(endMounting, endMounting + 1);

          model[2] = flange;

          power = model[3].substring(0, 1);
          signal = model[3].substring(1, 2);
          temperature = model[3].substring(2, 3);
          energy = model[3].substring(3, 4);

          model[3] = realMounting;
          model.splice(4, 0, fluid);
          model.splice(5, 0, power);
          model.splice(6, 0, signal);
          model.splice(7, 0, temperature);
          model.splice(8, 0, energy);
        }
        // Azbil has a weird model code that needs further split apart
        else if (VORTEK_NAME === utilities.AZBIL_NAME) {
          realMatSize = model[2].substring(0, 4);
          flange = model[2].substring(4, 6);
          model[2] = realMatSize;
          model.splice(3, 0, flange);
          size = model[2].substring(0, 3);

          realMounting = model[4].substring(0, 2);
          display = model[4].substring(2, 3);
          power = model[4].substring(3, 4);
          signal = model[4].substring(4, 5);
          temperature = model[4].substring(5, 6);
          transducer = model[4].substring(6, 7);

          model[4] = realMounting;
          model.splice(5, 0, display);
          model.splice(6, 0, power);
          model.splice(7, 0, signal);
          model.splice(8, 0, temperature);
          model.splice(9, 0, transducer);
        } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
          if (!match) {
            size = model[1].substring(1, 3);
          } else {
            size = match;
          }
        } else if (
          VORTEK_NAME === utilities.PANAFLOW_NAME ||
          VORTEK_NAME === utilities.PROV_NAME ||
          VORTEK_NAME === utilities.SPIRAX_NAME ||
          VORTEK_NAME === utilities.GESTRA_NAME ||
          VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
        ) {
          size = model[2].substring(0, 2);
        } else if (VORTEK_NAME === utilities.INNOVA_NAME) {
          // Sierra is Material then Size
          size = model[2].substring(model[2].length - 2);
          if (!utilities.isNumber(Number(size))) {
            size = model[2].substring(model[2].length - 1);
          }
        } else if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
          // eslint-disable-next-line
          size = model[2];
        }

        // we must do the feature list here manually as the above
        // redux store updates have not happened yet.
        featurelist = api.getSpecifiedFeatureList('m22Feature');
        const list = [] as Feature[];
        for (let j = 0; j < featurelist.length; j++) {
          const featureItem: Feature = featurelist[j];
          // eslint-disable-next-line
          // @ts-ignore
          // eslint-disable-next-line
          if (featureItem.FeatureNumber === '4') {
            list.push(featurelist[j]);
          }
        }

        for (i = 0; i < list.length; i++) {
          if (list[i].OrderCode === size) {
            match = list[i].Description;
            break;
          }
        }
      } else if (model[0] === InlineReduceModel || model[0] === VorconeReduceModel) {
        // Azbil has a weird model code that needs further split apart
        if (VORTEK_NAME === utilities.AZBIL_NAME) {
          realMatSize = model[2].substring(0, 4);
          flange = model[2].substring(4, 6);
          model[2] = realMatSize;
          model.splice(3, 0, flange);
          size = model[2].substring(0, 3);

          realMounting = model[4].substring(0, 2);
          display = model[4].substring(2, 3);
          power = model[4].substring(3, 4);
          signal = model[4].substring(4, 5);
          temperature = model[4].substring(5, 6);
          transducer = model[4].substring(6, 7);

          model[4] = realMounting;
          model.splice(5, 0, display);
          model.splice(6, 0, power);
          model.splice(7, 0, signal);
          model.splice(8, 0, temperature);
          model.splice(9, 0, transducer);
        } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
          if (!match) {
            size = model[1].substring(1, 3);
          } else {
            size = match;
          }
        } else if (
          VORTEK_NAME === utilities.PANAFLOW_NAME ||
          VORTEK_NAME === utilities.PROV_NAME ||
          VORTEK_NAME === utilities.SPIRAX_NAME ||
          VORTEK_NAME === utilities.GESTRA_NAME ||
          VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
        ) {
          size = model[2].substring(0, 2);
        } else if (VORTEK_NAME === utilities.INNOVA_NAME) {
          // Sierra is Material then Size
          size = model[2].substring(model[2].length - 2);
          if (!utilities.isNumber(Number(size))) {
            size = model[2].substring(model[2].length - 1);
          }
        } else if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
          // eslint-disable-next-line
          size = model[2];
        }

        // we must do the feature list here manually as the above
        // redux store updates have not happened yet.
        featurelist = api.getSpecifiedFeatureList('m24rFeature');
        const list = [] as Feature[];
        for (let j = 0; j < featurelist.length; j++) {
          const featureItem: Feature = featurelist[j];
          // eslint-disable-next-line
          // @ts-ignore
          // eslint-disable-next-line
          if (featureItem.FeatureNumber === '4') {
            list.push(featurelist[j]);
          }
        }

        for (i = 0; i < list.length; i++) {
          if (list[i].OrderCode === size) {
            match = list[i].Description;
            break;
          }
        }
      } else {
        // ultrasonic can have a tricky model code
        if (
          model[0] === UltrasonicS34Model ||
          model[0] === UltrasonicS36Model ||
          model[0] === UltrasonicU42Model ||
          model[0] === UltrasonicU43Model
        ) {
          if (model[1] === 'VERER' || model[1] === 'VETET') {
            model[1] += `-${model[2]}`;
            model.splice(2, 1);
          }
        } else if (VORTEK_NAME === utilities.ONICON_NAME) {
          flange = model[1].substring(0, 1);
          realMounting = model[1].substring(1, 2);
          endMounting = 2;
          if (model[1].substring(2, 3) === '(') {
            endMounting = model[1].indexOf(')');
            realMounting = model[1].substring(1, endMounting);
          }

          fluid = model[1].substring(endMounting, endMounting + 1);
          if (turbineActive) {
            turbine = model[1].substring(endMounting + 1, endMounting + 2);
          }

          model[1] = flange;

          power = model[2].substring(0, 1);
          signal = model[2].substring(1, 2);
          temperature = model[2].substring(2, 3);
          energy = model[2].substring(3, 4);

          model[2] = realMounting;
          model.splice(3, 0, fluid);
          if (turbineActive) {
            model.splice(4, 0, turbine as any as string);
            model.splice(5, 0, power);
            model.splice(6, 0, signal);
            model.splice(7, 0, temperature);
            model.splice(8, 0, energy);
          } else {
            model.splice(4, 0, power);
            model.splice(5, 0, signal);
            model.splice(6, 0, temperature);
            model.splice(7, 0, energy);
          }
        }
        // Azbil has a weird model code that needs further split apart
        else if (VORTEK_NAME === utilities.AZBIL_NAME) {
          realProbe = model[2].substring(0, 1);
          realMounting = model[2].substring(1, 3);
          display = model[2].substring(3, 4);
          power = model[2].substring(4, 5);
          signal = model[2].substring(5, 6);
          temperature = model[2].substring(6, 7);
          transducer = model[2].substring(7, 8);
          model[2] = realProbe;
          model.splice(3, 0, realMounting);
          model.splice(4, 0, display);
          model.splice(5, 0, power);
          model.splice(6, 0, signal);
          model.splice(7, 0, temperature);
          model.splice(8, 0, transducer);
        }

        if (turbineActive) {
          if (VORTEK_NAME === utilities.ONICON_NAME) {
            match = calcTurbineMeter.getOniconTurbineRotorModelCodeToVortekModelCode(turbine as any as string);
          }
        } else {
          match = obj['pipe-size'];
          if (obj['pipe-type'] === 'din') {
            // eslint-disable-next-line
            match = match?.split(' ')[0];
            if (obj['pipe-unit'] === 'in' && !match?.endsWith('-inch')) {
              match += '-inch';
            } else if (obj['pipe-unit'] === 'mm') {
              match = `DN ${match}`;
            }
          } else if (obj['pipe-type'] === 'other') {
            // eslint-disable-next-line
            match = match?.split(' ')[0];
            if (obj['pipe-unit'] === 'in') {
              match += 'in';
            } else if (obj['pipe-unit'] === 'mm') {
              match += 'mm';
            }
          } else if (model[0] === ElectromagneticProMModel) {
            match = match?.split(' ')[0];
            if (match) {
              match += '-inch';
            }
          }
        }
      }

      store.dispatch(actions.setTurbineActive(turbineActive));
      store.dispatch(actions.setReducingVortex(reducingVortex));
      store.dispatch(actions.setVorconeActive(vorconeActive));
      store.dispatch(actions.setReducingVorcone(reducingVorcone));

      if (vorconeActive || reducingVorcone) {
        match = beta;
      }

      let index;
      let found = false;

      // recalculate based on new values
      utilities.readyData();

      const meters = calcMeter.columnData();
      meters.forEach((meter, meterIndex) => {
        let matcher = meter.name;
        if (vorconeActive || reducingVorcone) {
          matcher = meter.beta as unknown as string;
        } else if (reducingVortex) {
          matcher = calcMeter.getReducedSize(pipeOutsideDiameter);
        }

        if (!found && matcher === match) {
          found = true;
          index = 0;

          if (VORTEK_NAME === utilities.ARMSTRONG_NAME) {
            if (model[0] === InlineModel || model[0] === InlineNonReduceModel) {
              store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
              index += 1;

              let tmpFlange = model[index];
              if (tmpFlange === '64') {
                tmpFlange = '63';
              }

              store.dispatch(actions.setSpecifyMeterFlange(tmpFlange));
              index += 1;

              size = model[index];
              store.dispatch(actions.setSpecifyMeterPipeSize(size));
              index += 1;

              material = model[index];
              store.dispatch(actions.setSpecifyMeterPipeMaterial(material));
              index += 1;

              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterInputPower(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessTemperature(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
              index += 1;
            } else {
              store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
              index += 1;

              let tmpConnection = model[index];
              if (tmpConnection === 'CF5064') {
                tmpConnection = 'CF5063';
              } else if (tmpConnection === 'PF5064R') {
                tmpConnection = 'PF5063R';
              }

              store.dispatch(actions.setSpecifyMeterProcessConnection(tmpConnection));
              index += 1;

              store.dispatch(actions.setSpecifyMeterProbeLength(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterInputPower(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessTemperature(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
              index += 1;
            }
          } else if (VORTEK_NAME === utilities.ONICON_NAME) {
            if (model[0] === InlineModel || model[0] === InlineNonReduceModel) {
              store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
              index += 1;

              size = model[index];
              store.dispatch(actions.setSpecifyMeterPipeSize(size));
              index += 1;

              store.dispatch(actions.setSpecifyMeterFlange(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterInputPower(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessTemperature(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterEnergyMeter(model[index]));
              index += 1;
            } else {
              store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessConnection(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 1;

              if (turbineActive) {
                turbine = model[index];
                store.dispatch(
                  actions.setSpecifyMeterRotor(calcTurbineMeter.getOniconTurbineRotorModelCodeToVortekModelCode(turbine))
                );
                index += 1;
              }

              store.dispatch(actions.setSpecifyMeterInputPower(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterProcessTemperature(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterEnergyMeter(model[index]));
              index += 1;
            }
          } else if (VORTEK_NAME === utilities.HEINRICH_NAME) {
            if (model[1].length) {
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[1].substring(0, 1)));
            }

            if (model[0] === InlineModel) {
              if (model[1].length > 2) {
                store.dispatch(actions.setSpecifyMeterPipeSize(model[1].substring(1, 3)));
              }

              if (model[1].length > 3) {
                store.dispatch(actions.setSpecifyMeterFlange(model[1].substring(3, 4)));
              }

              if (model[1].length > 4) {
                store.dispatch(actions.setSpecifyMeterPipeMaterial(model[1].substring(4, 5)));
              }

              if (model[1].length > 5) {
                store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[1].substring(5, 6)));
              }

              if (model[1].length > 6) {
                store.dispatch(actions.setSpecifyMeterInputPower(model[1].substring(6, 7)));
              }

              if (model[1].length > 7) {
                store.dispatch(actions.setSpecifyMeterOutputSignal(model[1].substring(7, 8)));
              }

              if (model[1].length > 8) {
                store.dispatch(actions.setSpecifyMeterProcessTemperature(model[1].substring(8, 9)));
              }

              if (model[1].length > 9) {
                store.dispatch(actions.setSpecifyMeterTransducer(model[1].substring(9, 10)));
              }
            } else {
              if (model[1].length > 1) {
                store.dispatch(actions.setSpecifyMeterProbeLength(model[1].substring(1, 2)));
              }

              if (model[1].length > 2) {
                store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[1].substring(2, 3)));
              }

              if (model[1].length > 3) {
                store.dispatch(actions.setSpecifyMeterInputPower(model[1].substring(3, 4)));
              }

              if (model[1].length > 4) {
                store.dispatch(actions.setSpecifyMeterOutputSignal(model[1].substring(4, 5)));
              }

              if (model[1].length > 5) {
                store.dispatch(actions.setSpecifyMeterProcessTemperature(model[1].substring(5, 6)));
              }

              if (model[1].length > 6) {
                store.dispatch(actions.setSpecifyMeterTransducer(model[1].substring(6, 7)));
              }

              if (model[1].length > 7) {
                store.dispatch(actions.setSpecifyMeterProcessConnection(model[1].substring(7, 8)));
              }
            }
          } else if (
            model[0] === InlineModel ||
            model[0] === InlineReduceModel ||
            model[0] === InlineNonReduceModel ||
            model[0] === VorconeModel ||
            model[0] === VorconeReduceModel
          ) {
            store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
            index += 1;

            if (model[index] === 'VTP' && model[index + 1] === 'EM') {
              model[index] = 'VTP-EM';
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 2;
            } else {
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 1;
            }

            // also need to do material if size is being set
            if (VORTEK_NAME === utilities.INNOVA_NAME) {
              // Sierra is Material then Size
              size = model[index].substring(model[index].length - 2);
              if (utilities.isNumber(Number(size))) {
                rest = model[index].substring(0, model[index].length - 2);
              } else {
                size = model[index].substring(model[index].length - 1);
                rest = model[index].substring(0, model[index].length - 1);
              }

              if (rest.length === 1) {
                material = '';
                realFlange = rest;
              } else {
                material = rest.substring(rest.length - 1);
                if (material !== 'C' && material !== 'H') {
                  material = '';
                  realFlange = rest;
                } else {
                  realFlange = rest.substring(0, rest.length - 1);
                }
              }

              store.dispatch(actions.setSpecifyMeterPipeSize(size));
              store.dispatch(actions.setSpecifyMeterPipeMaterial(material));
              index += 1;

              store.dispatch(actions.setSpecifyMeterFlange(realFlange));
            } else {
              // Everyone else is Size then Material
              material = model[index].substring(model[index].length - 1);
              size = model[index].substring(0, model[index].length - 1);

              store.dispatch(actions.setSpecifyMeterPipeSize(size));
              store.dispatch(actions.setSpecifyMeterPipeMaterial(material));
              index += 1;

              if (
                VORTEK_NAME === utilities.PROV_NAME ||
                VORTEK_NAME === utilities.AZBIL_NAME ||
                VORTEK_NAME === utilities.GESTRA_NAME ||
                VORTEK_NAME === utilities.PANAFLOW_NAME ||
                VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
              ) {
                let tmpFlange = model[index];
                if (tmpFlange === '64') {
                  tmpFlange = '63';
                }

                store.dispatch(actions.setSpecifyMeterFlange(tmpFlange));
              } else {
                store.dispatch(actions.setSpecifyMeterFlange(model[index]));
              }

              index += 1;
            }

            store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterDisplayOption(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterInputPower(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterProcessTemperature(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
            index += 1;

            if (model[0] === VorconeModel || model[0] === VorconeReduceModel) {
              store.dispatch(actions.setSpecifyMeterDiffPressXMTR(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterDiffPressXMTRMani(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterDiagDiffPressXMTR(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterDiagAddPressTap(model[index]));
              index += 1;
            }

            if (VORTEK_NAME === utilities.SPIRAX_NAME) {
              store.dispatch(actions.setSpecifyMeterApprovals(model[index]));
              index += 1;
              store.dispatch(actions.setSpecifyMeterFaceToFace(model[index]));
              index += 1;
            }
          } else if (model[0] === UltrasonicS34Model) {
            store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterCableLength(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterProcessTemperature(model[index]));
            index += 1;
          } else if (model[0] === UltrasonicS36Model) {
            store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterCableLength(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterCommunications(model[index]));
            index += 1;
          } else if (model[0] === UltrasonicU42Model || model[0] === UltrasonicU43Model) {
            store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterCableLength(model[index]));
            index += 1;
          } else if (model[0] === UltrasonicU44Model) {
            store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterTransducerBracket(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterCableLength(model[index]));
            index += 1;
          } else if (model[0] === ElectromagneticProMModel) {
            store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterAccuracy(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterPipeSize(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterProcessConnection(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterInputPower(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterCommunications(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterElectrodeMaterial(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterLinerMaterial(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterGrounding(model[index]));
            index += 1;
          } else {
            store.dispatch(actions.setSpecifyMeterParentModelNumber(model[index]));
            index += 1;
            if (model[index] === 'VTP' && model[index + 1] === 'EM') {
              model[index] = 'VTP-EM';
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 2;
            } else {
              store.dispatch(actions.setSpecifyMeterProcessFluid(model[index]));
              index += 1;
            }

            store.dispatch(actions.setSpecifyMeterProbeLength(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterElectronicsMounting(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterDisplayOption(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterInputPower(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterOutputSignal(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterProcessTemperature(model[index]));
            index += 1;
            store.dispatch(actions.setSpecifyMeterTransducer(model[index]));
            index += 1;

            if (
              VORTEK_NAME === utilities.PROV_NAME ||
              VORTEK_NAME === utilities.AZBIL_NAME ||
              VORTEK_NAME === utilities.GESTRA_NAME ||
              VORTEK_NAME === utilities.PANAFLOW_NAME ||
              VORTEK_NAME === utilities.WATSON_MCDANIEL_NAME
            ) {
              let tmpConnection = model[index];
              if (tmpConnection === 'C64') {
                tmpConnection = 'C63';
              } else if (tmpConnection === 'P64R') {
                tmpConnection = 'P63R';
              }

              store.dispatch(actions.setSpecifyMeterProcessConnection(tmpConnection));
            } else {
              store.dispatch(actions.setSpecifyMeterProcessConnection(model[index]));
            }

            index += 1;

            if (VORTEK_NAME === utilities.SPIRAX_NAME) {
              store.dispatch(actions.setSpecifyMeterApprovals(model[index]));
              index += 1;
            }
          }

          if (utilities.checkValues() && utilities.checkExtendedValues()) {
            store.dispatch(actions.setMeterData(meters));
            store.dispatch(actions.setMeterIndex(meterIndex));
            store.dispatch(actions.setAppMode('sizing'));
            store.dispatch(actions.setShowSpecifyMeter(true));
          }
        }
      });

      meterSpecify.createModelCode();
    }

    const accessoryData = obj?.accessoryData;
    if (accessoryData) {
      const expAccess = accessoryData.split('^');
      const newAccessories = [] as Accessory[];

      for (i = 0; i < expAccess.length - 1; i += 3) {
        const qty = parseInt(expAccess[i], 10);

        newAccessories.push({
          quantity: qty,
          code: expAccess[i + 1],
          description: expAccess[i + 2],
          order: -1,
        });
      }

      store.dispatch(actions.setAccessories(newAccessories));
    }
  }
};

export default {
  collectApplicationData,
  downloadApplication,
  newApplication,
  parseApplication,
  saveApplicationToServer,
};
